import React from 'react';
import { PostsTbody } from '@components/posts/posts-tbody';
import { Thead } from '@components/shared/thead';
import { PostsTableTitles } from '@_data/titles';

export const PostsTable: React.FC = () => {
  return (
    <table className='w-full divide-y divide-gray-200'>
      <Thead tableTitles={PostsTableTitles}/>
      <PostsTbody />
    </table>
  );
};