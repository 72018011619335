import React from 'react';
import { UsersTBody } from '@components/users/users-body';
import { UsersTableTitles } from '@_data/titles';
import { Thead } from '@components/shared/thead';

interface Props {
}

export const UsersTable: React.FC<Props> = () => {
  return (
    <table className='min-w-full divide-y divide-gray-200'>
      <Thead tableTitles={UsersTableTitles}/>
      <UsersTBody />
    </table>
  );
};