import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@interfaces/index';

interface IState {
  token: string | null;
  user: IUser | null;
  expired_at: string | null;
}

const initialState: IState = {
  token: null,
  user: null,
  expired_at: null,
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state: IState, action: PayloadAction<{ token: string | null, expired_at: string | null }>) => ({
      ...state,
      token: action.payload.token,
      expired_at: action.payload.expired_at,
    }),
    logOut: (state: IState) => ({
      ...state,
      token: null,
      expired_at: null,
      user: null
    }),
    setUser: (state, action: PayloadAction<{ user: IUser | null }>) => ({
      ...state,
      user: action.payload.user,
    }),
  },
});