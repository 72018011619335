import React from 'react';
import { postNavigation } from '@_data/titles';
import { Permission } from '@helpers/permissions';
import { moderaciyaStati } from '@_data/permissions';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface Props {

}

export const Sidebar: React.FC<Props> = ({ children }) => {

  return (
    <aside className='py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3'>
      <nav className='space-y-5 block sticky top-0'>
        {children}
        <div>
          {
            postNavigation.map((item, i) => (
              item.name === 'Настройки'
                ? (
                  <Permission
                      key={item.name + i}
                      action={moderaciyaStati}
                  >
                    <a
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                          : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                        'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-indigo-500 group-hover:text-indigo-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                        )}
                        aria-hidden='true'
                      />
                      <span className='truncate'>{item.name}</span>
                    </a>
                  </Permission>
                )
                : (
                  <a
                    key={item.name + i}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                        : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                      'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-indigo-500 group-hover:text-indigo-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                      )}
                      aria-hidden='true'
                    />
                    <span className='truncate'>{item.name}</span>
                  </a>
                )

            ))
          }
        </div>
      </nav>
    </aside>
  );
};