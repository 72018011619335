import React from 'react';
import { setMessage } from '@services/dispatch.service';

export const errorTitles = (err: any, setError: any, dispatch: any) => {
  let errors: any = [];
  if (err.response?.data?.errors) {
    if (Array.isArray(err.response.data.errors?.title)) {
      setError('title', {
        type: 'required',
        message: err.response.data.errors['title'][0]
      })
      return
    }
    if (Array.isArray(err.response.data.errors?.description)) return setMessage(dispatch, err.response.data.errors?.description[0])

    Object.keys(err.response.data.errors).forEach(key => {
      errors = [...errors, <p key={key}>{err.response.data.errors[key][0]}</p>];
      setError(key, {
        type: 'required',
        message: err.response.data.errors[key][0],
      });
    });
    return setMessage(dispatch, errors);
  }
  setMessage(dispatch, err);
}

export const errorNames = (err: any, setError: any, dispatch: any) => {
  if (err.response?.data?.errors) {
    if (Array.isArray(err.response.data.errors?.name)) {
      setError('name.ru', {
        type: 'required',
        message: err.response.data.errors['name'][0],
      });
      setError('name.uz', {
        type: 'required',
        message: err.response.data.errors['name'][0],
      });
      return
    }
    Object.keys(err.response.data.errors).forEach(key => {
      setError(key, {
        type: 'required',
        message: err.response.data.errors[key][0],
      });
    });
    return
  }
  setMessage(dispatch, err);
}