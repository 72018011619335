import Image from '@editorjs/image';

export default class ImageTool extends Image {
  constructor(...args) {
    super(...args);
    // this.renderSettings(this._data.caption, this.ui?.nodes);
  }

  // validate(savedData) {
  //   if (!savedData.file?.url) {
  //     this.api.blocks.delete(this.api.blocks.getCurrentBlockIndex())
  //     return false;
  //   }
  //   return true;
  // }

  renderSettings(caption, ui) {
    if (!caption) ui?.wrapper?.removeChild(ui.caption);
  }
}