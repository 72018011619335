import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  BriefcaseIcon,
  CalendarIcon,
  ChevronDownIcon,
  EyeIcon,
  LinkIcon,
} from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import { Breadcrumbs } from '@components/posts/UI/breadcrumbs';
import { IPost } from '@interfaces/index';
import { FireIcon, HeartIcon } from '@heroicons/react/outline';

interface AuthorProps {
  post: IPost;
}

const Author: React.FC<AuthorProps> = ({ post }) => {
  return (
    <Link to='/' className='flex-shrink-0 group block'>
      <div className='flex items-center'>
        <div>
          <img
            className='inline-block h-5 w-5 rounded-full'
            src={post.user?.avatar}
            alt=''
          />
        </div>
        <div className='ml-3'>
          <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>{post.user?.name}</p>
        </div>
      </div>
    </Link>
  );
};

interface Props {
  post: IPost | null;
  isModeration: boolean;
}

export const Header: React.FC<Props> = ({ post, children, isModeration }) => {
  return (
    <>
      <div className='lg:flex lg:items-center lg:justify-between'>
        <div className='flex-1 min-w-0'>
          <div className={'flex lg:items-center items-start justify-between mt-2 flex-col lg:flex-row'}>
            <Breadcrumbs />
            {children}
          </div>
          <h2 className='text-2xl font-bold leading-7 text-gray-900 sm:text-3xl w-full lg:mb-0 mb-2'>{post?.title}</h2>
          <div className='mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6'>
              <div className='mt-2 flex items-center text-xs text-gray-500'>
                <BriefcaseIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
                {post?.category?.name?.ru}
              </div>
            {
              !isModeration && <>
                {
                  !!post?.views_count && <div className='mt-2 flex items-center text-xs text-gray-500'>
                    <EyeIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
                    {post?.views_count} просмотров
                  </div>
                }
                {
                  !!post?.rating && <div className='mt-2 flex items-center text-xs text-gray-500'>
                    <LinkIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
                    {post?.rating} рейтинг
                  </div>
                }
              </>
            }
            <div className='mt-2 flex items-center text-xs text-gray-500'>
              <CalendarIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
              {post?.created_at}
            </div>
            {
              isModeration && post?.user_id && post?.user &&  <div className='mt-2 flex items-center text-xs text-gray-500'>
                <Author post={post} />
              </div>
            }
          </div>
          {
            !isModeration && <div className='flex flex-row flex-wrap sm:mt-2 space-x-6'>
              {
                post?.user_id && post?.user && <Author post={post} />
              }
              {
                !!post?.popular && <span
                  className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800'>
                <HeartIcon className='-ml-0.5 mr-1.5 h-3 w-3 text-indigo-400' />
                Популярно
              </span>
              }
              {
                post?.hot_post && <span
                  className='inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                  <FireIcon className='-ml-0.5 mr-1.5 h-3 w-3 text-red-400' />
                  Горячая новость
                </span>
              }
              {
                !!post?.special_report && <span
                  className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                <svg className='-ml-0.5 mr-1.5 h-2 w-2 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                  <circle cx={4} cy={4} r={3} />
                </svg>
                Спец. репортаж
              </span>
              }
            </div>
          }
        </div>
      </div>
    </>
  );
};
