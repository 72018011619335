import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { SlideOvers } from '@components/shared/slideovers';
import { PencilIcon, XCircleIcon, RefreshIcon } from '@heroicons/react/solid';
import { formatDate } from '@helpers/format-date';
import { setMessage } from '@services/dispatch.service';
import { BannedWordContext } from '@helpers/context';
import { Permission } from '@helpers/permissions';
import { obnovlenieRoli, udalenieRoli, vosstanovlenieRoli } from '@_data/permissions';
import { deleteBannedWord, refreshBannedWord } from '@services/bannedWords.service';
import { bannedWordSet, getBannedWords } from '@store/bannedWords/bannedWords.thunks';
import { BannedWord } from '@pages/bannedWords/bannedWord';
import { EditBannedWord } from '@pages/bannedWords/edit';

export const BannedWordsTbody: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useContext(BannedWordContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('show');
  const bannedWord = useSelector((state: State) => state.bannedWords.bannedWord);
  const bannedWords = useSelector((state: State) => state.bannedWords.bannedWords);

  const deleteHandler = (id: number) => {
    deleteBannedWord(id)
      .then(() => {
        dispatch(bannedWordSet(null));
        dispatch(getBannedWords(filter));
        setOpen(false);
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const refresh = async (id: number) => {
    await refreshBannedWord(id)
      .then(() => {
        dispatch(getBannedWords(filter));
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };

  return (
    <tbody className='bg-white divide-y divide-gray-200'>
    {!!bannedWords.length && bannedWords.map((bannedWord, i) => (
      <tr
        key={bannedWord.id + i}
        className='cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setMode('show');
          dispatch(bannedWordSet(bannedWord));
        }}
      >
        <td
          className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>{bannedWord?.text}</td>
        <td
          className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{formatDate(bannedWord?.created_at || '')}</td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
          {
            bannedWord?.deleted_at
              ? (
                <Permission action={vosstanovlenieRoli}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      refresh(bannedWord.id);
                    }}
                  >
                    <RefreshIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                  </button>
                </Permission>
              )
              : <>
                <Permission action={obnovlenieRoli}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(bannedWordSet(bannedWord));
                      setMode('edit');
                      setShow(true);
                    }}
                  >
                    <PencilIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                  </button>
                </Permission>
                <Permission action={udalenieRoli}>
                  <button
                    className='text-red-600 hover:text-red-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(bannedWordSet(bannedWord));
                      setOpen(true);
                    }}
                  >
                    <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                  </button>
                </Permission>
              </>
          }
        </td>
      </tr>
    ))}
    {
      !!bannedWord && <Permission action={udalenieRoli}>
        <PopupDelete
          setMode={setMode}
          setShow={setShow}
          open={open}
          setOpen={setOpen}
          handleDelete={deleteHandler}
          data={{ name: bannedWord.text, id: bannedWord.id}}
        />
      </Permission>
    }
    <SlideOvers
      Show={BannedWord}
      Edit={EditBannedWord}
      show={show}
      mode={mode}
      confirmModal={setOpen}
      setMode={setMode}
      setShow={setShow}
    />
    </tbody>
  );
};