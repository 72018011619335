import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';

interface Props {
  name: string,
  defaultValue: string;
  control: Control;
}

export const DatePick: React.FC<Props> = ({ control, name , defaultValue }) => {
  const { field } = useController({
    name,
    defaultValue,
    control
  })
  const defaultDate = field.value?.toString()?.split(' ')
  const [date, setDate] = useState(defaultDate ? defaultDate[0] : '')
  const [time, setTime] = useState(defaultDate ? defaultDate[1]?.split(':')?.filter((s: string, i: number) => i <= 1).join(':') : '')
  const onChange = (e: any) => {
    e.target.type === 'date'
      ? setDate(() => {
        field.onChange([e.target.value, time].join(' '))
        return e.target.value;
      })
      : setTime(() => {
        field.onChange([date, e.target.value].join(' '))
        return e.target.value;
      })

  }
  return (
    <>
      <input
        value={date}
        onChange={onChange}
        type='date'
        placeholder=''
        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
      />
      <input
        value={time}
        onChange={onChange}
        type='time'
        placeholder=''
        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
      />
    </>
  );
};