import React, { useEffect } from 'react';
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '@pages/posts/EDITOR_JS_TOOLS';
import { removePreview } from '@helpers/preview';
import { Redirect } from 'react-router-dom';
import { Header } from '@components/posts/UI/header';

interface IUserPrev {
  title: string;
  description: any;
  image: string;
  category: string;
}

const tools: any = EDITOR_JS_TOOLS

export const PreviewPage: React.FC = () => {
  useEffect(() => {
    return () => removePreview()
  })
  const post: IUserPrev | null | any = localStorage.getItem('previewpage') ? JSON.parse(localStorage.getItem('previewpage') || '{}') : null;
  if (!post) return <Redirect to='/posts'/>
  return (
    <>
    <div
      className='post_container lg:pl-20 px-2 sm:pt-1'
    >
      <Header post={post} isModeration={false}/>
      <div className='pb-1 sm:pb-6'>
        <div>
          <img
            className='h-full w-full mx-auto object-contain mt-4'
            src={post?.image}
            alt=''
          />
          <p className='text-sm font-normal mt-2 mb-4'>{post?.caption}</p>
          <p className='text-base font-normal my-4'>{post?.meta_description}</p>
          <div
            className={'mb-5 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md'}>
            <EditorJs
              readOnly={true}
              tools={tools}
              data={post.description}
            />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};