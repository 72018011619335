import React from 'react'
import { Switch } from '@headlessui/react'
import { Control, useController } from 'react-hook-form';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}
interface Props {
  title: string;
  name: string,
  defaultValue: boolean,
  control: Control
}
export const Toggle: React.FC<Props> = ({title, defaultValue, control, name }) => {
  const { field } = useController({
    name,
    defaultValue,
    control
  })
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={field.value}
        onChange={field.onChange}
        className={classNames(
          field.value ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            field.value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium whitespace-nowrap text-gray-900">{title}</span>
      </Switch.Label>
    </Switch.Group>
  )
}