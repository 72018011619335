import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { deleteRole, refreshRole } from '@services/roles.service';
import { getRoles, roleSet } from '@store/roles/roles.thunks';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { SlideOvers } from '@components/shared/slideovers';
import { PencilIcon, XCircleIcon, RefreshIcon } from '@heroicons/react/solid';
import { formatDate } from '@helpers/format-date';
import { RolePage } from '@pages/roles/role';
import { EditRole } from '@pages/roles/edit';
import { setMessage } from '@services/dispatch.service';
import { RoleContext } from '@helpers/context';
import { Permission } from '@helpers/permissions';
import { obnovlenieRoli, udalenieRoli, vosstanovlenieRoli } from '@_data/permissions';

export const RolesTBody: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useContext(RoleContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('show');
  const role = useSelector((state: State) => state.roles.role);
  const roles = useSelector((state: State) => state.roles.roles);

  const deleteHandler = (id: number) => {
    deleteRole(id)
      .then(() => {
        dispatch(roleSet(null));
        dispatch(getRoles(filter));
        setOpen(false);
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const refresh = async (id: number) => {
    await refreshRole(id)
      .then(() => {
        dispatch(getRoles(filter));
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };

  return (
    <tbody className='bg-white divide-y divide-gray-200'>
    {!!roles.length && roles.map((role) => (
      <tr
        key={role.id}
        className='cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setMode('show');
          dispatch(roleSet(role));
        }}
      >
        <td
          className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>{role.name}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{role.slug}</td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{formatDate(role.created_at)}</td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
          {
            role.deleted_at
              ? (
                <Permission action={vosstanovlenieRoli}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      refresh(role.id);
                    }}
                  >
                    <RefreshIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                  </button>
                </Permission>
              )
              : <>
                <Permission action={obnovlenieRoli}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(roleSet(role));
                      setMode('edit');
                      setShow(true);
                    }}
                  >
                    <PencilIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                  </button>
                </Permission>
                <Permission action={udalenieRoli}>
                  <button
                    className='text-red-600 hover:text-red-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(roleSet(role));
                      setOpen(true);
                    }}
                  >
                    <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                  </button>
                </Permission>
              </>
          }
        </td>
      </tr>
    ))}
    {
      !!role && <Permission action={udalenieRoli}>
        <PopupDelete
          setMode={setMode}
          setShow={setShow}
          open={open}
          setOpen={setOpen}
          handleDelete={deleteHandler}
          data={role}
        />
      </Permission>
    }
    <SlideOvers
      Show={RolePage}
      Edit={EditRole}
      show={show}
      mode={mode}
      confirmModal={setOpen}
      setMode={setMode}
      setShow={setShow}
    />
    </tbody>
  );
};