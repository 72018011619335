import { setMessage } from '@services/dispatch.service';
import { tagsAction } from '@store/tags/tags.slices';
import { getAllTagsService, getTagService, getTagsService } from '@services/tags.service';

export const tagSet = (tag: any) => async (dispatch: any) => {
  dispatch(tagsAction.setTag({ tag }));
};
export const tagsSet = (tags: any) => async (dispatch: any) => {
  dispatch(tagsAction.setTags({ ru: {tags: tags.ru?.data, pagination: tags.ru?.total}, uz: {tags: tags.uz?.data, pagination: tags.uz?.total} }));
};
export const ruTagsSet = (tags: any) => async (dispatch: any) => {
  dispatch(tagsAction.setRuTags({ ru: tags}));
};
export const uzTagsSet = (tags: any) => async (dispatch: any) => {
  dispatch(tagsAction.setUzTags({ uz: tags}));
};

export const getTags = (params: any) => async (dispatch: any) => {
  return getTagsService(params)
    .then(res => dispatch(tagsSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error));
};

export const getTag = (id: string) => async (dispatch: any) => {
  return getTagService(id)
    .then((res) => dispatch(tagSet(res.role)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error));
};

export const getAllTags = () => async (dispatch: any) => {
  return getAllTagsService()
    .then(res => {
      dispatch(tagsAction.setAllTags({ allTags: res.tags }));
    })
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error));
};