import React from 'react';
import { useForm } from 'react-hook-form';

interface Props {

}

export const SettingsPage: React.FC<Props> = () => {
  const {register} = useForm();
  const fields = [
    {
      field: 'title',
      type: 'text',
      label: 'Название'
    },
    {
      field: 'description',
      type: 'text',
      label: 'Описание'
    },
    {
      field: 'keywords',
      type: 'text',
      label: 'Ключевые слова'
    },
  ]

  return (
    <>
      <h3 className='text-2xl font-semibold'>Настройки сайта</h3>
      <div className='grid grid-cols-2 gap-6 justify-between mb-4 mt-2 py-4 items-center'>
        { fields.map((field) => (
          <div className="col-6" key={field.field}>
            <label htmlFor={field.field} className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <div className="mt-1">
              <input
                type={field.type}
                id={field.field}
                className="shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                {...register(field.field)}
              />
            </div>
          </div>
        )) }
      </div>
      <h3 className='text-2xl mt-6 font-semibold'>Мобильные операторы</h3>
    </>
  );
};