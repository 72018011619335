import { getLastPopularPostsService, getPostService, getPostsService } from '@services/posts.service';
import { postsAction } from '@store/posts/posts.slices';
import { setMessage } from '@services/dispatch.service';

export const postSet = (post: any) => async (dispatch: any) => {
  dispatch(postsAction.setPost({post}))
}
export const postsSet = (res: any) => async (dispatch: any) => {
  dispatch(postsAction.setPosts({
    posts: res.data,
    pagination: res.total || 0,
  }));
}

export const getPosts = (params: any) => async (dispatch: any) => {
  getPostsService(params)
    .then(res => dispatch(postsSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
export const getLastPopularPosts = () => async (dispatch: any) => {
  getLastPopularPostsService()
    .then(res => dispatch(postsSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
export const getPost = (id: number) => async (dispatch: any) => {
  return getPostService(id)
    .then(post => dispatch(postSet(post)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};