import React, { useEffect } from 'react';
import { LoginForm } from '@components/login';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { login, logout } from '@store/auth/auth.thunks';
import { loginService } from '@services/auth.service';
import { setMessage } from '@services/dispatch.service';

interface IValidation {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (history.location?.state?.from) {
      dispatch(logout());
      setMessage(dispatch, 'Ваш Token истек, авторизуйтесь в систему заного')
    }
  }, [dispatch, history.location?.state?.from]);

  const handleSubmitForm = (data: IValidation) => {
    loginService(data)
      .then(res => {
        dispatch(login(res));
        history.push('/',{ from: '' });
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  return (
    <div className='min-h-screen bg-white flex'>
      <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div>
            {/*TODO: LogoType*/}
            <img
              className='h-12 w-auto'
              src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
              alt='Workflow'
            />
            <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>Sign in to your account</h2>
          </div>

          <LoginForm
            handleSubmitForm={handleSubmitForm}
          />
        </div>
      </div>
      <div className='hidden lg:block relative w-0 flex-1'>
        <img
          className='absolute inset-0 h-full w-full object-cover'
          src='https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80'
          alt=''
        />
      </div>
    </div>
  );
};