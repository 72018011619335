export const userField = [
  {
    field: 'name',
    label: 'Имя'
  },
  {
    field: 'email',
    label: 'Email'
  },
  {
    field: 'phone',
    label: 'Номер'
  },
  {
    field: 'role',
    label: 'Роль'
  },
];
export const roleFields = [
  {
    field: 'name',
    label: 'Имя',
  },
];
export const bannedWordFields = [
  {
    field: 'text',
    label: 'Текст',
  },
];
export const postField = [
  {
    field: 'title',
    label: 'Заголовок',
  },
];
export const tagField = [
  {
    field: 'name',
    label: 'Название',
  },
];
export const categoryField = [
  {
    field: 'name',
    label: 'Имя'
  }
];
export const commentField = [
  {
    field: 'post',
    label: 'Статья'
  },
  {
    field: 'user',
    label: 'Пользователь'
  }
];