import {
  ChartBarIcon,
  InboxIcon,
  UsersIcon,
  HomeIcon,
  NewspaperIcon,
  UserCircleIcon,
  CogIcon,
  ChatIcon,
  BookmarkIcon,
} from '@heroicons/react/outline';
import {
  prosmotrKategorii,
  prosmotrKommentariev,
  prosmotrPolzovatelei,
  prosmotrRolei,
  prosmotrStati,
  prosmotrTegov,
} from './permissions';

export const sidebarItems = [
  { name: 'Главная', slug: prosmotrStati, to: '/', icon: HomeIcon, current: false },
  { name: 'Пользователи', slug: prosmotrPolzovatelei, to: '/users', icon: UsersIcon, current: false },
  { name: 'Роли', slug: prosmotrRolei, to: '/roles', icon: ChartBarIcon, current: false },
  { name: 'Запрещенные слова', slug: prosmotrRolei, to: '/bannedWords', icon: ChartBarIcon, current: false },
  { name: 'Тэги', slug: prosmotrTegov, to: '/tags', icon: BookmarkIcon, current: false },
  { name: 'Категории', slug: prosmotrKategorii, to: '/categories', icon: InboxIcon, current: false },
  { name: 'Статьи', slug: prosmotrStati, to: '/posts', icon: NewspaperIcon, current: false },
  { name: 'Коментарии', slug: prosmotrKommentariev, to: '/comments', icon: ChatIcon, current: false },
];

export const postNavigation = [
  { name: 'Статья', href: '#post', icon: UserCircleIcon, current: true },
  { name: 'Настройки', href: '#settings', icon: CogIcon, current: false },
];
export const adsNavigation = [
  { name: 'Весь сайт', href: '#wholesite', icon: UserCircleIcon, current: true },
  { name: 'Главная', href: '#main', icon: CogIcon, current: false },
  { name: 'Списки', href: '#list', icon: CogIcon, current: false },
  { name: 'Статья', href: '#post', icon: CogIcon, current: false },
];

interface PostCheckbox {
  id: number;
  title: string;
  description: string,
  field: 'hot_post' | 'special_report' | 'should_notify' | 'notify_social'
}

export const postCheckbox: PostCheckbox[] =  [
  { id: 1, title: 'Горячая новость', description: 'Статья будет выведена в топовых позициях сайта.', field: 'hot_post' },
  { id: 2, title: 'Спец репортаж', description: 'Определяет статью в отдельно посвященный раздел.', field: 'special_report' },
  { id: 3, title: 'Уведомить пользователей', description: 'Пришлет уведомление о выходе статьи на сайте в Push уведомлениях браузеров IOS/Android приложений.', field: 'should_notify' },
  { id: 4, title: 'Выложить в соц. сети', description: 'Будет выгружено в telegram и facebook', field: 'notify_social' },
]

export const RolesTableTitles = [
  'Название',
  'Ссылка',
  'Дата создания',
  'Изменить',
];
export const BannedTableTitles = [
  'Текст',
  'Дата создания',
  'Изменить',
];
export const TagsTableTitles = [
  'Название',
  'Просмотры',
  'Дата создания',
  'Изменить',
];
export const PostsTableTitles = [
  'Название',
  'Язык',
  'Показатели',
  'Статус',
  ''
];
export const CommentsTableTitles = [
  'Пользователь',
  'Статья',
  'Статус',
  ''
];
export const UsersTableTitles = [
  'Имя',
  'Роль',
  'Номер',
  'Дата создания',
  'Изменить',
];
export const CategoriesTableTitles = [
  'Название',
  'Позиция',
  'Дата создания',
  '',
  'Изменить',
];