import React, {useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { nameOrtitle } from '@helpers/nameOrTitle';

const animatedComponents = makeAnimated();

interface Props {
  isTag?: boolean;
  title: string;
  setSelected: (select: any) => void;
  getItems: (param: any) => Promise<any>;
  ru: boolean;
  isMulti: boolean;
  selected?: any;
}
export const SearchSelect: React.FC<Props> = ({ title, setSelected, getItems, ru, isMulti , selected = [], isTag = false}) => {
  const [items, setItems] = useState<any[]>(() => []);
  const [value, setValue] = useState<any[]>(() => selected.map((item: any) => ({ value: item.id, label: nameOrtitle(item, ru) })))
  const selectRef = useRef<any>()

  const setSel = (val: any) => {
    isMulti
      ? setSelected(val.map((item: any) => item.value || item.id))
      : setSelected(val.value);
  }

  const changeInput = (val: string) => {
    getItems(val)
      .then(res => {
        isTag
          ? setItems(res[ru ? 'ru' : 'uz'].data.map((item: any) => ({ value: item.id, label: nameOrtitle(item, ru) })))
          : setItems(res.data.map((item: any) => ({ value: item.id, label: nameOrtitle(item, ru) })))
      });
  };

  const selectInput = (val: any) => {
    setValue(val);
  }

  useEffect(() => {
    setSel(value)
  }, [value])

  const keyDownHandler = (e: any) => {
    const { keyCode , target} = e
    if (keyCode === 13 && !items.length && e.target.value) {
      setValue([...value, { value: target.value, label: target.value}])
      selectRef.current.blur()
      e.stopPropagation()
      e.preventDefault()
    }
  }

  return (
    <Select
      ref={selectRef}
      name={title}
      options={items}
      value={value}
      placeholder={title}
      onKeyDown={keyDownHandler}
      closeMenuOnSelect={false}
      onChange={selectInput}
      onInputChange={changeInput}
      components={animatedComponents}
      isMulti={isMulti}
    />
  );
};