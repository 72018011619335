import { api } from '@api/index';

export const getAllRoles = () => {
  return api
    .get('/dashboard/roles/all')
    .then(res => res.data.result)
}
export const createRole = (data: any) => {
  return api
    .post('/dashboard/roles', data)
    .then(res => res.data.result.role)
}
export const updateRole = (id: number, data: any) => {
  return api
    .post(`/dashboard/roles/${id}`, {
      ...data,
      _method: "PATCH"
    })
    .then(res => res.data.result.role)
}
export const refreshRole = (id: number) => {
  return api
    .post(`/dashboard/roles/${id}/restore`)
    .then(res => res.data.result.role)
}
export const deleteRole = (id: number) => {
  return api
    .post(`/dashboard/roles/${id}`, {
      _method: 'DELETE'
    })
    .then(res => res.data.result)
}
export const getRolesService = (params: any) => {
  return api
    .get(`/dashboard/roles`, { params })
    .then(res => res.data.result)
}
export const getRoleService = (id: string) => {
  return api
    .get(`/dashboard/roles/${id}`)
    .then(res => res.data.result)
}