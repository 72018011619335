import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { XIcon, PencilIcon, RefreshIcon } from '@heroicons/react/solid';
import { refreshCategory } from '@services/categories.service';
import {
  categorySet,
  getCategories,
} from '@store/categories/categories.thunks';
import { Tab } from '@components/shared/tab';
import { CategoryContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import { Permission } from '@helpers/permissions';
import {
  obnovlenieKategorii,
  vosstanovlenieKategorii,
} from '@_data/permissions';

interface Props {
  setOpen: (open: boolean) => void;
  setMode: (mode: string) => void;
}

export const CategoryPage: React.FC<Props> = ({ setOpen, setMode }) => {
  const dispatch = useDispatch();
  const filter = useContext(CategoryContext);
  const category = useSelector((state: State) => state.categories.category);
  const [ru, setRu] = useState(true);
  const close = () => {
    setOpen(false);
    dispatch(categorySet(null));
  };
  const refresh = async (id: number) => {
    await refreshCategory(id)
      .then((category) => {
        dispatch(categorySet(category));
        dispatch(getCategories(filter));
        setMode('show');
      })
      .catch((err) =>
        setMessage(dispatch, err.response?.data?.result?.message || err),
      );
  };
  return (
    <>
      <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
        <div className="px-4 py-6 sm:px-6">
          <div className="flex items-start justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-gray-900"
            >
              Категория
            </h2>
            <div className="ml-3 h-7 flex items-center">
              <button
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                onClick={close}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        {/* Main */}
        <div>
          <div className="pb-6">
            <div className="bg-indigo-700 h-24 sm:h-20 lg:h-28" />
            <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-15">
              <div>
                <div className="-m-1 flex">
                  <div className="inline-flex rounded-lg overflow-hidden border-4 border-white">
                    <img
                      className="flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48"
                      src={category?.image}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:ml-6 sm:flex-1">
                <div>
                  <div className="flex items-center">
                    <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                      {ru ? category?.name?.ru : category?.name?.uz}
                    </h3>
                  </div>
                  <p className="text-sm text-gray-500">
                    <span className="text-black">Ссылка:</span> {category?.slug}
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="text-black">Позиция:</span>{' '}
                    {category?.position}
                  </p>
                </div>
                <div className="mt-5 w-6/12 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                  {!!category && category.deleted_at ? (
                    <Permission action={vosstanovlenieKategorii}>
                      <button
                        className="text-gray-600 hover:text-gray-900"
                        onClick={(e) => {
                          e.stopPropagation();
                          category && refresh(category.id);
                        }}
                      >
                        <RefreshIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                      </button>
                    </Permission>
                  ) : (
                    <Permission action={obnovlenieKategorii}>
                      <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 text-gray-400 border border-gray-400 shadow-sm text-sm leading-4 font-medium rounded-3xl hover:text-white bg-transparent hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setMode('edit')}
                      >
                        <PencilIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Изменить
                      </button>
                    </Permission>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6 pb-4 sm:pb-6">
            <Tab setRu={setRu} ru={ru} />
          </div>
          <div className="flex flex-col gap-y-6">
            <div className={`${ru ? '' : 'hidden'}`}>
              <dl className="pb-5 space-y-8 px-4 sm:px-6 sm:space-y-6">
                <div className="w-12/12">
                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                    Описание
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {category ? category.description?.ru : ''}
                  </dd>
                </div>
              </dl>
              <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                <div className="flex justify-between">
                  <div className="flex justify-between space-x-6">
                    <div className="w-6/12">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                        Meta Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                        {category ? category.meta_description?.ru : ''}
                      </dd>
                    </div>
                    <div className="w-6/12">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                        Meta Title
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                        {category ? category.meta_title?.ru : ''}
                      </dd>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
            <div className={`${ru ? 'hidden' : ''}`}>
              <dl className="pb-5 space-y-8 px-4 sm:px-6 sm:space-y-6">
                <div className="w-12/12">
                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                    Описание
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {category ? category.description?.uz : ''}
                  </dd>
                </div>
              </dl>
              <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                <div className="flex justify-between">
                  <div className="flex justify-between space-x-6">
                    <div className="w-6/12">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                        Meta Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                        {category ? category.meta_description?.uz : ''}
                      </dd>
                    </div>
                    <div className="w-6/12">
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                        Meta Title
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                        {category ? category.meta_title?.uz : ''}
                      </dd>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
