import { authActions } from '@store/auth/auth.slices';
import history from '../../history'
import { userService } from '@services/auth.service';
import { setMessage } from '@services/dispatch.service';

const clearStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expired_at');
}
export const userSet = (user: any) => async (dispatch: any) => {
  dispatch(authActions.setUser({ user }))
}
export const getUser = () => async (dispatch: any) => {
  return userService()
    .then(user => {
      dispatch(userSet(user));
    })
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
}

export const autoLogIn = () => async (dispatch: any) => {
  const token = localStorage.getItem('token');
  const expired_at = localStorage.getItem('expired_at');
  if (expired_at) {
    const [expires, now] = [new Date(expired_at), new Date()]
    if (expires.getTime() <= now.getTime()) {
      clearStorage()
      return history.push('/login', { from: history.location.pathname });
    }
    if (token) return dispatch(authActions.logIn({ token, expired_at }));
  }
  history.push('/login', { from: history.location.pathname })
};

export const login = (data: any) => async (dispatch: any) => {
  localStorage.setItem('token', data.token ? data.token : '');
  localStorage.setItem('expired_at', data.expired_at ? data.expired_at : '')
  dispatch(authActions.logIn({ ...data }));
};

export const logout = () => async (dispatch: any) => {
  clearStorage()
  dispatch(authActions.logOut())
};
