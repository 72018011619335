import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBannedWord } from '@interfaces/index';

interface IState {
  bannedWords: IBannedWord[];
  bannedWord: IBannedWord | null;
  pagination: number;
}

const initialState: IState = {
  bannedWords: [],
  bannedWord: null,
  pagination: 0,
};

export const { actions: bannedWordsAction, reducer: bannedWordsReducer } = createSlice({
  name: 'bannedWords',
  initialState,
  reducers: {
    setBannedWords: (state, action: PayloadAction<{ bannedWords: IBannedWord[], pagination: number }>) => ({
      ...state,
      bannedWords: action.payload.bannedWords,
      pagination: action.payload.pagination,
    }),
    setBannedWord: (state, action: PayloadAction<{ bannedWord: IBannedWord | null }>) => ({
      ...state,
      bannedWord: action.payload.bannedWord,
    }),
  },
});