import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoginPage } from '@pages/login';
import { PrivateRoute } from '@helpers/private-route';
import { autoLogIn } from '@store/auth/auth.thunks';
import Layout from '@helpers/layout';
import { Notification } from '@components/shared/notification';

function App() {
  const dispatch = useDispatch();
  dispatch(autoLogIn());

  return (
    <>
    <Switch>
        <Route path='/login' component={LoginPage} exact/>
        <PrivateRoute path={'/'} component={Layout}/>
    </Switch>
      <Notification />
    </>
  );
}

export default App;


