import React from 'react';
import { postCheckbox } from '@_data/titles';
import { UseFormRegister } from 'react-hook-form';
import { IPost } from '@interfaces/index';

interface Props {
  register: UseFormRegister<any>,
  post: IPost | null
}

export const Settings: React.FC<Props> = ({ register, post}) => {
  return (
    <div id='settings'>
      <div className='shadow sm:rounded-md sm:overflow-hidden'>
        <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>Настройки</h3>
          </div>
          <fieldset className='border-t border-b border-gray-200'>
            <div className='divide-y divide-gray-200'>
              {
                postCheckbox.map(field => {
                  return (
                    <div key={field.id} className='relative flex items-start py-4'>
                      <div className='min-w-0 flex-1 text-sm'>
                        <label htmlFor='comments' className='font-medium text-gray-700'>
                          {field.title}
                        </label>
                        <p id='comments-description' className='text-gray-500'>
                          {field.description}
                        </p>
                      </div>
                      <div className='ml-3 flex items-center h-5'>
                        <input
                          type='checkbox'
                          defaultChecked={post ? !!post[field.field] : false}
                          {...register(field.field)}
                          className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};