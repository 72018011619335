import { api } from '@api/index';

export const deleteImage = (id: number) => {
  return api
    .post(`/dashboard/posts/${id}/remove/image`)

}
export const createPost = (data: any) => {
  return api
    .post('/dashboard/posts', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => res.data.result.post)
}
export const refreshPost = (id: number) => {
  return api
    .post(`/dashboard/posts/${id}/restore`)
    .then(res => res.data.result.post)
}
export const updatePost = (id: number, data: any) => {
  return api
    .post(`/dashboard/posts/${id}`, data)
    .then(res => res.data.result.post)
}

export const deletePost = (id: number) => {
  return api
    .delete(`/dashboard/posts/${id}`)
    .then(res => res.data.result)
}
export const moderatePost = (id: number, data: any) => {
  return api
    .post(`/dashboard/posts/${id}/moderate`, data)
    .then(res => res.data.result)
}

export const getPostsService = (params: any) => {
  return api
    .get(`/dashboard/posts`, { params })
    .then(res => res.data.result.posts)
}
export const getLastPopularPostsService = () => {
  return api
    .get(`/dashboard/last/posts`)
    .then(res => res.data.result.posts)
}

export const getPostService = (id: number) => {
  return api
    .get(`/dashboard/posts/${id}`)
    .then(res => res.data.result.post)
}