import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createCategory } from '@services/categories.service';
import { XIcon } from '@heroicons/react/solid';
import { getCategories } from '@store/categories/categories.thunks';
import { useDispatch } from 'react-redux';
import { Tab } from '@components/shared/tab';
import { formatData } from '@helpers/format-data';
import { imageUpload } from '@helpers/preview';
import { errorNames } from '@helpers/errorhandle';
import { Alert } from '@components/posts/UI/alert';
import { CategoryContext } from '@helpers/context';

interface Props {
  setOpen: (open: boolean) => void;
  parent_id?: number;
}

export const CreateCategory: React.FC<Props> = ({ setOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const filter = useContext(CategoryContext);
  const dispatch = useDispatch();
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>();
  const [ru, setRu] = useState(true);
  const close = () => {
    setOpen(false);
  };
  const submitHandle = async (formdata: any) => {
    await createCategory(formatData({ formdata, image: file }))
      .then(() => {
        dispatch(getCategories(filter));
        reset();
        close();
      })
      .catch((err) => errorNames(err, setError, dispatch));
  };
  return (
    <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
      <div className="px-4 py-6 sm:px-6 ">
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-lg font-medium text-gray-900"
          >
            Создать категорию
          </h2>
          <div className="ml-3 h-7 flex items-center">
            <button
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
              onClick={close}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <form className='h-full' onSubmit={handleSubmit(submitHandle)}>
        <div className="shadow overflow-hidden sm:rounded-md dropdown-box flex h-full flex-col justify-between">
          <div className="px-4 sm:px-6 grid grid-cols-6 gap-6 p-5 items-center">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Фото
              </label>
              <div className="mt-1 flex items-center">
                <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                  <img src={preview} alt="" className="h-full" />
                </span>
                <div>
                  <label
                    htmlFor="upload-image"
                    className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Upload
                  </label>
                  <input
                    id="upload-image"
                    type="file"
                    className="w-0"
                    onChange={imageUpload(setPreview, setFile)}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3 mt-5">
              <div className="min-w-0 flex gap-1 text-sm">
                <div className="ml-3 flex items-center h-5">
                  <input
                    type="checkbox"
                    {...register(`auto_notification`)}
                    defaultChecked={true}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <label
                  htmlFor={`person`}
                  className="font-medium text-gray-700 select-none"
                >
                  Авто уведомление
                </label>
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6">
            <input
              placeholder="Ссылка"
              {...register('slug', {})}
              className={`${
                errors.slug ? 'border-red-300' : 'border-gray-300'
              } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            <p className="mt-2 text-red-600 text-sm">
              {errors.slug && errors.slug.message}
            </p>
          </div>
          <div className="px-4 sm:px-6">
            <input
              placeholder="Позиция"
              type="number"
              {...register('position', {})}
              className={`${
                errors.slug ? 'border-red-300' : 'border-gray-300'
              } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            <p className="mt-2 text-red-600 text-sm">
              {errors.position && errors.position.message}
            </p>
          </div>
          <div className="px-4 sm:px-6 pb-4 sm:pb-6">
            {(errors.name?.ru && <Alert message={errors.name?.ru.message} />) ||
              (errors.name?.uz && <Alert message={errors.name?.uz.message} />)}
            <Tab setRu={setRu} ru={ru} />
          </div>
          <div className={`${ru ? '' : 'hidden'}`}>
            <div className="px-4 sm:px-6 pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Название:
                </label>
                <input
                  {...register('name.ru', {})}
                  className={`${
                    errors.name?.ru ? 'border-red-300' : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.name?.ru && errors.name?.ru.message}
                </p>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="meta_title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Title:
                </label>
                <input
                  {...register('meta_title.ru', {})}
                  className={`${
                    errors.meta_title?.ru ? 'border-red-300' : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.meta_title?.ru && errors.meta_title?.ru.message}
                </p>
              </div>
            </div>
            <div className="pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Описание:
                </label>
                <textarea
                  {...register('description.ru', {})}
                  className={`${
                    errors.description?.ru
                      ? 'border-red-300'
                      : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.description?.ru && errors.description?.ru.message}
                </p>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="meta_description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Description:
                </label>
                <textarea
                  {...register('meta_description.ru', {})}
                  className={`${
                    errors.meta_description?.ru
                      ? 'border-red-300'
                      : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.meta_description?.ru &&
                    errors.meta_description?.ru.message}
                </p>
              </div>
            </div>
          </div>
          <div className={`${ru ? 'hidden' : ''}`}>
            <div className="pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Название(uz):
                </label>
                <input
                  {...register('name.uz', {})}
                  className={`${
                    errors.name?.uz ? 'border-red-300' : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.name?.uz && errors.name?.uz.message}
                </p>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="meta_title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Title(uz):
                </label>
                <input
                  {...register('meta_title.uz', {})}
                  className={`${
                    errors.meta_title?.uz ? 'border-red-300' : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.meta_title?.uz && errors.meta_title?.uz.message}
                </p>
              </div>
            </div>
            <div className="pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Описание(uz):
                </label>
                <textarea
                  {...register('description.uz', {})}
                  className={`${
                    errors.description?.uz
                      ? 'border-red-300'
                      : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.description?.uz && errors.description?.uz.message}
                </p>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="meta_description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Description(uz):
                </label>
                <textarea
                  {...register('meta_description.uz', {})}
                  className={`${
                    errors.meta_description?.uz
                      ? 'border-red-300'
                      : 'border-gray-300'
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                />
                <p className="mt-2 text-red-600 text-sm">
                  {errors.meta_description?.uz &&
                    errors.meta_description?.uz.message}
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="button"
              className="transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={close}
            >
              Отмена
            </button>
            <button
              type="submit"
              className="transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Создать
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
