import { setMessage } from '@services/dispatch.service';
import { bannedWordsAction } from '@store/bannedWords/bannedWords.slices';
import { getBannedWordService, getBannedWordsService } from '@services/bannedWords.service';
import { IBannedWord } from '@interfaces/index';

export const bannedWordSet = (bannedWord: IBannedWord | null) => async (dispatch: any) => {
  dispatch(bannedWordsAction.setBannedWord({ bannedWord }));
}
export const bannedWordsSet = (res: any) => async (dispatch: any) => {
  dispatch(bannedWordsAction.setBannedWords({
    bannedWords: res.data,
    pagination: res.total,
  }));
}

export const getBannedWords = (params: any) => async (dispatch: any) => {
  return getBannedWordsService(params)
    .then(bannedWords => dispatch(bannedWordsSet(bannedWords)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};

export const getBannedWord = (id: string) => async (dispatch: any) => {
  return getBannedWordService(id)
    .then((bannedWord) => dispatch(bannedWordSet(bannedWord)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};