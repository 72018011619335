import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useForm, useFormState } from 'react-hook-form';
import { IFilters } from '@interfaces/index';
import { Select } from '@components/shared/select';
import { Toggle } from '@components/shared/toggle';
import { useSelector } from 'react-redux';
import { State } from '@store/index';
import { SearchSelect } from '@components/shared/searchselect/searchselect';
import { getPostsService } from '@services/posts.service';
import { getUsersService } from '@services/users.service';
import './filter-bar.scss';

interface IField {
  field: string;
  label: string;
}

interface Props {
  fields?: IField[];
  handleFilter: (data: any) => void;
  handleReset?: () => void;
}

export const FilterBar: React.FC<Props> = ({ fields, handleFilter, handleReset }) => {
  const { register, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, []);
  const applyFilters = (data: IFilters) => {
    handleFilter(data);
    setOpen(!open);
  };
  const close = (e: any) => {
    if (e.target.closest('#open_filter')) return;
    setOpen(false);
  };
  const resetFilters = () => {
    reset();
    handleReset && handleReset();
  };

  return (
    <div
      className='relative filter-bar mb-3'
      id='open_filter'
    >
      <div className='flex items-center'>
        <button
          onClick={() => setOpen(!open)}
          className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
          type='button'
        >
          Фильтр
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </button>
        <div className='w-6/12'>
          <p
            className={`${isDirty || open ? '' : 'hidden'}  ml-2 text-red-500 reset-form text-xs`}
            onClick={resetFilters}
          >
            Сбросить
          </p>
        </div>
      </div>
      <form
        className={`${open ? '' : 'hidden'} flex items-end gap-3 p-3 mt-3 w-min rounded-md focus:outline-none`}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='w-12/12 sm:flex sm:items-end sm:space-x-2 '>
          {fields && fields.map((field, index) => {
            return (
              <div
                className={`${index === 0 ? 'mt-1' : 'mt-3'} w-72 sm:w-60`}
                key={field.field}
              >
                <label htmlFor={field.field} className='block capitalize text-sm font-medium text-gray-700'>
                  {field.label}
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    id={field.field}
                    {...register(field.field)}
                    className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border px-3 py-2 rounded-md'
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className='sm:flex items-center justify-start mt-4 sm:w-12/12'>
          <div
            className='flex sm:w-10/12 w-full'
          >
            <div className='mt-1 w-6 text-start cursor-pointer'>
              <input
                type='checkbox'
                id='with_trashed'
                {...register('with_trashed')}
                className='shadow-sm w-6 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border rounded-md'
              />
            </div>
            <label htmlFor='with_trashed'
                   className='w-42 block capitalize text-sm font-medium text-gray-700'>
              Удаленные
            </label>
          </div>
          <div className='w-full sm:w-24 sm:order-first'>
            <button
              type='submit'
              className='w-full text-center items-center px-2.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Применить
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const FilterBarPost: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const categories = useSelector((state: State) => state.categories.allCategories);
  const authors = useSelector((state: State) => state.users.authors);
  const { register, handleSubmit, reset, control, formState: { isDirty } } = useForm();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, []);
  const applyFilters = (data: any) => {
    handleFilter({
      ...data,
      category: data.category?.id,
      author: data.author_id?.id,
    });
    setOpen(!open);
  };
  const close = (e: any) => {
    if (e.target.closest('#open_filter')) return;
    setOpen(false);
  };
  const resetFilters = () => {
    reset();
    handleReset && handleReset();
  };
  return (
    <div
      className='relative mb-2'
      id='open_filter'
    >
      <div className='flex items-center w-12/12 gap-2'>
        <div>
          <input
            type='text'
            {...register('title')}
            onChange={e => handleFilter({ title: e.target.value })}
            className='w-24 sm:w-52 shadow-sm focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
            placeholder='Название'
          />
        </div>
        <button
          onClick={() => setOpen(!open)}
          className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
          type='button'
        >
          Фильтр
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </button>
        <div className='w-6/12'>
          <p
            className={`${isDirty || open ? '' : 'hidden'}  ml-2 text-red-500 reset-form text-xs`}
            onClick={resetFilters}
          >
            Сбросить
          </p>
        </div>
      </div>
      <form
        className={`${open ? '' : 'hidden'} form_width p-3 mt-3 rounded-md focus:outline-none`}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='flex flex-wrap gap-4 items-center'>
          <div className='max-w-24'>
            <Select
              items={categories}
              title='Категории'
              selected={null}
              name='category'
              control={control}
            />
          </div>
          <div className='max-w-24'>
            <Select
              items={authors}
              title='Автор'
              selected={null}
              name='author_id'
              control={control}
            />
          </div>
          <div>
            <Toggle title='Опубликовано' control={control} name='published' defaultValue={false} />
          </div>
          <div>
            <Toggle title='Спец' control={control} name='special_report' defaultValue={false} />
          </div>
          <div>
            <Toggle title='Популярные' control={control} name='popular' defaultValue={false} />
          </div>
          <div>
            <Toggle title='Горячие' control={control} name='hot_post' defaultValue={false} />
          </div>
          <div>
            <Toggle title='К модерации' control={control} name='moderate' defaultValue={false} />
          </div>
          <div>
            <Toggle title='Авторы' control={control} name='authors' defaultValue={false} />
          </div>
          <div className='flex gap-4 items-center'>
            <div className='flex'>
              <div className='mt-1 w-6 text-start cursor-pointer'>
                <input
                  type='checkbox'
                  id='with_trashed'
                  {...register('with_trashed')}
                  className='shadow-sm w-6 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border rounded-md'
                />
              </div>
              <label htmlFor='with_trashed' className='w-42 block capitalize text-sm font-medium text-gray-700'>
                Удаленные
              </label>
            </div>
            <div className='sm:order-first'>
              <button
                type='submit'
                className='w-full text-center items-center px-2.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Применить
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const FilterBarComment: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const { register, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const [open, setOpen] = useState(false);
  const [post, setPost] = useState<any>();
  const [user, setUser] = useState<any>();
  useEffect(() => {
    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, []);
  const applyFilters = (data: any) => {
    handleFilter({ ...data, post, user });
    setOpen(!open);
  };
  const close = (e: any) => {
    if (e.target.closest('#open_filter')) return;
    setOpen(false);
  };
  const resetFilters = () => {
    reset();
    setPost(null);
    setUser(null);
    handleReset && handleReset();
  };
  return (
    <div
      className='relative filter-bar my-3'
      id='open_filter'
    >
      <div className='flex items-center gap-2'>
        <div>
          <input
            type='text'
            {...register('text')}
            className='w-24 sm:w-52 shadow-sm focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
            placeholder='Содержание'
          />
        </div>
        <button
          onClick={() => setOpen(!open)}
          className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
          type='button'
        >
          Фильтр
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </button>
        <div className='w-6/12'>
          <p
            className={`${isDirty || open ? '' : 'hidden'}  ml-2 text-red-500 reset-form text-xs`}
            onClick={resetFilters}
          >
            Сбросить
          </p>
        </div>
      </div>
      <form
        className={`${open ? '' : 'hidden'} form_width p-3 mt-3 focus:outline-none`}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='flex flex-wrap gap-4 items-center'>
          <div className='w-72'>
            <SearchSelect
              title='Статья'
              setSelected={setPost}
              getItems={(title: string) => getPostsService({ title })}
              ru={true}
              isMulti={false}
            />
          </div>
          <div className='w-52'>
            <SearchSelect
              title='Пользователь'
              setSelected={setUser}
              getItems={(name: string) => getUsersService({ name })}
              ru={true}
              isMulti={false}
            />
          </div>
          <div>
            <Toggle title='Жалобы' control={control} name='reported' defaultValue={false} />
          </div>
          <div>
            <Toggle title='Забаненные' control={control} name='banned' defaultValue={false} />
          </div>
          <div>
            <Toggle title='Есть запрещенные слова' control={control} name='special_report' defaultValue={false} />
          </div>
          <div className='sm:flex items-center justify-start sm:w-12/12'>
            <div
              className='flex sm:w-10/12 w-full'
            >
              <div className='mt-1 w-6 text-start cursor-pointer'>
                <input
                  type='checkbox'
                  id='with_trashed'
                  {...register('with_trashed')}
                  className='shadow-sm w-6 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border rounded-md'
                />
              </div>
              <label htmlFor='with_trashed'
                     className='w-42 block capitalize text-sm font-medium text-gray-700'>
                Удаленные
              </label>
            </div>
            <div className='w-full sm:w-24 sm:order-first'>
              <button
                type='submit'
                className='w-full text-center items-center px-2.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Применить
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const FilterBarUser: React.FC<Props> = ({ handleFilter, handleReset }) => {
  const { register, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const roles = useSelector((state: State) => state.roles.allRoles);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, []);
  const applyFilters = (data: any) => {
    handleFilter({ ...data, role: data.role?.id });
    setOpen(!open);
  };
  const close = (e: any) => {
    if (e.target.closest('#open_filter')) return;
    setOpen(false);
  };
  const resetFilters = () => {
    reset();
    handleReset && handleReset();
  };
  return (
    <div
      className='relative filter-bar my-3'
      id='open_filter'
    >
      <div className='flex items-center gap-2'>
        <button
          onClick={() => setOpen(!open)}
          className='inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
          type='button'
        >
          Фильтр
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </button>
        <div className='w-6/12'>
          <p
            className={`${isDirty || open ? '' : 'hidden'}  ml-2 text-red-500 reset-form text-xs`}
            onClick={resetFilters}
          >
            Сбросить
          </p>
        </div>
      </div>
      <form
        className={`${open ? '' : 'hidden'} form_width p-3 mt-3 focus:outline-none`}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='flex flex-wrap gap-4 items-end'>
          <div className='max-w-24'>
            <Select items={roles} title='Роли' selected={null} name='role' control={control} />
          </div>
          <input
            type='text'
            {...register('name')}
            className='w-24 sm:w-52 shadow-sm focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
            placeholder='Имя'
          />
          <input
            type='text'
            {...register('email')}
            className='w-24 sm:w-52 shadow-sm focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
            placeholder='Email'
          />
          <input
            type='text'
            {...register('phone')}
            className='w-24 sm:w-52 shadow-sm focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
            placeholder='Номер'
          />
          <div className='sm:flex items-center justify-start sm:w-12/12'>
            <div
              className='flex sm:w-10/12 w-full'
            >
              <div className='mt-1 w-6 text-start cursor-pointer'>
                <input
                  type='checkbox'
                  id='with_trashed'
                  {...register('with_trashed')}
                  className='shadow-sm w-6 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border rounded-md'
                />
              </div>
              <label htmlFor='with_trashed'
                     className='w-42 block capitalize text-sm font-medium text-gray-700'>
                Удаленные
              </label>
            </div>
            <div className='w-full sm:w-24 sm:order-first'>
              <button
                type='submit'
                className='w-full text-center items-center px-2.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Применить
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};