import React, { useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilAltIcon } from '@heroicons/react/outline';
import { IComment } from '@interfaces/index';
import { formatDate } from '@helpers/format-date';
import { useForm } from 'react-hook-form';
import { deleteUser } from '@services/users.service';
import { deleteComment, updateComment } from '@services/comments.service';
import { useDispatch } from 'react-redux';
import { getComments } from '@store/comments/comments.thunks';
import { CommentContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';

interface Props {
  comment: IComment;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface CommentContentProps {
  comment: IComment;
  setOpen: (open: boolean) => void;
  setType: (open: string) => void;
}

const [commentariy, ban, deactivate, edit] = ['Комментарий', 'Забанить пользователя', 'Деактивация комментария', 'Редактировать комментарий'];
export const ActionModal: React.FC<Props> = ({ comment, setOpen, open }) => {
  const [type, setType] = useState(commentariy);
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={() => {
        setOpen(false);
        setType(commentariy);
      }}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              {
                commentariy === type &&
                <CommentContent comment={comment} setType={setType} setOpen={setOpen}>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    {type}
                  </Dialog.Title>
                </CommentContent>
              }
              {
                deactivate === type &&
                <DeactivateContent comment={comment} setType={setType} setOpen={setOpen}>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    {type}
                  </Dialog.Title>
                </DeactivateContent>
              }
              {
                edit === type &&
                <EditContent comment={comment} setType={setType} setOpen={setOpen}>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    {type}
                  </Dialog.Title>
                </EditContent>
              }
              {
                ban === type &&
                <DeactivateUser comment={comment} setType={setType} setOpen={setOpen}>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    {type}
                  </Dialog.Title>
                </DeactivateUser>
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const CommentContent: React.FC<CommentContentProps> = ({ comment, setOpen, setType, children }) => {
  const dispatch = useDispatch();
  const filter = useContext(CommentContext);
  const moderate = () => {
    updateComment(comment.id, { status: 'approved' })
      .then(() => {
        dispatch(getComments(filter));
        setMessage(dispatch, 'Комментарий отмечен как провереный');
        setOpen(false);
        setType(commentariy);
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  return (
    <>
      <div>
        <div className='flex items-center px-5'>
          <div className='flex-shrink-0'>
            <img className='h-10 w-10 rounded-full' src={comment.user?.avatar} alt='' />
          </div>
          <div className='ml-3'>
            <div className='text-base font-medium text-gray-800'>{comment.user?.name}</div>
            <div className='text-sm font-medium text-gray-500'>{formatDate(comment.created_at)}</div>
          </div>
          <button
            onClick={() => setType(ban)}
            type='button'
            className='ml-auto mr-2 flex-shrink-0 p-2 bg-gray-200 p-1 text-black font-medium rounded-full hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          >
            Забанить
          </button>
          <button
            onClick={() => setType(edit)}
            type='button'
            className='ml-auto flex-shrink-0 p-2 bg-indigo-600 p-1 text-white rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          >
            <span className='sr-only'>View notifications</span>
            <PencilAltIcon className='h-6 w-6 text-white' aria-hidden='true' />
          </button>
        </div>
        <div className='mt-3 text-center sm:mt-5'>
          {
            children
          }
          <div className='mt-2'>
            <p className='text-sm text-gray-500'>
              {comment.text}
            </p>
          </div>
        </div>
      </div>
      <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
        <button
          type='button'
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
          onClick={() => setType(deactivate)}
        >
          Деактивировать
        </button>
        <button
          disabled={comment.status === 'approved'}
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
          onClick={moderate}
        >
          Прочитано
        </button>
      </div>
    </>
  );
};
const DeactivateContent: React.FC<CommentContentProps> = ({ comment, setOpen, setType, children }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const filter = useContext(CommentContext);
  const submit = (formdata: any) => {
    deleteComment(comment.id, formdata)
      .then(() => {
        dispatch(getComments(filter));
        setMessage(dispatch, 'Комментарий успешно деактевирован');
        setType(commentariy);
        setOpen(false);
      })
      .catch(e => setMessage(dispatch, e.response.data || e));
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      {
        children
      }
      <div className='mt-2'>
        <label htmlFor='reason' className='block text-sm font-medium text-gray-700'>
          Причина
        </label>
        <input
          defaultValue={comment.reason}
          {...register('reason')}
          className='shadow-sm w-full focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
          placeholder='Причина'
        />
        <p className='mt-2 text-red-600 text-sm'>{(errors.reason && errors.reason?.message)}</p>
      </div>
      <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
        <button
          type='submit'
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
        >
          Деактивировать
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
          onClick={() => setType(commentariy)}
        >
          Назад
        </button>
      </div>
    </form>
  );
};
const EditContent: React.FC<CommentContentProps> = ({ comment, setType, children }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const filter = useContext(CommentContext);
  const submit = (formdata: any) => {
    updateComment(comment.id, { ...formdata })
      .then(() => {
        setMessage(dispatch, 'Изменения успешно сохранены');
        dispatch(getComments(filter));
      })
      .catch(e => setMessage(dispatch, e.response.data || e));
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      {
        children
      }
      <div className='mt-2'>
        <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
          Комментарий
        </label>
        <div className='mt-1'>
          <textarea
            defaultValue={comment.text}
            {...register('text', {
              required: 'Поле не может быть пустым',
            })}
            className='shadow-sm border rounded-md p-2 focus:ring-indigo-500 h-36 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          />
        </div>
        <p className='mt-2 text-red-600 text-sm'>{(errors.text && errors.text?.message)}</p>
      </div>
      <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
        <button
          type='submit'
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
        >
          Сохранить
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
          onClick={() => setType(commentariy)}
        >
          Назад
        </button>
      </div>
    </form>
  );
};
const DeactivateUser: React.FC<CommentContentProps> = ({ comment, setOpen, setType, children }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const banUser = (data: any) => {
    deleteUser(comment.user_id, data.reason)
      .then(() => {
        setMessage(dispatch, 'Пользователь забанен');
        setOpen(false);
        setType(commentariy);
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  return (
    <form onSubmit={handleSubmit(banUser)}>
      {
        children
      }
      <div className='mt-2'>
        <label htmlFor='reason' className='block text-sm font-medium text-gray-700 mb-2'>
          Причина
        </label>
        <textarea
          {...register('reason')}
          className='shadow-sm w-full focus:ring-indigo-500 border py-2 px-3 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md'
          placeholder='Причина'
        />
        <p className='mt-2 text-red-600 text-sm'>{(errors.reason && errors.reason?.message)}</p>
      </div>
      <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
        <button
          type='submit'
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
        >
          Деактивировать
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
          onClick={() => setType(commentariy)}
        >
          Назад
        </button>
      </div>
    </form>
  );
};
