import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPermission } from '@interfaces/index';

interface IState {
  permissions: IPermission[];
}

const initialState: IState = {
  permissions: [],
};

export const { actions: permissionsAction, reducer: permissionsReducer } = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<{ permissions: IPermission[] }>) => ({
      ...state,
      permissions: action.payload.permissions,
    }),
  },
});