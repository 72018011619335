import React from 'react';

interface Props {
  tableTitles: any[]
}

export const Thead: React.FC<Props> = ({tableTitles}) => {
  return (
    <thead className='bg-gray-50'>
    <tr>
      {tableTitles.map((title, i) => {
        if (title === 'Изменить') {
          return (<th key={title + i} scope='col' className='relative px-6 py-3'>
            <span className='sr-only'>Изменить</span>
          </th>);
        }

        return (
          <th
            key={title + i}
            scope='col'
            className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
          >
            {title}
          </th>
        )
      })}
    </tr>
    </thead>
  );
};