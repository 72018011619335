import { api } from '@api/index';

export const createBannedWord = (data: any) => {
  return api
    .post('/dashboard/banned-words', data)
    .then(res => res.data.result.word)
}
export const updateBannedWord = (id: number, data: any) => {
  return api
    .post(`/dashboard/banned-words/${id}`, {
      ...data,
      _method: "PATCH"
    })
    .then(res => res.data.result.word)
}
export const refreshBannedWord = (id: number) => {
  return api
    .post(`/dashboard/banned-words/${id}/restore`)
    .then(res => res.data.result.word)
}
export const deleteBannedWord = (id: number) => {
  return api
    .post(`/dashboard/banned-words/${id}`, {
      _method: 'DELETE'
    })
    .then(res => res.data.result)
}
export const getBannedWordsService = (params: any) => {
  return api
    .get(`/dashboard/banned-words`, { params })
    .then(res => res.data.result.banned_words)
}
export const getBannedWordService = (id: string) => {
  return api
    .get(`/dashboard/banned-words/${id}`)
    .then(res => res.data.result)
}