import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { getPost, postSet } from '@store/posts/posts.thunks';
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '@pages/posts/EDITOR_JS_TOOLS';
import { useParams } from 'react-router-dom';
import { Header } from '@components/posts/UI/header';
import { PostModerateButtons } from '@components/posts/UI/buttons';
import { Toggle } from '@components/shared/toggle';
import { Alert } from '@components/posts/UI/alert';
import { moderatePost } from '@services/posts.service';
import { useHistory } from 'react-router';
import { setMessage } from '@services/dispatch.service';
import { useForm } from 'react-hook-form';

const tools: any = EDITOR_JS_TOOLS

export const ModeratePost: React.FC = () => {
  const {handleSubmit, control} = useForm()
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  useEffect(() => {
    dispatch(getPost(+params.id));
    return () => {
      dispatch(postSet(null));
    };
  }, [dispatch, params.id]);
  const post = useSelector((state: State) => state.posts.post);
  const [loading, setLoading] = useState(false);

  const submitHandle = (data: any) => {
    setLoading(true);
    moderatePost(+params.id, data)
      .then(() => {
        setLoading(false);
        history.push(`/posts/show/${params.id}`);
      })
      .catch(err => setMessage(dispatch, err.response.data.message || err));
  };
  return (
    <div
      className='post_container lg:pl-20 px-2 sm:pt-1'
    >
      <Header post={post} isModeration={true}>
        <PostModerateButtons onSubmit={handleSubmit(submitHandle)} loading={loading} slug={post?.slug}/>
      </Header>
      <div>
        {
          !!post && !post?.active && <div className='flex space-x-2 my-3'>
            <Toggle title={'Горячая новость'} name='hot_post' defaultValue={post.hot_post} control={control}/>
            <Toggle title={'Спец. репортаж'} name='special_report' defaultValue={!!post.special_report} control={control}/>
            <Toggle title={'Уведомить пользователя'} name='should_notify' defaultValue={false} control={control}/>
          </div>
        }
        {post?.error && <Alert message={post.error} />}
      </div>
      <div className='pb-1 sm:pb-6'>
        <img
          className='h-full w-full mx-auto object-contain mt-4'
          src={post?.image}
          alt=''
        />
        <p className='text-sm font-normal mt-2 mb-4'>{post?.caption}</p>
        <p className='text-base font-normal my-4'>{post?.meta_description}</p>
        <div
          className={'mb-5 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md'}>
          {
            !!post && <EditorJs
              readOnly={true}
              tools={tools}
              data={JSON.parse(post.description)}
            />
          }
        </div>
      </div>
      <div className='flex justify-end'>
        <PostModerateButtons onSubmit={handleSubmit(submitHandle)} loading={loading} slug={post?.slug}/>
      </div>
    </div>
  );
};

