import { api } from '@api/index';
import { AxiosResponse } from 'axios';

interface IUsersParams {
  page?: number;
  limit?: number;
  email?: string;
  name?: string;
  phone?: string;
}
export const deleteImage = (id: number) => {
  return api
    .post(`/dashboard/users/${id}/remove/image`)
    .then((res) => res)
}
export const getUsersService = (params: IUsersParams) => {
  return api
    .get('/dashboard/users', {
      params,
    })
    .then((res) => res.data.result.users)
};
export const getAuthorsService = () => {
  return api
    .get('/dashboard/users/authors')
    .then((res) => res.data.result.users)
};
export const refreshUser = (id: number) => {
  return api
    .post(`/dashboard/users/${id}/restore`)
    .then(res => res.data.result.user)
}
export const deleteUser = (id: number, reason?: string): Promise<AxiosResponse> => {
  return api
    .post(`/dashboard/users/${id}`, {
      deleted_reason: reason,
      _method: 'DELETE'
    })
    .then((res) => res);
};

export const updateUser = (id: number, data: any) => {
  return api
    .post(`/dashboard/users/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data.result.user);
};

export const createUser = (data: any) => {
  return api
    .post('/dashboard/users', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}