import { formatPhoneNumber } from '@helpers/format-phone-number';

interface Args{
  formdata: any;
  image?: Blob | null;
  avatar?: Blob | null;
  phone?: string;
  patch?: boolean;
  data?: any;
  datauz?: any;
}
export const formatData = ({formdata, avatar, image, patch, data, datauz, phone}: Args) => {
  const postData = new FormData();
  const formatData: any = {
    ...formdata
  };
  Object.keys(formatData).forEach((key) => {
    if (formatData[key] !== undefined || formatData[key] !== null) {
      if (formatData[key] instanceof Object) {
        if (Object.keys(formatData[key]).length) {
          Object.keys(formatData[key]).forEach((key2) => {
            postData.append(`${key}[${key2}]`, formatData[key][key2]);
          });
          return;
        } else {
          postData.append(key, formatData[key]);
        }
      } else {
        postData.append(key, formatData[key]);
      }
    }
  });
  data && postData.append('description', JSON.stringify(data));
  // datauz && postData.append('description[uz]', JSON.stringify(datauz));
  image && postData.append('image', image);
  avatar && postData.append('avatar', avatar);
  phone && postData.append('phone', formatPhoneNumber(phone));
  patch && postData.append('_method', 'PATCH');

  return postData;
};