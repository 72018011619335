import React, { useState } from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Transition, Dialog } from '@headlessui/react';
import { DotsVerticalIcon, MenuIcon } from '@heroicons/react/solid';
import { sidebarItems } from '@_data/titles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '@store/auth/auth.thunks';
import { State } from '@store/index';
import { PermissionCheck } from '@helpers/permissions';
import { XIcon } from '@heroicons/react/outline';

export const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: State) => state.users.user);
  const signOut = () => {
    dispatch(logout());
    history.push('/login');
  };
  return (
    <>
      <div className='w-full h-12 bg-gray-800 flex justify-between items-center visible md:hidden'>
        <Link to='/'>
          <img
            className="h-8 w-8 ml-2"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
            alt="Workflow"
          />
        </Link>
        <div>
          <MenuIcon
            onClick={() => setOpen(true)}
            className='block h-6 w-6 text-white mr-2'
            aria-hidden='true'
          />
        </div>
      </div>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog as='div' className='fixed inset-0 overflow-hidden' onClose={setOpen}>
          <div className='absolute inset-0 overflow-hidden'>
            <Transition.Child
              as={React.Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>
            <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex'>
              <Transition.Child
                as={React.Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='relative w-screen max-w-md'>
                  <Transition.Child
                    as={React.Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4'>
                      <button
                        type='button'
                        className='rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
                        onClick={() => setOpen(false)}
                      >
                        <span className='sr-only'>Close panel</span>
                        <XIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
                    <div className='relative flex-1'>
                      {/* Replace with your content */}
                      <div className='flex flex-col h-full w-full'>
                        <div className='flex flex-col h-0 flex-1 bg-gray-800'>
                          <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
                            <div className='flex items-center flex-shrink-0 px-4'>
                              <img
                                className='h-8 w-auto'
                                src='https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg'
                                alt='Workflow'
                              />
                            </div>
                            <nav className='mt-5 flex-1 px-2 bg-gray-800 space-y-1'>
                              {
                                sidebarItems.map((item, i) => (
                                    <div
                                      key={item.name + i}
                                      onClick={() => setOpen(false)}
                                    >
                                      <PermissionCheck
                                        component={SidebarItem}
                                        operation={item.slug}
                                        item={item}
                                      />
                                    </div>
                                ))
                              }
                            </nav>
                          </div>
                          <div className='flex-shrink-0 flex bg-gray-700 p-4'>
                            <div className='flex-shrink-0 w-full group block'>
                              <div className='flex items-center justify-between'>
                                <div className='flex items-center'>
                                  <div>
                                    {
                                      !!user && <img
                                        className='inline-block h-9 w-9 rounded-full'
                                        src={user.avatar}
                                        alt=''
                                      />
                                    }
                                  </div>
                                  <div className='ml-3'>
                                    <p
                                      className='text-sm font-medium text-white overflow-hidden overflow-ellipsis whitespace-nowrap w-20 sm:w-40 cursor-pointer'>{user ? user.name : ''}</p>
                                    <p
                                      className='text-xs font-medium text-gray-300 group-hover:text-gray-200 cursor-pointer'>{user ? user.role?.name : ''}</p>
                                  </div>
                                </div>
                                <div className='w-1/12'>
                                  <Menu as='div' className='relative inline-block text-left'>
                                    {({ open }) => (
                                      <>
                                        <div>
                                          <Menu.Button className='rounded-full flex items-center text-gray-400'>
                                            <span className='sr-only'>Open options</span>
                                            <DotsVerticalIcon className='h-5 w-5' aria-hidden='true' />
                                          </Menu.Button>
                                        </div>

                                        <Transition
                                          show={open}
                                          as={React.Fragment}
                                          enter='transition ease-out duration-100'
                                          enterFrom='transform opacity-0 scale-95'
                                          enterTo='transform opacity-100 scale-100'
                                          leave='transition ease-in duration-75'
                                          leaveFrom='transform opacity-100 scale-100'
                                          leaveTo='transform opacity-0 scale-95'
                                        >
                                          <Menu.Items
                                            static
                                            className='origin-bottom-right absolute right-0 bottom-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                                          >
                                            <div className='py-1'>
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <NavLink
                                                    to='/profile'
                                                    className={classNames(
                                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                      'block px-4 py-2 text-sm',
                                                    )}
                                                  >
                                                    Профиль
                                                  </NavLink>
                                                )}
                                              </Menu.Item>
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <NavLink
                                                    to='/settings'
                                                    className={classNames(
                                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                      'block px-4 py-2 text-sm',
                                                    )}
                                                  >
                                                    Настройки
                                                  </NavLink>
                                                )}
                                              </Menu.Item>
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    type='button'
                                                    onClick={signOut}
                                                    className={classNames(
                                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                      'block w-full text-left px-4 py-2 text-sm w-full',
                                                    )}
                                                  >
                                                    Выйти
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </>
                                    )}
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const SidebarItem: React.FC<any> = ({ item }) => {
  return (
    <NavLink
      to={item.to}
      activeClassName='bg-gray-900 text-white'
      className='text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
      exact
    >
      <item.icon
        className={classNames(
          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
          'mr-3 flex-shrink-0 h-6 w-6',
        )}
        aria-hidden='true'
      />
      {item.name}
    </NavLink>
  );
};