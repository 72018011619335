import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, refreshUser } from '@services/users.service';
import { getUsers, userSet } from '@store/users/users.thunks';
import { State } from '@store/index';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { formatDate } from '@helpers/format-date';
import { XCircleIcon, PencilIcon, RefreshIcon } from '@heroicons/react/solid';
import { SlideOvers } from '@components/shared/slideovers';
import { Edit, UserPage } from '@pages/users';
import { UserContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import { Permission } from '@helpers/permissions';
import { obnovleniePolzovatelya, udaleniePolzovatelya, vosstanovleniePolzovatelya } from '@_data/permissions';

interface Props {
}

export const UsersTBody: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const filter = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('show');
  const user = useSelector((state: State) => state.users.user);
  const users = useSelector((state: State) => state.users.users);
  const handleDelete = (id: number) => {
    deleteUser(id).then(() => {
      setOpen(false);
      dispatch(userSet(null));
      dispatch(getUsers(filter));
    });
  };
  const refresh = async (id: number) => {
    await refreshUser(id)
      .then(() => {
        dispatch(getUsers(filter));
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
    {
      !!users.length && users.map((user) => (
          <tr
            key={user.id}
            className='cursor-pointer'
            onClick={(e) => {
              e.stopPropagation();
              setShow(true);
              setMode('show');
              dispatch(userSet(user));
            }}
          >
            <td className='px-6 py-4 whitespace-nowrap'>
              <div className='flex items-center'>
                <div className='flex-shrink-0 h-10 w-10'>
                  <img className='h-10 w-10 rounded-full' src={user.avatar} alt='' />
                </div>
                <div className='ml-4'>
                  <div className='text-sm font-medium text-gray-900'>{user.name}</div>
                  <div className='text-sm text-gray-500'>{user.email}</div>
                </div>
              </div>
            </td>
            <td className='px-6 py-4 whitespace-nowrap'>
              <div className='text-sm text-gray-900'>{user.role ? user.role.name : ''}</div>
            </td>
            <td className='px-6 py-4 whitespace-nowrap'>
              <div className='text-sm text-gray-900'>{user.phone ? +user.phone : ''}</div>
            </td>
            <td className='px-6 py-4 whitespace-nowrap'>
              <div className='text-sm text-gray-900'>{formatDate(user.created_at)}</div>
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-right'>
              <div className='flex justify-end'>
                {
                  user.deleted_at
                    ? (
                      <Permission action={vosstanovleniePolzovatelya}>
                        <button
                          className='text-gray-600 hover:text-gray-900'
                          onClick={(e) => {
                            e.stopPropagation();
                            refresh(user.id);
                          }}
                        >
                          <RefreshIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                        </button>
                      </Permission>
                    )
                    : <>
                      <Permission action={obnovleniePolzovatelya}>
                        <button
                          className='text-gray-600 hover:text-gray-900'
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(userSet(user));
                            setMode('edit');
                            setShow(true);
                          }}
                        >
                          <PencilIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                        </button>
                      </Permission>
                      <Permission action={udaleniePolzovatelya}>
                        <button
                          className='ml-3 text-red-600 hover:text-red-900'
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(userSet(user));
                            setOpen(true);
                          }}
                        >
                          <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                        </button>
                      </Permission>
                    </>
                }
              </div>
            </td>
          </tr>
        ),
      )
    }
    {
      user && <Permission action={udaleniePolzovatelya}><PopupDelete
        setMode={setMode}
        setShow={setShow}
        open={open}
        setOpen={setOpen}
        data={user}
        handleDelete={handleDelete}
      /></Permission>
    }
    <SlideOvers
      Show={UserPage}
      Edit={Edit}
      show={show}
      mode={mode}
      confirmModal={setOpen}
      setMode={setMode}
      setShow={setShow}
    />
    </tbody>
  );
};