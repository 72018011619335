import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateCategory } from '@services/categories.service';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { XIcon } from '@heroicons/react/solid';
import {
  categorySet,
  getCategories,
} from '@store/categories/categories.thunks';
import { deleteImage } from '@services/categories.service';
import { Tab } from '@components/shared/tab';
import { formatData } from '@helpers/format-data';
import { imageUpload } from '@helpers/preview';
import { errorNames } from '@helpers/errorhandle';
import { Alert } from '@components/posts/UI/alert';
import { CategoryContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';

interface Props {
  confirmModal: (open: boolean) => void;
  setOpen: (open: boolean) => void;
  setMode: (mode: string) => void;
}

export const EditCategory: React.FC<Props> = ({
  confirmModal,
  setOpen,
  setMode,
}) => {
  const dispatch = useDispatch();
  const filter = useContext(CategoryContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const category = useSelector((state: State) => state.categories.category);
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>(category?.image);
  const [ru, setRu] = useState(true);
  const close = () => {
    setOpen(false);
    dispatch(categorySet(null));
  };
  const submitHandle = async (formdata: any) => {
    await updateCategory(
      category ? category.id : 0,
      formatData({ formdata, image: file, patch: true }),
    )
      .then((category) => {
        dispatch(categorySet(category));
        dispatch(getCategories(filter));
        reset();
        setMode('show');
      })
      .catch((err) => errorNames(err, setError, dispatch));
  };
  return (
    <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
      <div className="px-4 py-6 sm:px-6 ">
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-lg font-medium text-gray-900"
          >
            Изменить категорию
          </h2>
          <div className="ml-3 h-7 flex items-center">
            <button
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
              onClick={close}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      {/* Main */}
      <form onSubmit={handleSubmit(submitHandle)}>
        <div className="px-4 sm:px-6 grid grid-cols-6 gap-6 p-5 items-center">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="photo"
              className="block text-sm font-medium text-gray-700"
            >
              Фото
            </label>
            <div className="mt-1 flex items-center">
              <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                <img src={preview} alt="priview" className="h-full" />
              </span>
              <div>
                <label
                  htmlFor="upload-image"
                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Upload
                </label>
                <input
                  id="upload-image"
                  type="file"
                  className="w-0"
                  onChange={imageUpload(setPreview, setFile)}
                />
                <button
                  type="button"
                  className="ml-2 px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                  onClick={() => {
                    setPreview('');
                    category?.id &&
                      deleteImage(category?.id)
                        .then(() => setMessage(dispatch, 'Изображение удалено'))
                        .catch((err) =>
                          setMessage(
                            dispatch,
                            err.response?.data?.result?.message || err,
                          ),
                        );
                  }}
                >
                  Удалить
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3 mt-5">
            <div className="min-w-0 flex gap-1 text-sm">
              <div className="ml-3 flex items-center h-5">
                {!!category && (
                  <input
                    type="checkbox"
                    {...register(`auto_notification`)}
                    defaultChecked={category.auto_notification}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                )}
              </div>
              <label
                htmlFor={`person`}
                className="font-medium text-gray-700 select-none"
              >
                Авто уведомление
              </label>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          {!!category && (
            <>
              <input
                placeholder="Ссылка"
                defaultValue={category.slug}
                {...register('slug')}
                className={`${
                  errors.slug ? 'border-red-300' : 'border-gray-300'
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p className="mt-2 text-red-600 text-sm">
                {errors.slug && errors.slug.message}
              </p>
            </>
          )}
        </div>
        <div className="px-4 sm:px-6">
          {!!category && (
            <>
              <input
                placeholder="Позиция"
                type="number"
                defaultValue={category.position}
                {...register('position', {})}
                className={`${
                  errors.slug ? 'border-red-300' : 'border-gray-300'
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p className="mt-2 text-red-600 text-sm">
                {errors.position && errors.position.message}
              </p>
            </>
          )}
        </div>
        <div className="px-4 sm:px-6 pb-4 sm:pb-6">
          {(errors.name?.ru && <Alert message={errors.name?.ru.message} />) ||
            (errors.name?.uz && <Alert message={errors.name?.uz.message} />)}
          <Tab setRu={setRu} ru={ru} />
        </div>
        <div className={`${ru ? '' : 'hidden'}`}>
          <div className="px-4 sm:px-6 pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Название:
              </label>
              {!!category && (
                <>
                  <input
                    defaultValue={category.name?.ru}
                    {...register('name.ru', {
                      required: 'Поле не должно быть пустым',
                    })}
                    className={`${
                      errors.name?.ru ? 'border-red-300' : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.name?.ru && errors.name?.ru.message}
                  </p>
                </>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="meta_title"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Title:
              </label>
              {!!category && (
                <>
                  <input
                    defaultValue={category.meta_title?.ru}
                    {...register('meta_title.ru', {})}
                    className={`${
                      errors.meta_title?.ru
                        ? 'border-red-300'
                        : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.meta_title?.ru && errors.meta_title?.ru.message}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Описание:
              </label>
              {!!category && (
                <>
                  <textarea
                    defaultValue={category.description?.ru}
                    {...register('description.ru', {})}
                    className={`${
                      errors.description?.ru
                        ? 'border-red-300'
                        : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.description?.ru && errors.description?.ru.message}
                  </p>
                </>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="meta_description"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Description:
              </label>
              {!!category && (
                <>
                  <textarea
                    defaultValue={category.meta_description?.ru}
                    {...register('meta_description.ru', {})}
                    className={`${
                      errors.meta_description?.ru
                        ? 'border-red-300'
                        : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.meta_description?.ru &&
                      errors.meta_description?.ru.message}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`${ru ? 'hidden' : ''}`}>
          <div className="pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Название(uz):
              </label>
              {!!category && (
                <>
                  <input
                    defaultValue={category.name?.uz}
                    {...register('name.uz', {
                      required: 'Поле не должно быть пустым',
                    })}
                    className={`${
                      errors.name?.uz ? 'border-red-300' : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.name?.uz && errors.name?.uz.message}
                  </p>
                </>
              )}
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="meta_title"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Title(uz):
              </label>
              {!!category && (
                <>
                  <input
                    defaultValue={category.meta_title?.uz}
                    {...register('meta_title.uz', {})}
                    className={`${
                      errors.meta_title?.uz
                        ? 'border-red-300'
                        : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.meta_title?.uz && errors.meta_title?.uz.message}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="pb-4 sm:pb-6 grid grid-cols-6 gap-6 px-5">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Описание(uz):
              </label>
              {!!category && (
                <>
                  <textarea
                    defaultValue={category.description?.uz}
                    {...register('description.uz', {})}
                    className={`${
                      errors.description?.uz
                        ? 'border-red-300'
                        : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.description?.uz && errors.description?.uz.message}
                  </p>
                </>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="meta_description"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Description(uz):
              </label>
              {!!category && (
                <>
                  <textarea
                    defaultValue={category.meta_description?.uz}
                    {...register('meta_description.uz', {})}
                    className={`${
                      errors.meta_description?.uz
                        ? 'border-red-300'
                        : 'border-gray-300'
                    } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className="mt-2 text-red-600 text-sm">
                    {errors.meta_description?.uz &&
                      errors.meta_description?.uz.message}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex border px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="button"
            className="mr-auto transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setOpen(false);
              confirmModal(true);
            }}
          >
            Удалить
          </button>
          <button
            type="button"
            className="transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setMode('show')}
          >
            Отменить
          </button>
          <button
            type="submit"
            className="transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Изменить
          </button>
        </div>
      </form>
    </div>
  );
};
