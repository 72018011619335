import React from 'react'

const tabs = [
  { name: "Десктоп", value: false},
  { name: "Мобильный", value: true},
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
interface Props {
  setIsMobile: (expansion: boolean) => void;
  isMobile: boolean;
}

export const MobTag: React.FC<Props> = ({setIsMobile, isMobile}) => {
  return (
      <div className="hidden sm:block my-2">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <p
                key={tab.name}
                className={classNames(
                  tab.value === isMobile
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'w-24 py-2 px-1 text-center border-b-2 font-medium text-sm cursor-pointer'
                )}
                onClick={() => setIsMobile(tab.value)}
                aria-current={tab.value === isMobile ? 'page' : undefined}
              >
                {tab.name}
              </p>
            ))}
          </nav>
        </div>
      </div>
  )
}