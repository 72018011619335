export const imageUpload = (setPreview: (preview: any) => void, setFile: (file: object) => void) => (e: any) => {
  const file = e.target.files[0];
  setFile(file);

  const reader = new FileReader();

  reader.onload = () => {
    setPreview(reader.result);
  };
  reader.readAsDataURL(file);
};


export const setterPreview = (post: any) => {
  localStorage.setItem('previewpage', JSON.stringify(post))
}
export const removePreview = () => {
  localStorage.removeItem('previewpage')
}