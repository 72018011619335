import React, { useContext, useState } from 'react';
import { Checkbox } from '@components/shared/checkbox';
import { useForm } from 'react-hook-form';
import { createRole } from '@services/roles.service';
import { useDispatch } from 'react-redux';
import { getRoles } from '@store/roles/roles.thunks';
import { XIcon } from '@heroicons/react/solid';
import { RoleContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';

interface Props {
  setOpen: (open: boolean) => void;
}

export const CreateRole: React.FC<Props> = ({ setOpen }) => {
  const dispatch = useDispatch();
  const filter = useContext(RoleContext)
  const [exist, setExist] = useState('');
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm();
  const close = () => {
    setOpen(false);
  };
  const submitHandle = async (data: any) => {
    const permissions = Object.keys(data.permissions).filter(per => data.permissions[per] === true).map(per => +per);
    await createRole({ ...data, permissions })
      .then(() => {
        setExist('');
        close();
        dispatch(getRoles(filter));
        reset();
      })
      .catch(err => {
        if (err.response) {
          Object.keys(err.response.data.errors).forEach(key => {
            setError(key, {
              type: 'required',
              message: err.response.data.errors[key][0],
            });
          });
          return;
        }
        setMessage(dispatch, err.response?.data?.result?.message || err)
      });
  };
  return (
    <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
      <div className='px-4 py-6 sm:px-6 '>
        <div className='flex items-start justify-between'>
          <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
            Создать роль
          </h2>
          <div className='ml-3 h-7 flex items-center'>
            <button
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
              onClick={close}
            >
              <span className='sr-only'>Close panel</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
      <form
        className='w-12/12 h-full'
        onSubmit={handleSubmit(submitHandle)}
      >
        <div className='shadow overflow-hidden sm:rounded-md dropdown-box flex h-full flex-col justify-between'>
          <div className='grid grid-cols-6 gap-6 p-5'>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Название:
              </label>
              <input
                type='text'
                {...register('name')}
                className={`${errors.name ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p className='mt-2 text-red-600 text-sm'>{(errors.name && errors.name.message) || exist}</p>
            </div>
            <div className='col-span-6 sm:col-span-3'/>
            <div className='col-span-12 sm:col-span-6'>
              <Checkbox
                register={register}
              />
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            <button
              type='button'
              className='transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={close}
            >
              Отмена
            </button>
            <button
              type='submit'
              className='transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Создать
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};