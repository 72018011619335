import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { State } from '@store/index';

interface Props {
  register: UseFormRegister<any>;
  per?: number[];
}

export const Checkbox: React.FC<Props> = ({ register, per }) => {
  const permissions = useSelector((state: State) => state.permissions.permissions) || ['edit', 'creat', 'write', 'delete'];
  return (
    <div className='flex justify-start flex-col relative filter-bar'>
      {
        !!permissions && !!permissions.length && <div className='grid grid-cols-2 gap-2'>
          {
            permissions.map((permission, i) => (
              <div key={permission.id + i}
                   className='col-span-1 sm:col-span-1 flex justify-start text-start items-start'>
                <div className='min-w-0 flex gap-1 text-sm'>
                  <div className='ml-3 flex items-center h-5'>
                    <input
                      type='checkbox'
                      {
                        ...register(`permissions.${permission.id}`)
                      }
                      defaultChecked={!!(per && per.includes(permission.id))}
                      id={`person-${permission.id}`}
                      className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                    />
                  </div>
                  <label htmlFor={`person-${permission.id}`} className='font-medium text-gray-700 select-none'>
                    {permission.name}
                  </label>
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
};
