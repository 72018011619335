import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITag } from '@interfaces/index';

interface ITags {
  tags: ITag[];
  pagination: number;
}

interface IState {
  ru?: ITags,
  uz?: ITags ;
  allTags: { ru?: ITags, uz?: ITags };
  tag: ITag | null;
}

const initialState: IState = {
  ru: undefined,
  uz: undefined,
  allTags: {},
  tag: null,
};

export const { actions: tagsAction, reducer: tagsReducer } = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setAllTags: (state, action: PayloadAction<{ allTags: { ru?: ITags, uz?: ITags } }>) => ({
      ...state,
      allTags: action.payload.allTags,
    }),
    setTags: (state, action: PayloadAction<{ ru?: ITags, uz?: ITags }>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setRuTags: (state, action: PayloadAction<{ ru?: ITags }>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setUzTags: (state, action: PayloadAction<{ uz?: ITags }>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setTag: (state, action: PayloadAction<{ tag: ITag | null }>) => ({
      ...state,
      tag: action.payload.tag,
    }),
  },
});