import { rolesAction } from './roles.slices';
import { getAllRoles, getRoleService, getRolesService } from '@services/roles.service';
import { setMessage } from '@services/dispatch.service';

export const roleSet = (role: any) => async (dispatch: any) => {
  dispatch(rolesAction.setRole({ role }));
}
export const rolesSet = (res: any) => async (dispatch: any) => {
  dispatch(rolesAction.setRoles({
    roles: res.roles.data,
    pagination: res.roles.total,
  }));
}

export const getRoles = (params: any) => async (dispatch: any) => {
  return getRolesService(params)
    .then(res => dispatch(rolesSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};

export const getRole = (id: string) => async (dispatch: any) => {
  return getRoleService(id)
    .then((res) => dispatch(roleSet(res.role)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};

export const fetchAllRoles = () => async (dispatch: any) => {
  return getAllRoles()
    .then(res => {
      dispatch(rolesAction.setAllRoles({ allRoles: res.roles }));
    })
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};