import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PaginationComponent } from '@components/shared/pagination/pagination';
import { FilterBar } from '@components/shared/filter-bar';
import { CategoriesTable } from '@components/categories/categories-table';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '@store/categories/categories.thunks';
import { State } from '@store/index';
import { SlideOvers } from '@components/shared/slideovers';
import { CreateCategory } from '@pages/categories/create';
import { IFilters } from '@interfaces/index';
import { categoryField } from '@_data/filter-fields';
import { CategoryContext } from '@helpers/context';
import { Permission } from '@helpers/permissions';
import { sozdanieStati } from '@_data/permissions';

const limit = 10;

export const CategoriesPage: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useRef<any>()
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);

  const categoriesGet = useCallback(() => {
    dispatch(getCategories({ page: activePage, limit, ...filter.current }))
  }, [dispatch, activePage]);
  const applyFilter = (data: IFilters) => {
    filter.current = {name: data.name, with_trashed: data.with_trashed}
    setActivePage(1);
    categoriesGet()
  };
  const resetFilter = () => {
    setActivePage(1)
    filter.current = {}
    categoriesGet()
  }
  const changePage = (i: number) => setActivePage(i);

  useEffect(() => {
    categoriesGet();
  }, [activePage, dispatch, categoriesGet]);
  const pagination = useSelector((state: State) => state.categories.pagination);

  return (
    <CategoryContext.Provider value={{
      ...filter.current,
      page: activePage
    }}>
      <h3 className='text-2xl font-semibold'>Категории</h3>
      <div className='flex justify-between items-start'>
        <div className='w-2/12'>
          <FilterBar fields={categoryField} handleFilter={applyFilter} handleReset={resetFilter} />
        </div>
        <div>
          <Permission action={sozdanieStati}>
            <button
              type='button'
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
              className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Создать категорию
            </button>
          </Permission>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <CategoriesTable />
            </div>
          </div>
        </div>
      </div>
      {
        !!pagination && <PaginationComponent
          activePage={activePage}
          changePage={changePage}
          itemsCount={pagination}
        />
      }
      {
        <SlideOvers
          Create={CreateCategory}
          show={show}
          setShow={setShow}
          mode={'create'}
        />
      }
    </CategoryContext.Provider>
  );
};