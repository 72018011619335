import React from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

interface IValidation {
  email: string;
  password: string;
}
interface Props {
  handleSubmitForm: (data: IValidation) => void
}
const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LoginForm: React.FC<Props> = ({handleSubmitForm}) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const fields = [
    {
      field: 'email',
      type: 'text',
      label: 'Email',
      required: 'Email не должен быть пустым',
      pattern: {
        value: emailValidation,
        message: 'Email должен соответствовать стандартному правилу создания',
      },
    },
    {
      field: 'password',
      type: 'password',
      label: 'Password',
      required: 'Пароль не должен быть пустым',
      minLength: {
        value: 6,
        message: 'Пароль не должен быть меньше 8 символов',
      },
    },
  ];


  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} className='space-y-6 mt-9'>
      {fields.map((field, index) => {
        return (
          <div key={`${field}-${index}`} className={classNames({
            'space-y-1': index !== 0,
          })}>
            <div
              className={`${errors[field.field] ? 'border-red-300' : 'border-gray-300'} relative border rounded-md px-3 py-4 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600`}
            >
              <label
                htmlFor={field.field}
                className='absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900'
              >
                {field.label}
              </label>
              <input
                id={field.field}
                type={field.type}
                defaultValue=''
                {...register(field.field,
                  {
                    required: field.required,
                    minLength: field.minLength,
                    pattern: field.pattern,
                  },
                )
                }
                className='block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm'
                placeholder={field.label}
              />
            </div>
            <p className='mt-2 text-red-600 text-sm'>{errors[field.field] && errors[field.field].message}</p>
          </div>
        );

      })}

      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <input
            type='checkbox'
            {...register('remember_me')}
            className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
          />
          <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
            Remember me
          </label>
        </div>
      </div>

      <div>
        <button
          type='submit'
          className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          Sign in
        </button>
      </div>
    </form>
  );
};