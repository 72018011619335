import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PaginationComponent } from '@components/shared/pagination/pagination';
import { FilterBar } from '@components/shared/filter-bar';
import { IFilters } from '@interfaces/index';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { SlideOvers } from '@components/shared/slideovers';
import { bannedWordFields } from '@_data/filter-fields';
import { BannedWordContext } from '@helpers/context';
import { Permission } from '@helpers/permissions';
import { sozdanieRoli } from '@_data/permissions';
import { getBannedWords } from '@store/bannedWords/bannedWords.thunks';
import { BannedWordsTable } from '@components/bannedWords/bannedWords-table';
import { CreateBannedWord } from '@pages/bannedWords/create';

const limit = 10;

export const BannedWords: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useRef<any>();
  const pagination = useSelector((state: State) => state.bannedWords.pagination);

  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);

  const bannedWordsGet = useCallback(() => {
    dispatch(getBannedWords({ page: activePage, limit, ...filter.current }));
  }, [dispatch, activePage]);
  const applyFilter = (data: IFilters) => {
    filter.current = { ...data };
    setActivePage(1);
    bannedWordsGet();
  };
  const resetFilter = () => {
    filter.current = {};
    setActivePage(1);
    bannedWordsGet();
  };
  const changePage = (i: number) => setActivePage(i);

  useEffect(() => {
    bannedWordsGet();
  }, [activePage, dispatch, bannedWordsGet]);


  return (
    <BannedWordContext.Provider value={{
      ...filter.current,
      page: activePage
    }}>
      <h3 className='text-2xl font-semibold'>Запрещенные слова</h3>
      <div className='flex justify-between items-start'>
        <div className='w-2/12'>
          <FilterBar fields={bannedWordFields} handleFilter={applyFilter} handleReset={resetFilter} />
        </div>
        <Permission action={sozdanieRoli}>
          <div>
            <button
              type='button'
              className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
            >
              Создать запрещенное слово
            </button>
          </div>
        </Permission>
      </div>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <BannedWordsTable />
            </div>
          </div>
        </div>
      </div>
      {
        !!pagination && <PaginationComponent
          activePage={activePage}
          changePage={changePage}
          itemsCount={pagination}
        />
      }
      <SlideOvers
        Create={CreateBannedWord}
        show={show}
        setShow={setShow}
        mode='create'
      />
    </BannedWordContext.Provider>
  );
};