import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatistic } from '@interfaces/index';

interface IState {
  statistics: IStatistic[]
}

const initialState: IState = {
  statistics: []
}

export const {actions: statisticsAction, reducer: statisticsReducer} = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics: (state, action: PayloadAction<{statistics: IStatistic[]}>) => ({
      ...state,
      statistics: action.payload.statistics
    })
  }
})