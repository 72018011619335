import React, { useEffect, useRef, useState } from 'react';
import { Dropzone } from '@components/shared/dropzone';
import EditorJs from 'react-editor-js';
import {EDITOR_JS_TOOLS, logLevel} from '@pages/posts/EDITOR_JS_TOOLS';
import { useForm } from 'react-hook-form';
import { createPost } from '@services/posts.service';
import { formatData } from '@helpers/format-data';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@components/shared/select';
import { State } from '@store/index';
import { getAllCategories } from '@store/categories/categories.thunks';
import { Breadcrumbs } from '@components/posts/UI/breadcrumbs';
import { PostCreateButtons } from '@components/posts/UI/buttons';
import { Alert } from '@components/posts/UI/alert';
import { setterPreview } from '@helpers/preview';
import { useHistory } from 'react-router';
import { getTagsService } from '@services/tags.service';
import { SearchSelect } from '@components/shared/searchselect';
import { forPost } from '@_data/helpers';
import { Sidebar } from '@components/posts/UI/sidebar';
import { moderaciyaStati } from '@_data/permissions';
import { Permission } from '@helpers/permissions';
import { Spinner } from '@components/shared/spinner';
import { getAuthors } from '@store/users/users.thunks';
import { setNotifications } from '@store/notifications/notifications.thunks';
import { Settings } from '@components/posts/settings';
import { handleReady } from '@helpers/helpers';

const tools: any = EDITOR_JS_TOOLS;
const log: any = logLevel

export const CreatePost: React.FC = () => {
  const editorRef = useRef<any>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
    getValues,
    clearErrors,
    control,
  } = useForm();

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAuthors());
  }, [dispatch]);

  const categories = useSelector((state: State) => state.categories.allCategories);
  const authors = useSelector((state: State) => state.users.authors);
  const publicate = useRef(false);
  const [files, setFiles] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [selected, setSelected] = useState<any>([]);
  const setPublicate = (pub: boolean) => publicate.current = pub;
  const handleChange = (newData: any) => {
    console.log(newData)
    setData(newData);
  };

  useEffect(() => {
    clearErrors('image')
  }, [files]);

  const submitHandle = async (formdata: any) => {
    await createPost(formatData({
      formdata: {
        ...formdata,
        category: formdata.category?.id,
        author_id: formdata.author_id?.id,
        tags_id: selected,
        active: publicate.current,
      }, image: files[0], data,
    }))
        .then(post => {
          reset();
          history.push(`/posts/edit/${+post.id}`);
        })
        .catch(err => {
          if (err.response?.data?.errors) {
            return Object.keys(err.response.data.errors).forEach(key => {
              setError(key, {
                type: 'required',
                message: err.response.data.errors[key][0],
              });
            });
          }
          dispatch(setNotifications({ notification: true, message: 'Что то пошло не так' }));
        });
  };
  const previewpage = () => setterPreview({
    title: getValues('title'),
    meta_description: getValues('meta_description'),
    description: data,
    image: files[0]?.preview,
    category: getValues('category'),
  });

  const undoRedo = (undo: boolean = false) => () => {
    const keydownCTRL = new KeyboardEvent('keydown', {
      bubbles: true,
      key: undo ? 'z' : 'y',
      ctrlKey: true,
    });
    document.querySelector(`#${editorRef.current?.holder}`)?.dispatchEvent(keydownCTRL);
  };

  return (
      <form
          onSubmit={handleSubmit(submitHandle)}
          className='post_container relative lg:pl-20 px-2 sm:pt-1'
      >
        <div className='w-full py-2 px-5 bg-white mb-5 flex justify-between'>
          <Breadcrumbs />
          {
            isSubmitting
                ? <Spinner className='text-right' />
                : <PostCreateButtons isSubmiting={isSubmitting} setPublicate={setPublicate} previewpage={previewpage} />
          }
        </div>
        <div className='lg:grid lg:grid-cols-12 lg:gap-x-5'>
          <Sidebar>
            {
                !!categories &&
                <>
                  <Select
                      selected={undefined}
                      items={categories}
                      title='Категория'
                      control={control}
                      name='category'
                      error={errors['category']}
                  />
                  <Permission action={moderaciyaStati}>
                    <Select
                        items={authors}
                        title='Автор'
                        selected={undefined}
                        name='author_id'
                        control={control}
                        error={errors['author_id']}
                    />
                  </Permission>
                </>
            }
          </Sidebar>
          <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
            {/*Main*/}
            <div id='post'>
              <div className='shadow sm:rounded-md'>
                <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
                  <div>
                    <h3 className='text-lg leading-6 font-medium text-gray-900'>Создание статьи</h3>
                  </div>
                  <div>
                    <Dropzone files={files} setFiles={setFiles} title={forPost} />
                    <p className='mt-2 text-red-600 text-sm'>{errors.caption && errors.caption.message}</p>
                  </div>
                  <div
                      className='w-full'
                  >
                    <input
                        type='text'
                        placeholder='Caption'
                        {...register('caption')}
                        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    />
                    <p className='mt-2 text-red-600 text-sm'>{errors.caption && errors.caption.message}</p>
                  </div>
                  <div className='relative flex items-center justify-end'>
                    <div className='min-w-0 text-sm'>
                      <label htmlFor='comments' className='font-medium text-gray-700'>
                        Водяной знак
                      </label>
                    </div>
                    <div className='ml-3 h-5'>
                      <input
                          {...register('watermark')}
                          type='checkbox'
                          className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                    </div>
                  </div>
                  <div>
                    {
                      (errors.title && <Alert message={errors.title.message} />)
                    }
                  </div>
                  <div>
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Язык
                      </label>
                      <select
                          defaultValue='ru'
                          {...register('locale')}
                          className='mt-1 block border w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                      >
                        <option value='ru'>Русский</option>
                        <option value='uz'>Узбекский</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <div
                        className='w-full'
                    >
                    <textarea
                        placeholder='Заголовок статьи'
                        {...register('title')}
                        className={`post_title mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    />
                      <p className='mt-2 text-red-600 text-sm'>{errors.title && errors.title.message}</p>
                    </div>
                    <div
                        className='w-full'
                    >
                      <textarea
                          placeholder='URL'
                          {...register('slug')}
                          className={`post_title mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      />
                      <p className='mt-2 text-red-600 text-sm'>{errors.slug && errors.slug.message}</p>
                    </div>
                    <div
                        className='w-full'
                    >
                      <textarea
                          placeholder='Короткое содержание'
                          {...register('meta_description')}
                          className={`meta_description mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      />
                      <p
                          className='mt-2 text-red-600 text-sm'>{errors.meta_description && errors.meta_description.message}</p>
                    </div>
                    <div
                        className={'mb-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md'}>
                      <EditorJs
                          logLevel={log}
                          ref={editorRef}
                          tools={tools}
                          onReady={handleReady}
                          onChange={(api, newData) => handleChange(newData)}
                          data={data}
                      />
                      <div className='rounded-md p-2 flex justify-end gap-2'>
                        <img
                            src='/undo.png'
                            alt=''
                            className='cursor-pointer h-4 w-4'
                            style={{ transform: 'scale3d(1.5, 1.5, 1.5)' }}
                            onClick={undoRedo(true)}
                        />
                        <img
                            src='/undo.png'
                            alt=''
                            className='cursor-pointer h-4 w-4'
                            style={{ transform: 'scale3d(-1.5, 1.5, -1.5)' }}
                            onClick={undoRedo()}
                        />
                      </div>
                      <p className='mt-2 text-red-600 text-sm'>{(errors.description && errors.description?.message)}</p>
                    </div>
                    <div className='mb-2'>
                      <SearchSelect
                          isMulti={true}
                          ru={true}
                          setSelected={setSelected}
                          title='Тэги'
                          getItems={(name: string) => getTagsService({ name })}
                          isTag={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end'>
                  {
                    isSubmitting
                        ? <Spinner className='text-right' />
                        : <PostCreateButtons
                            isSubmiting={isSubmitting}
                            setPublicate={setPublicate}
                            previewpage={previewpage} />
                  }
                </div>
              </div>
            </div>
            <Permission action={moderaciyaStati}>
              <Settings register={register} post={null} />
              <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end'>
                {
                  isSubmitting
                      ? <Spinner className='text-right' />
                      :
                      <PostCreateButtons isSubmiting={isSubmitting} setPublicate={setPublicate} previewpage={previewpage} />
                }
              </div>
            </Permission>
          </div>
        </div>
      </form>
  );
};