import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { SlideOvers } from '@components/shared/slideovers';
import { PencilIcon, XCircleIcon, RefreshIcon, EyeIcon } from '@heroicons/react/solid';
import { formatDate } from '@helpers/format-date';
import { setMessage } from '@services/dispatch.service';
import { TagPage } from '@pages/tags/tag';
import { EditTag } from '@pages/tags/edit';
import { deleteTag, refreshTag } from '@services/tags.service';
import { getTags, tagSet } from '@store/tags/tags.thunks';
import { TagContext } from '@helpers/context';
import { Permission } from '@helpers/permissions';
import { obnovlenieTega, udalenieTega, vosstanovlenieTega } from '@_data/permissions';

interface Props {
  loc: 'ru' | 'uz';
}

export const TagsTbody: React.FC<Props> = ({ loc }) => {
  const dispatch = useDispatch();
  const filter = useContext(TagContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('show');
  const tag = useSelector((state: State) => state.tags.tag);
  const tags = useSelector((state: State) => state.tags);

  const deleteHandler = (id: number) => {
    deleteTag(id)
      .then(() => {
        dispatch(tagSet(null));
        dispatch(getTags(filter));
        setOpen(false);
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const refresh = async (id: number) => {
    await refreshTag(id)
      .then((dsd) => {
        console.log(dsd);
        dispatch(getTags(filter));
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
    {!!tags[loc] && tags[loc]!.tags?.map((tag) => (
      <tr
        key={tag.id}
        className='cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setMode('show');
          dispatch(tagSet(tag));
        }}
      >
        <td
          className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'><p
          className='overflow-hidden overflow-ellipsis w-full' style={{ maxWidth: '600px' }}>{tag.name}</p></td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center'>
          <EyeIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
          <p className='overflow-hidden overflow-ellipsis w-40'>{tag.views_count}</p>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{formatDate(tag.created_at)}</td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
          {
            tag?.deleted_at
              ? (
                <Permission action={vosstanovlenieTega}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      refresh(tag.id);
                    }}
                  >
                    <RefreshIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                  </button>
                </Permission>
              )
              : <>
                <Permission action={obnovlenieTega}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(tagSet(tag));
                      setMode('edit');
                      setShow(true);
                    }}
                  >
                    <PencilIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                  </button>
                </Permission>
                <Permission action={udalenieTega}>
                  <button
                    className='text-red-600 hover:text-red-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(tagSet(tag));
                      setOpen(true);
                    }}
                  >
                    <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                  </button>
                </Permission>
              </>
          }
        </td>
      </tr>
    ))}
    {
      !!tag && <Permission action={udalenieTega}>
        <PopupDelete
          setMode={setMode}
          setShow={setShow}
          open={open}
          setOpen={setOpen}
          handleDelete={deleteHandler}
          data={tag}
        />
      </Permission>

    }
    <SlideOvers
      Show={TagPage}
      Edit={EditTag}
      show={show}
      mode={mode}
      confirmModal={setOpen}
      setMode={setMode}
      setShow={setShow}
    />
    </tbody>
  );
};