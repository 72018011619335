import { api } from '@api/index';

export const getCommentsService = (params: any) => {
  return api
    .get(`/dashboard/comments`, {params})
    .then((res) => res.data.result.comments)
}
export const getLastCommentsService = () => {
  return api
    .get(`/dashboard/last/comments`)
    .then((res) => res.data.result.comments)
}
export const updateComment = (id: number, data: any) => {
  return api
    .post(`/dashboard/comments/${id}`, {
      ...data,
      _method: 'PUT'
    })
    .then((res) => res.data.result.comment)
}
export const deleteComment = (id: number, deleted_reason: any) => {
  return api
    .post(`/dashboard/comments/${id}`, {
      ...deleted_reason,
      _method: 'DELETE'
    })
    .then((res) => res.data.result.comments)
}
export const refreshComment = (id: number) => {
  return api
    .post(`/dashboard/comments/${id}/restore`)
    .then((res) => res.data.result.comments)
}