export const udalenieKategorii = 'udalenie-kategorii'
export const udalenieIzobrazeniyaKategorii = 'udalenie-izobrazeniya-kategorii'
export const bezvozvratnoeUdalenieKategorii = 'bezvozvratnoe-udalenie-kategorii'
export const obnovlenieKategorii = 'obnovlenie-kategorii'
export const vosstanovlenieKategorii = 'vosstanovlenie-kategorii'
export const prosmotrKategorii = 'prosmotr-kategorii'
export const sozdanieKategorii = 'sozdanie-kategorii'

export const udalenieRoli = 'udalenie-roli'
export const udalenieIzobrazeniyaRoli = 'udalenie-izobrazeniya-roli'
export const vosstanovlenieRoli = 'vosstanovlenie-roli'
export const prosmotrRolei = 'prosmotr-vsex-rolei'
export const obnovlenieRoli = 'obnovlenie-roli'
export const prosmotrRoli = 'prosmotr-roli'
export const sozdanieRoli = 'sozdanie-roli'

export const udalenieTega = 'udalenie-tega'
export const bezvozvratnoeUdalenieTega = 'bezvozvratnoe-udalenie-tega'
export const udalenieIzobrazeniyaTega = 'udalenie-izobrazeniya-tega'
export const vosstanovlenieTega = 'vosstanovlenie-tega'
export const prosmotrTegov = 'prosmotr-vsex-tegov'
export const obnovlenieTega = 'obnovlenie-tega'
export const prosmotrTega = 'prosmotr-tega'
export const sozdanieTega = 'sozdanie-tega'

export const udalenieKommentarii = 'udalenie-kommentarii'
export const bezvozvratnoeUdalenieKommentarii = 'bezvozvratnoe-udalenie-kommentarii'
export const vosstanovlenieKommentarii = 'vosstanovlenie-kommentarii'
export const prosmotrKommentariev = 'prosmotr-vsex-kommentarii'
export const obnovlenieKommentarii = 'obnovlenie-kommentarii'
export const prosmotrKommentarii = 'prosmotr-kommentarii'
export const sozdanieKommentarii = 'sozdanie-kommentarii'

export const udalenieReklami = 'udalenie-reklam'
export const bezvozvratnoeUdalenieReklami = 'bezvozvratnoe-udalenie-reklam'
export const vosstanovlenieReklami = 'vosstanovlenie-reklam'
export const prosmotrReklam = 'prosmotr-vsex-reklam'
export const obnovlenieReklami = 'obnovlenie-reklam'
export const prosmotrReklami = 'prosmotr-reklam'
export const sozdanieReklami = 'sozdanie-reklam'

export const udaleniePolzovatelya = 'udalenie-polzovatelya'
export const vosstanovleniePolzovatelya = 'vosstanovlenie-polzovatelya'
export const bezvozvratnoeUdaleniePolzovatelya = 'bezvozvratnoe-udalenie-polzovatelya'
export const obnovleniePolzovatelya = 'obnovlenie-polzovatelya'
export const zagruzkaIzobrazeniePolzovatelya = 'zagruzka-izobrazenie-polzovatelya'
export const prosmotrPolzovatelya = 'prosmotr-polzovatelya'
export const prosmotrPolzovatelei = 'prosmotr-vsex-polzovatelei'
export const prosmotrAvtorovUPolzovatelei = 'prosmotr-avtorov-u-polzovatelei'
export const sozdaniePolzovatelya = 'sozdanie-polzovatelya'

export const udalenieNastroiki = 'udalenie-nastroiki'
export const vosstasozdanieNastroiki = 'vosstanovlenie-nastroiki'
export const bezvozvratnoesozdanieNastroiki = 'bezvozvratnoe-udalenie-nastroiki'
export const obsozdanieNastroiki = 'obnovlenie-nastroiki'
export const prosmotrNastroiki = 'prosmotr-nastroiki'
export const prosmotrNastroek = 'prosmotr-vsex-nastroek'
export const sozdanieNastroiki = 'sozdanie-nastroiki'

export const udalenieStati = 'udalenie-stati'
export const obnovlenieStati = 'obnovlenie-stati'
export const prosmotrStati = 'prosmotr-stati'
export const sozdanieStati = 'sozdanie-stati'
export const vosstanovlenieStati = 'vosstanovlenie-stati'
export const bezvozvratnoeUdalenieStati = 'bezvozvratnoe-udalenie-stati'
export const zagruzkaIzobrazenieStati = 'zagruzka-izobrazenie-stati'
export const udalenieIzobrazenieStati = 'udalenie-izobrazenie-stati'
export const polucenieDannyxSoSsylkiVStatyax = 'polucenie-dannyx-so-ssylki-v-statyax'
export const moderaciyaStati = 'moderaciya-stati'

export const udaleniePrav = 'udalenie-prav'
export const obnovleniePrav = 'obnovlenie-prav'
export const prosmotrPrav = 'prosmotr-prav'
export const sozdaniePrav = 'sozdanie-prav'