import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { getPost, postSet } from '@store/posts/posts.thunks';
import { deletePost, refreshPost } from '@services/posts.service';
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '@pages/posts/EDITOR_JS_TOOLS';
import { useParams } from 'react-router-dom';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { udalenieStati } from '@_data/permissions';
import { Permission } from '@helpers/permissions';
import { Header } from '@components/posts/UI/header';
import { PostPageButtons } from '@components/posts/UI/buttons';
import { setMessage } from '@services/dispatch.service';

const tools: any = EDITOR_JS_TOOLS

export const PostPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  useEffect(() => {
    dispatch(getPost(+params.id));
    return () => {
      dispatch(postSet(null));
    };
  }, [dispatch, params.id]);
  const post: any = useSelector((state: State) => state.posts.post);
  const [open, setOpen] = useState(false);
  const refresh = async () => {
    await refreshPost(+params.id)
      .then(post => {
        dispatch(postSet(post));
        setMessage(dispatch, 'Статья успешно опубликована');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const deleteHandler = () => {
    deletePost(+params.id)
      .then((res) => {
        setOpen(false);
        dispatch(postSet(res.post));
        setMessage(dispatch, 'Статья успешно удалена');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  return (
    <>
      <div
        className='post_container lg:pl-20 px-2 sm:pt-1'
      >
        <Header post={post} isModeration={false}>
          <PostPageButtons refresh={refresh} onDelete={setOpen} loading={false} deleted={!!post?.deleted_at} active={post?.active}/>
        </Header>
        <div className='pb-1 sm:pb-6'>
          <img
            className='h-full w-full mx-auto object-contain mt-4'
            src={post?.image}
            alt=''
          />
          <p className='text-sm font-normal mt-2 mb-4'>{post?.caption}</p>
          <p className='text-base font-normal my-4'>{post?.meta_description}</p>
          <div
            className={'mb-5 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md'}>
            {
              !!post && <EditorJs
                readOnly={true}
                tools={tools}
                data={JSON.parse(post.description || '{}')}
              />
            }
          </div>
        </div>
      </div>
      <Permission action={udalenieStati}>
        {
          !!post
            ? <PopupDelete
              open={open}
              setOpen={setOpen}
              handleDelete={deleteHandler}
              data={{ name: post.title, id: post.id }}
            />
            : null
        }
      </Permission>
    </>
  );
};