import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAds } from '@interfaces/index';

interface IState {
  adsPostLineEnd: IAds | null,
  adsPostLineStart: IAds | null,
  adsMain: IAds | null,
  adsAuthor: IAds | null,
  adsPostFeed: IAds | null,
  adsHeader: IAds | null,
}

const initialState: IState = {
  adsPostLineEnd: null,
  adsPostLineStart: null,
  adsMain: null,
  adsAuthor: null,
  adsPostFeed: null,
  adsHeader: null,
};

export const { actions: adsAction, reducer: adsReducer } = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    setAds: (state, action: PayloadAction<{ adverts: IState }>) => ({
      ...state,
      adsPostLineEnd: action.payload.adverts.adsPostLineEnd || null,
      adsPostLineStart: action.payload.adverts.adsPostLineStart || null,
      adsMain: action.payload.adverts.adsMain || null,
      adsAuthor: action.payload.adverts.adsAuthor || null,
      adsPostFeed: action.payload.adverts.adsPostFeed || null,
      adsHeader: action.payload.adverts.adsHeader || null,
    })
  },
});