import React from 'react';
import { Thead } from '@components/shared/thead';
import { BannedTableTitles } from '@_data/titles';
import { BannedWordsTbody } from '@components/bannedWords/bannedWords-tbody';

export const BannedWordsTable: React.FC = () => {
  return (
    <table className='min-w-full divide-y divide-gray-200'>
      <Thead tableTitles={BannedTableTitles}/>
      <BannedWordsTbody />
    </table>
  );
};