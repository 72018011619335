import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PaginationComponent } from '@components/shared/pagination/pagination';
import { FilterBar } from '@components/shared/filter-bar';
import { IFilters } from '@interfaces/index';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { SlideOvers } from '@components/shared/slideovers';
import { tagField } from '@_data/filter-fields';
import { TagContext } from '@helpers/context';
import { TagsTable } from '@components/tags/tags-table';
import { CreateTag } from '@pages/tags/create';
import { getTags, ruTagsSet, uzTagsSet } from '@store/tags/tags.thunks';
import { Permission } from '@helpers/permissions';
import { sozdanieTega } from '@_data/permissions';
import { getTagsService } from '@services/tags.service';
import { setMessage } from '@services/dispatch.service';

const limit = 10;

export const TagsPage: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useRef<any>();
  const { ru, uz } = useSelector((state: State) => state.tags);

  const [activePageRu, setActivePageRu] = useState(1);
  const [activePageUz, setActivePageUz] = useState(1);
  const [show, setShow] = useState(false);

  const tagsGet = useCallback(() => {
    dispatch(getTags({ page: activePageRu, limit, ...filter.current }));
  }, [dispatch, activePageRu]);
  const tagsGetRu = useCallback(() => {
    getTagsService({ page: activePageRu, limit, ...filter.current, locale: 'ru' })
      .then(({ ru }) => dispatch(ruTagsSet({tags: ru.data, pagination: ru.total})))
      .catch(() => setMessage(dispatch, 'Что то пошло не так'));

  }, [dispatch, activePageRu]);
  const applyFilter = (data: IFilters) => {
    filter.current = { ...data };
    setActivePageRu(1);
    setActivePageUz(1);
    tagsGet();
    tagsGetUz();
  };
  const resetFilter = () => {
    filter.current = {};
    setActivePageRu(1);
    setActivePageUz(1);
    tagsGet();
    tagsGetUz();
  };
  const changePage = (i: number) => setActivePageRu(i);

  const tagsGetUz = useCallback(() => {
    getTagsService({ page: activePageUz, limit, ...filter.current, locale: 'uz' })
      .then(({ uz }) => dispatch(uzTagsSet({tags: uz.data, pagination: uz.total})))
      .catch(() => setMessage(dispatch, 'Что то пошло не так'));
  }, [dispatch, activePageUz]);
  const changePageUz = (i: number) => setActivePageUz(i);

  useEffect(() => {
    tagsGetRu();
  }, [activePageRu, dispatch, tagsGetRu]);

  useEffect(() => {
    tagsGetUz();
  }, [activePageUz, dispatch, tagsGetUz]);


  return (
    <TagContext.Provider value={{
      filter: { ...filter.current },
      page: { ru: activePageRu, uz: activePageUz },
    }}>
      <h3 className='text-2xl font-semibold'>Тэги</h3>
      <div className='flex justify-between items-start'>
        <div className='w-2/12'>
          <FilterBar fields={tagField} handleFilter={applyFilter} handleReset={resetFilter} />
        </div>
        <div>
          <Permission action={sozdanieTega}>
            <button
              type='button'
              className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
            >
              Создать тэг
            </button>
          </Permission>
        </div>
      </div>
      <div className='grid grid-cols-12 gap-2'>
        <div className='flex flex-col col-span-6'>
          <div className='overflow-x-auto'>
            <div className='align-middle inline-block min-w-full'>
              <h3 className='text-2xl font-semibold mb-2'>Русские теги</h3>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <TagsTable loc='ru' />
              </div>
            </div>
          </div>
          {
            !!ru && <PaginationComponent
              activePage={activePageRu}
              changePage={changePage}
              itemsCount={ru.pagination}
            />
          }
        </div>
        <div className='flex flex-col col-span-6'>
          <div className='overflow-x-auto'>
            <div className='align-middle inline-block min-w-full'>
              <h3 className='text-2xl font-semibold mb-2'>Узбекские теги</h3>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <TagsTable loc='uz' />
              </div>
            </div>
          </div>
          {
            !!uz && <PaginationComponent
              activePage={activePageUz}
              changePage={changePageUz}
              itemsCount={uz.pagination}
            />
          }
        </div>
      </div>
      <SlideOvers
        Create={CreateTag}
        show={show}
        setShow={setShow}
        mode={'create'}
      />
    </TagContext.Provider>
  );
};