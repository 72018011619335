import React from 'react';
import { TagsTbody } from '@components/tags/tags-tbody';
import { Thead } from '@components/shared/thead';
import { TagsTableTitles } from '@_data/titles';

interface Props {
  loc: 'ru' | 'uz'
}

export const TagsTable: React.FC<Props> = ({loc}) => {
  return (
    <table className='min-w-full divide-y divide-gray-200'>
      <Thead tableTitles={TagsTableTitles}/>
      <TagsTbody loc={loc}/>
    </table>
  );
};