import React from 'react';
import { CategoriesTBody } from '@components/categories/categories-tbody';
import { Thead } from '@components/shared/thead';
import { CategoriesTableTitles } from '@_data/titles';

export const CategoriesTable: React.FC = () => {
  return (
    <table className='min-w-full divide-y divide-gray-200'>
      <Thead tableTitles={CategoriesTableTitles}/>
      <CategoriesTBody />
    </table>
  );
};