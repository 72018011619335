import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '@store/index';

interface Props {
  component: React.JSXElementConstructor<any>;
  exact?: boolean;
  path: string;
}

export const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const token = useSelector((state: State) => state.auth.token)
  return (
    <Route {...rest} render={props => {
      return token
        ? (
          <Component {...props} />
        )
        : (
          <Redirect to='/login' />
        )
    }
    }
    />
  );
};

