import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { useForm } from 'react-hook-form';
import { imageUpload } from '@helpers/preview';
import { deleteImage, updateUser } from '@services/users.service';
import { formatData } from '@helpers/format-data';
import { userSet } from '@store/auth/auth.thunks';
import { setMessage } from '@services/dispatch.service';
import { formatDate } from '@helpers/format-date';

interface Props {
  setOpen: (show: boolean) => void
}

export const Account: React.FC<Props> = ({setOpen}) => {
  const {register, handleSubmit, formState: {errors}, setError} = useForm()
  const dispatch = useDispatch()
  const user = useSelector((state: State) => state.auth.user)
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>(user?.avatar);
  const imageRemove = () => {
    deleteImage(user!.id)
      .then(() => {
        setPreview('');
        setMessage(dispatch, 'Изображение удалено');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err))
  }
  const submitForm = async (formdata: any) => {
    updateUser(user!.id, formatData({
      formdata: { ...formdata, phone: null },
      image: file,
      patch: true,
      phone: formdata.phone,
    }))
      .then(user => {
        dispatch(userSet(user));
      })
      .catch((err) => {
        if (err.response.data.errors) {
          Object.keys(err.response.data.errors).forEach((key) => {
            setError(key, {
              type: 'server-error',
              message: err.response.data.errors[key][0],
            });
          });
          return;
        }
        setMessage(dispatch, err.response?.data?.result?.message || err)
      });
  };
  return (
    <div className='flex-1 h-full max-h-screen xl:overflow-y-auto bg-white border'>
      <div className='max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8'>
        <h1 className='text-3xl font-extrabold text-blue-gray-900'>Профиль</h1>

        <form
          onSubmit={handleSubmit(submitForm)}
          className='mt-6 space-y-8 divide-y divide-y-blue-gray-200'
        >
          <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6'>
            <div className='sm:col-span-6'>
              <label htmlFor='first-name' className='block text-sm font-medium text-blue-gray-900'>
                Имя
              </label>
              <input
                type='text'
                defaultValue={user?.name}
                {...register('name')}
                autoComplete='given-name'
                className='mt-1 block border py-2 px-3 w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
              />
              <p className='text-xs text-red-600 mt-2'>{errors.name && errors.name.message}</p>
            </div>

            <div className='sm:col-span-6'>
              <label htmlFor='photo' className='block text-sm font-medium text-blue-gray-900'>
                Фото
              </label>
              <div className='mt-1 flex items-center'>
                <img
                  className='inline-block h-12 w-12 rounded-full'
                  src={preview}
                  alt=''
                />
                <div className='ml-4 flex'>
                  <div
                    className='relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500'>
                    <label
                      htmlFor='user-photo'
                      className='relative text-sm font-medium text-blue-gray-900 pointer-events-none'
                    >
                      <span>Изменить</span>
                      <span className='sr-only'> фото пользователя</span>
                    </label>
                    <input
                      id='user-photo'
                      name='user-photo'
                      type='file'
                      className='absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md'
                      onChange={imageUpload(setPreview, setFile)}
                    />
                  </div>
                  <button
                    type='button'
                    className='ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500'
                    onClick={imageRemove}
                  >
                    Удалить
                  </button>
                </div>
              </div>
            </div>

          </div>

          <div className='pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6'>
            <div className='sm:col-span-6'>
              <h2 className='text-xl font-medium text-blue-gray-900'>Личные данные</h2>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='email-address' className='block text-sm font-medium text-blue-gray-900'>
                Email
              </label>
              <input
                type='text'
                defaultValue={user!.email}
                {...register('email')}
                autoComplete='email'
                className='mt-1 block border py-2 px-3 w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
              />
              <p className='text-xs text-red-600 mt-2'>{errors.email && errors.email.message}</p>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='phone-number' className='block text-sm font-medium text-blue-gray-900'>
                Тел.
              </label>
              <input
                type='text'
                defaultValue={user!.phone}
                {...register('phone')}
                autoComplete='tel'
                className='mt-1 block border py-2 px-3 w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
              />
              <p className='text-xs text-red-600 mt-2'>{errors.phone && errors.phone.message}</p>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='country' className='block text-sm font-medium text-blue-gray-900'>
                Роль
              </label>
              <input
                type='text'
                value={user!.role!.name}
                autoComplete='country'
                className='mt-1 block border py-2 px-3 w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                readOnly
              />
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='language' className='block text-sm font-medium text-blue-gray-900'>
                Пароль
              </label>
              <input
                type='text'
                defaultValue={user!.password}
                {...register('password')}
                className='mt-1 block border py-2 px-3 w-full border-blue-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
              />
            </div>

            <p className='text-sm text-blue-gray-500 sm:col-span-6'>
              Аккаунт создан:{' '}
              <time dateTime='2017-01-05T20:35:40'>{formatDate(user!.created_at)}</time>
              .
            </p>
          </div>

          <div className='pt-8 flex justify-end'>
            <button
              onClick={() => setOpen(false)}
              type='button'
              className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              Закрыть
            </button>
            <button
              type='submit'
              className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};