import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { XIcon } from '@heroicons/react/solid';
import { TagContext } from '@helpers/context';
import { createTag, getTagsService } from '@services/tags.service';
import { setMessage } from '@services/dispatch.service';
import { ruTagsSet, uzTagsSet } from '@store/tags/tags.thunks';

interface Props {
  setOpen: (open: boolean) => void;
}

export const CreateTag: React.FC<Props> = ({ setOpen }) => {
  const dispatch = useDispatch();
  const { filter, page }: any = useContext(TagContext);
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm();
  const close = () => {
    setOpen(false);
  };
  const submitHandle = async (data: any) => {
    await createTag({ ...data })
      .then(() => {
        close();
        getTagsService({ filter, locale: data.locale, page: page[data.locale] })
          .then(res => {
            if (res.uz) dispatch(uzTagsSet({tags: res.uz.data, pagination: res.uz.total}))
            if (res.ru) dispatch(ruTagsSet({tags: res.ru.data, pagination: res.ru.total}))
          })
        reset();
      })
      .catch(err => {
        if (err.response) {
          Object.keys(err.response.data.errors).forEach(key => {
            setError(key, {
              type: 'required',
              message: err.response.data.errors[key][0],
            });
          });
          return;
        }
        setMessage(dispatch, err.response?.data?.result?.message || err);
      });
  };
  return (
    <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
      <div className='px-4 py-6 sm:px-6 '>
        <div className='flex items-start justify-between'>
          <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
            Создать тэг
          </h2>
          <div className='ml-3 h-7 flex items-center'>
            <button
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
              onClick={close}
            >
              <span className='sr-only'>Close panel</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
      <form
        className='w-12/12 h-full'
        onSubmit={handleSubmit(submitHandle)}
      >
        <div className='shadow overflow-hidden sm:rounded-md dropdown-box flex h-full flex-col justify-between'>
          <div className='grid grid-cols-6 gap-6 p-5'>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Название:
              </label>
              <input
                type='text'
                {...register('name')}
                className={`${errors.name ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p className='mt-2 text-red-600 text-sm'>{(errors.name && errors.name.message)}</p>
            </div>
            <div
              className='col-span-3 sm:col-span-1'
            >
              <label htmlFor='location' className='block text-sm font-medium text-gray-700'>
                Локализация
              </label>
              <select
                defaultValue='ru'
                {...register('locale')}
                className={`${errors.name ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full pl-3 pr-6 py-2 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
              >
                <option value='ru'>RU</option>
                <option value='uz'>UZ</option>
              </select>
              <p className='mt-2 text-red-600 text-sm'>{(errors.locale && errors.locale.message)}</p>
            </div>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Meta Title:
              </label>
              <input
                type='text'
                {...register('meta_title')}
                className={`${errors.meta_title ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p className='mt-2 text-red-600 text-sm'>{(errors.meta_title && errors.meta_title.message)}</p>
            </div>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Meta Description:
              </label>
              <input
                type='text'
                {...register('meta_description')}
                className={`${errors.meta_description ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p
                className='mt-2 text-red-600 text-sm'>{(errors.meta_description && errors.meta_description.message)}</p>
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            <button
              type='button'
              className='transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={close}
            >
              Отмена
            </button>
            <button
              type='submit'
              className='transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Создать
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};