import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@interfaces/index';

interface IState {
  users: IUser[];
  authors: IUser[];
  user: IUser | null;
  pagination: number;
}

const initialState: IState = {
  authors: [],
  users: [],
  user: null,
  pagination: 0,
};

export const { actions: usersAction, reducer: usersReducer } = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchSuccess: (state, action: PayloadAction<{ users: IUser[], pagination: number }>) => ({
      ...state,
      users: action.payload.users,
      pagination: action.payload.pagination,
    }),
    setUser: (state, action: PayloadAction<{ user: IUser | null }>) => ({
      ...state,
      user: action.payload.user,
    }),
    setAuthors: (state, action: PayloadAction<{ auhtors: IUser[] }>) => ({
      ...state,
      authors: action.payload.auhtors,
    }),
  },
});