export const nameOrtitle = (item: any, ru: boolean) => {
  let name = ''
  if (item.name instanceof Object) {
    name = item.name[ru ? 'ru' : 'uz'];
  }

  if (typeof item.name === 'string') {
    name = item.name;
  }

  if (item.title instanceof Object) {
    name = item.title[ru ? 'ru' : 'uz'];
  }

  if (typeof item.title === 'string') {
    name = item.title;
  }

  return name
}
