import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { PencilIcon, RefreshIcon, XIcon } from '@heroicons/react/solid';
import { formatDate } from '@helpers/format-date';
import { BannedWordContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import { Permission } from '@helpers/permissions';
import { obnovlenieRoli, vosstanovlenieRoli } from '@_data/permissions';
import { bannedWordSet, getBannedWords } from '@store/bannedWords/bannedWords.thunks';
import { refreshBannedWord } from '@services/bannedWords.service';

interface Props {
  setOpen: (open: boolean) => void;
  setMode: (mode: string) => void;
}

export const BannedWord: React.FC<Props> = ({ setOpen, setMode }) => {
  const dispatch = useDispatch();
  const filter = useContext(BannedWordContext);
  const close = () => {
    setOpen(false);
    dispatch(bannedWordSet(null));
  };
  const refresh = async (id: number) => {
    await refreshBannedWord(id)
      .then(bannedWord => {
        dispatch(bannedWordSet(bannedWord));
        dispatch(getBannedWords(filter));
        setMode('show');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const bannedWord = useSelector((state: State) => state.bannedWords.bannedWord);
  return (
    <>
      <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
        <div className='px-4 py-6 sm:px-6'>
          <div className='flex items-start justify-between'>
            <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
              Роль
            </h2>
            <div className='ml-3 h-7 flex items-center'>
              <button
                className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
                onClick={close}
              >
                <span className='sr-only'>Close panel</span>
                <XIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
        {/* Main */}
        <div>
          <div className='pb-1 sm:pb-6 flex justify-between items-center'>
            <div>
              <div className='px-4 sm:flex sm:items-end sm:px-6'>
                <div className='sm:flex-1'>
                  <div className='flex items-center'>
                    <h3 className='font-bold text-xl text-gray-900 sm:text-2xl'>{bannedWord?.text}</h3>
                  </div>
                  <p className='text-sm text-gray-500'>{bannedWord?.created_at}</p>
                </div>
              </div>
            </div>
            <div className='w-4/12'>
              {
                bannedWord?.deleted_at
                  ? (
                    <Permission action={vosstanovlenieRoli}>
                      <button
                        className='text-gray-600 hover:text-gray-900'
                        onClick={(e) => {
                          e.stopPropagation();
                          refresh(bannedWord.id);
                        }}
                      >
                        <RefreshIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                      </button>
                    </Permission>
                  )
                  : (
                    <Permission action={obnovlenieRoli}>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-2 text-gray-400 border border-gray-400 shadow-sm text-sm leading-4 font-medium rounded-3xl hover:text-white bg-transparent hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={() => setMode('edit')}
                      >
                        <PencilIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                        Изменить
                      </button>
                    </Permission>
                  )
              }

            </div>
          </div>
          <div>
            <dl>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>Текс</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>{bannedWord?.text}</dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>Дата создания</dt>
                <dd
                  className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>{formatDate(bannedWord?.created_at || '')}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};