import React from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  confirmModal?: (open: boolean) => void;
  show: boolean;
  setShow: (open: boolean) => void;
  Show?: React.JSXElementConstructor<any>;
  Edit?: React.JSXElementConstructor<any>;
  Create?: React.JSXElementConstructor<any>;
  mode: string;
  setMode?: (mode: string) => void;
}

export const SlideOvers: React.FC<Props> = ({ confirmModal, show, setShow, Show, Edit, mode, setMode, Create }) => {
  const close = () => {
    setShow(false);
  };
  const Component = () => {
    if (Edit && mode === 'edit') return <Edit confirmModal={confirmModal} setOpen={setShow} setMode={setMode} />;
    if (Create && mode === 'create') return <Create setOpen={setShow} />;
    return (Show && <Show setOpen={setShow} setMode={setMode} />) || null;
  };
  return (
    <Transition.Root show={show} as={React.Fragment}>
      <Dialog as='div' static className='inset-0 overflow-hidden' open={show} onClose={close}>
        <div className='inset-0 overflow-hidden'>
          <div className='fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16'>
            <Transition.Child
              as={React.Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='w-screen max-w-2xl'>
                {
                  !!Component && <Component />
                }
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};