import axios from 'axios';
import history from '../history'
const baseURL = 'https://api.uznews.uz';

export const api = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': 'ru'
  }
})


api.interceptors.request.use(function (config) {
  // Проверим в самом начале, есть ли токен в хранилище
  const JWTToken = localStorage.getItem('token');
  // Если токен есть, то добавим заголовок к запросам
  if (history.location.pathname !== '/login') config.headers.Authorization =  JWTToken ? `Bearer ${JWTToken}` : '';
  return config;
});

api.interceptors.response.use((response) => {
  return response
}, async function (error) {
  if (error.response?.status === 401 && history.location.pathname !== '/login') {
    history.push('/login', {from: history.location.pathname})
    return
  }
  return Promise.reject(error)
});

