import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './dropzone.scss';
import { XCircleIcon } from '@heroicons/react/outline';
import { deleteImage } from '@services/posts.service';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { setMessage } from '@services/dispatch.service';

interface Props {
  files: any;
  setFiles: (file: any[]) => void;
  title: string
}

export const Dropzone: React.FC<Props> = ({ files, setFiles, title }) => {
  const dispatch = useDispatch()
  const post = useSelector((state: State) => state.posts.post)
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
  });

  const thumbs = files.map((file: any, i: any) => (
    <div className='w-full' key={file.name + i}>
      <XCircleIcon
        className='absolute h-6 w-6 right-2 top-2 text-red-600 cursor-pointer'
        aria-hidden='true'
        onClick={() => {
          post && deleteImage(post.id).then(() => setMessage(dispatch, 'Изображение успешно удалено')).catch(() => setMessage(dispatch, 'Изображение не удалено'))
          setFiles([]);
        }}
      />
      <div className='thumb' key={file.name}>
        <div className='thumbInner'>
          <img
            src={file.preview}
            alt=''
            className='img'
          />
        </div>
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className='col-span-3'>
      <label className='block text-sm font-medium text-gray-700'>{title}</label>
      <aside className='thumbsContainer'>
        {thumbs}
      </aside>
      <div
        className='mt-1 border-2 border-gray-300 relative border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center'>
        <div className='space-y-1 text-center'>
          <svg
            className='mx-auto h-12 w-12 text-gray-400'
            stroke='currentColor'
            fill='none'
            viewBox='0 0 48 48'
            aria-hidden='true'
          >
            <path
              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p className='text-xs text-gray-500'>PNG, JPG, GIF до 10MB</p>
          <div className='flex text-sm text-gray-600'>
            <section className='container' style={{ cursor: 'pointer' }}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p className='w-full h-full flex items-end justify-center absolute top-0 left-0 '><span>Перетащите сюда файл или щелкните, чтобы выбрать</span></p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};