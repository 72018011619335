import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Sidebar } from '@components/sidebar';
import { HomePage } from '@pages/home';
import { RolesPage } from '@pages/roles';
import { UsersPage } from '@pages/users';
import { CategoriesPage } from '@pages/categories';
import { SettingsPage } from '@pages/settings';

import { PostsPage } from '@pages/posts';
import { PostPage } from '@pages/posts/post';
import { ModeratePost } from '@pages/posts/moderation';
import { CreatePost } from '@pages/posts/create';
import { EditPost } from '@pages/posts/edit';

// import { EditPost as DemoEditPost} from '@pages/postdemo/edit';

import { PreviewPage } from '@pages/posts/preview';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionCheck } from '@helpers/permissions';
import {
  moderaciyaStati,
  obnovlenieStati,
  prosmotrKategorii,
  prosmotrKommentariev, prosmotrNastroek,
  prosmotrPolzovatelei,
  prosmotrRolei,
  prosmotrStati,
  prosmotrTegov,
  sozdanieStati,
} from '@_data/permissions';
import { State } from '@store/index';
import { Header } from '@components/header';

import { getUser } from '@store/auth/auth.thunks';
import { CommentsPage } from '@pages/comments';
import { TagsPage } from '@pages/tags';
import { BannedWords } from '@pages/bannedWords';

const Layout: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  const user = useSelector((state: State) => state.auth.user);
  return (
    <div>
      {
        user
          ? <>
            <Header />
            <div className='h-screen flex overflow-hidden bg-gray-100'>
              <Sidebar />
              <main className='flex-1 p-4 relative z-0 focus:outline-none overflow-y-scroll overflow-x-hidden'>
                <Route path='/' component={HomePage} exact />
                <PermissionCheck path='/roles' component={RolesPage} operation={prosmotrRolei} route={true} exact />
                <PermissionCheck path='/bannedWords' component={BannedWords} operation={prosmotrRolei} route={true} exact />
                <PermissionCheck path='/tags' component={TagsPage} operation={prosmotrTegov} route={true} exact />
                <PermissionCheck path='/comments' component={CommentsPage} operation={prosmotrKommentariev} route={true} exact />
                <Route path='/posts' component={PostsPage} exact />
                <PermissionCheck path='/posts/create' component={CreatePost} operation={sozdanieStati} route={true} exact />
                <PermissionCheck path='/posts/show/:id' component={PostPage} operation={prosmotrStati} route={true} exact />
                <PermissionCheck path='/posts/moderate/:id' component={ModeratePost} operation={moderaciyaStati} route={true} exact />
                <PermissionCheck path='/posts/preview' component={PreviewPage} operation={prosmotrStati} route={true} exact />
                <PermissionCheck path='/posts/edit/:id' component={EditPost} operation={obnovlenieStati} route={true} exact />
                {/**/}
                {/*<PermissionCheck path='/postsdemo/edit/:id' component={DemoEditPost} operation={obnovlenieStati} route={true} exact />*/}
                {/**/}
                <PermissionCheck path='/categories' component={CategoriesPage} operation={prosmotrKategorii} route={true} exact />
                <PermissionCheck path='/users' component={UsersPage} operation={prosmotrPolzovatelei} route={true} exact />
                <PermissionCheck path='/settings' component={SettingsPage} operation={prosmotrNastroek} route={true} exact />
              </main>
            </div>
          </>
          : null
      }
    </div>
  )
    ;
};

export default Layout;


