import dateFormat from 'dateformat';

dateFormat.i18n = {
  dayNames: [
    "Вос",
    "Пон",
    "Вт",
    "Ср",
    "Чт",
    "Пн",
    "Суб",
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  monthNames: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июнь",
    "Июль",
    "Авг",
    "Сен",
    "Окт",
    "Ноябрь",
    "Дек",
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декаборь",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
}
export const formatDate = (date: string): string => {
  return dateFormat(date, "HH:MM dd mmmm yyyy ");
};