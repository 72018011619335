import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPost } from '@interfaces/index';

interface IState {
  posts: IPost[];
  post: IPost | null;
  pagination: number;
}

const initialState: IState = {
  posts: [],
  post: null,
  pagination: 0,
};

export const { actions: postsAction, reducer: postsReducer } = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<{ posts: IPost[], pagination: number }>) => ({
      ...state,
      posts: action.payload.posts,
      pagination: action.payload.pagination
    }),
    setPost: (state, action: PayloadAction<{ post: IPost | null }>) => ({
      ...state,
      post: action.payload.post
    }),
  },
});