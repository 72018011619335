import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComment } from '@interfaces/index';

interface IState {
  comments: IComment[];
  comment: IComment | null;
  pagination: number
}

const initialState: IState = {
  comments: [],
  comment: null,
  pagination: 0
};

export const { actions: commentsAction, reducer: commentsReducer } = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments: (state, action: PayloadAction<{comments: IComment[], pagination: number}>) => ({
      ...state,
      comments: action.payload.comments,
      pagination: action.payload.pagination
    }),
    setComment: (state, action: PayloadAction<{comment: IComment | null}>) => ({
      ...state,
      comment: action.payload.comment
    }),
  },
});