import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { formatDate } from '@helpers/format-date';
import { Permission } from '@helpers/permissions';
import {obnovlenieKommentarii, udalenieKommentarii, vosstanovlenieKommentarii} from '@_data/permissions';
import { commentSet, getComments } from '@store/comments/comments.thunks';
import { ActionModal } from '@components/comments/UI/modal';
import {deleteComment, refreshComment, updateComment} from '@services/comments.service';
import { CommentContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import {AxiosError} from "axios";

const status = (status: string) => {
  switch (status) {
    case 'moderation':
      return {
        row: '',
        btn: 'bg-gray-300 text-gray-600',
        text: 'В модерации',
      };
    case 'published':
      return {
        row: 'bg-indigo-200',
        btn: 'bg-indigo-300 text-indigo-600',
        text: 'Опубликовано',
      };
    case 'approved':
      return {
        row: '',
        btn: 'bg-green-300 text-green-600',
        text: 'Проверено',
      };
    case 'reported':
      return {
        row: 'bg-red-200',
        btn: 'bg-red-300 text-red-600',
        text: 'Жалоба',
      };
    case 'banned':
      return {
        row: 'bg-yellow-200',
        btn: 'bg-yellow-300 text-yellow-600',
        text: 'Автобан',
      };
    default:
      return {
        row: '',
        btn: '',
      };
  }
};

export const CommentsTbody: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useContext(CommentContext);
  const [open, setOpen] = useState(false);
  const comment = useSelector((state: State) => state.comments.comment);
  const comments = useSelector((state: State) => state.comments.comments);
  const moderate = (id: number) => {
    updateComment(id, { status: 'approved' })
      .then(() => {
        dispatch(getComments(filter));
        setMessage(dispatch, 'Комментарий отмечен как провереный');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const refresh = async (id: number) => {
    await refreshComment(id)
      .then(() => {
        dispatch(getComments(filter));
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const commentDelete = (id: number) => {
    deleteComment(id, {
      reason: 'Удалено сервером'
    })
        .then(() => {
          dispatch(getComments(filter));
          setMessage(dispatch, 'Комментарий успешно деактевирован');
        })
        .catch((e: AxiosError) => setMessage(dispatch, 'Что то пошло не так'));
  };
  return (
    <tbody className='bg-white divide-y divide-gray-200'>
    {!!comments.length && comments.map((comment) => {
      const stat = status(comment.status);
      return (
        <React.Fragment
          key={comment.user_id + comment.id + comment.post_id}
        >
          <tr
            style={{ borderBottomWidth: '0' }}
            className={`cursor-pointer ${stat.row}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <td className='px-6 py-4 whitespace-nowrap'>
              <div className='flex items-center'>
                <div className='flex-shrink-0 h-10 w-10'>
                  <img className='h-10 w-10 rounded-full' src={comment.user?.avatar} alt='' />
                </div>
                <div className='ml-4'>
                  <div className='text-sm font-medium text-gray-900'><p
                    className='overflow-hidden overflow-ellipsis w-80'>{comment.user?.name}</p></div>
                  <div className='text-sm text-gray-500'><p
                    className='overflow-hidden overflow-ellipsis w-80'>{formatDate(comment.created_at)}</p>
                  </div>
                </div>
              </div>
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
              <div className='text-sm font-medium text-gray-900'>
                <p className='overflow-hidden overflow-ellipsis w-60'>{comment?.post?.title}</p>
              </div>
              <div className='text-sm text-gray-500'>
                <p className='overflow-hidden overflow-ellipsis w-60'>{comment.post?.category.name.ru}</p>
              </div>
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
              {
                !comment.deleted_at &&
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${stat.btn}`}>
                  {stat.text}
                </span>
              }
              {
                !!comment.deleted_at && <span
                  className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
                В архиве
              </span>
              }
            </td>
            <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>

              {
                comment.status !== 'approved' && <Permission action={obnovlenieKommentarii}>
                  <button
                    className='text-indigo-600 hover:text-indigo-900 mx-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      moderate(comment.id);
                    }}
                  >
                    Проверено
                  </button>
                </Permission>
              }
              {
                comment.deleted_at
                  ? (
                    <Permission action={vosstanovlenieKommentarii}>
                      <button
                        className='text-grey-600 hover:text-grey-900 mx-2'
                        onClick={(e) => {
                          e.stopPropagation();
                          refresh(comment.id);
                        }}
                      >
                        Восстановить.
                      </button>
                    </Permission>
                  )
                  : (
                    <Permission action={obnovlenieKommentarii}>
                      <button
                        className='text-indigo-600 hover:text-indigo-900 mx-2'
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen(true);
                          dispatch(commentSet(comment));
                        }}
                      >
                        Ред.
                      </button>
                    </Permission>
                  )
              }
              <Permission action={udalenieKommentarii}>
                <button
                    className='text-indigo-600 hover:text-indigo-900 mx-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      commentDelete(comment.id)
                    }}
                >
                  Удал.
                </button>
              </Permission>
            </td>
          </tr>
          <tr
            style={{ borderTopWidth: '0' }}
            className={`${stat.row}`}
          >
            <td
              colSpan={4}
              className='px-6 py-4 text-xs font-normal'
            >
              {
                comment.image &&
                <img src={comment.image} alt='' className='w-48 h-48 object-contain' />
              }
              {comment.text}
            </td>
          </tr>
        </React.Fragment>
      );
    })}
    {
      comment && <Permission action={obnovlenieKommentarii}>
        <ActionModal comment={comment} open={open} setOpen={setOpen} />
      </Permission>
    }
    </tbody>
  );
};