import React from 'react';
import classNames from 'classnames';
import classes from './spinner.module.css';

interface IProps {
  className?: string;
}

export const Spinner: React.FC<IProps> = ({ className }) => {
  return (
    <div className={classNames('w-full overflow-hidden', className)}>
      <div className={classes.spinner} />
    </div>
  );
};