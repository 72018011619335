import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from '@store/auth/auth.slices';
import { usersReducer } from '@store/users/users.slices';
import { rolesReducer } from '@store/roles/roles.slices';
import { postsReducer } from '@store/posts/posts.slices';
import { categoriesReducer } from '@store/categories/categories.slices';
import { permissionsReducer } from '@store/permissions/permissions.slices';
import { notificationsReducer } from '@store/notifications/notifications.slices';
import { commentsReducer } from '@store/comments/comments.slices';
import { tagsReducer } from '@store/tags/tags.slices';
import { statisticsReducer } from '@store/statistics/statistics.slice';
import { adsReducer } from '@store/ads/ads.slices';
import { bannedWordsReducer } from '@store/bannedWords/bannedWords.slices';

export const rootReducer = combineReducers({
  auth: authReducer,
  ads: adsReducer,
  users: usersReducer,
  roles: rolesReducer,
  tags: tagsReducer,
  posts: postsReducer,
  permissions: permissionsReducer,
  categories: categoriesReducer,
  notifications: notificationsReducer,
  comments: commentsReducer,
  statistics: statisticsReducer,
  bannedWords: bannedWordsReducer,
});
