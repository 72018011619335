import { api } from '@api/index';
import { permissionsAction } from './permissions.slices';
import { setMessage } from '@services/dispatch.service';

export const getPermissions = () => async (dispatch: any) => {
  return api.get(`/dashboard/permissions`)
    .then(res => {
      dispatch(permissionsAction.setPermissions({ permissions: res.data.result.permissions }));
    })
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};