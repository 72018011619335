import { api } from '@api/index';

export const deleteImage = (id: number) => {
  return api
    .post(`/dashboard/categories/${id}/remove/image`)
    .then((res) => res)
}
export const createCategory = (data: any) => {
  return api
    .post('/dashboard/categories', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => res.data.result)
}
export const refreshCategory = (id: number) => {
  return api
    .post(`/dashboard/categories/${id}/restore`)
    .then(res => res.data.result.category)
}
export const updateCategory = (id: number, data: any) => {
  return api
    .post(`/dashboard/categories/${id}`, data)
    .then(res => res.data.result.category)
}

export const deleteCategory = (id: number) => {
  return api
    .delete(`/dashboard/categories/${id}`)
    .then(res => res.data.result)
}

export const getCategoriesService = (params: any) => {
  return api
    .get(`/dashboard/categories`, { params })
    .then(res => res.data.result)
}
export const getAllCategoriesService = () => {
  return api
    .get(`/dashboard/categories/all`)
    .then(res => res.data.result)
}

export const getCategoryService = (id: string) => {
  return api
    .get(`/dashboard/categories/${id}`)
    .then(res => res.data.result)
}