import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
} from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { getStatistics } from '@store/statistics/statistics.thunk';
import { State } from '@store/index';
import { getLastPopularPosts, postsSet } from '@store/posts/posts.thunks';
import { commentsSet, getLastComments } from '@store/comments/comments.thunks';
import { defaultImage } from '@_data/helpers';
import { Permission } from '@helpers/permissions';
import { prosmotrKommentariev } from '@_data/permissions';

const name = (key: string) => {
  switch (key) {
    case 'comments_average':
      return 'Комментарии';
    case 'rating_average':
      return 'Рейтинг';
    case 'views_average':
      return 'Просмотры';
    default:
      return '';
  }
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStatistics());
    dispatch(getLastPopularPosts());
    dispatch(getLastComments());
    return () => {
      dispatch(commentsSet({ data: [], total: 0 }));
      dispatch(postsSet({ data: [], total: 0 }));
    };
  }, [dispatch]);
  const statistics = useSelector((state: State) => state.statistics.statistics);
  const { posts } = useSelector((state: State) => state.posts);
  const { comments } = useSelector((state: State) => state.comments);
  return (
    <div className='relative min-h-screen bg-gray-100'>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <div className='py-10'>
        <div className='max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8'>
          <div className='col-span-12'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>Последние 30 дней</h3>
            <dl
              className='mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x'>
              {statistics?.map((item) => (
                <div key={item.key} className='px-4 py-5 sm:p-6'>
                  <dt className='text-base font-normal text-gray-900'>{name(item.key)}</dt>
                  <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                    <div className='flex items-baseline text-2xl font-semibold text-indigo-600'>
                      {item.value}
                      <span className='ml-2 text-sm font-medium text-gray-500'>из {item.last_value}</span>
                    </div>

                    <div
                      className={classNames(
                        item.percent > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
                      )}
                    >
                      {
                        item.percent > 0 ? (
                          <ArrowSmUpIcon
                            className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'
                            aria-hidden='true'
                          />
                        ) : (
                          <ArrowSmDownIcon
                            className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'
                            aria-hidden='true'
                          />
                        )
                      }

                      <span className='sr-only'>{item.percent > 0 ? 'Increased' : 'Decreased'} by</span>
                      {item.percent}%
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <main className='col-span-full lg:col-span-9 xl:col-span-8 mt-4 xl:mt-0'>
            {/* Projects table (small breakpoint and up) */}
            <div className='block'>
              <div className='align-middle inline-block min-w-full border-b border-gray-200'>
                <table className='min-w-full'>
                  <thead>
                  <tr className='border-t border-gray-200'>
                    <th
                      className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      <span className='lg:pl-2'>Статьи</span>
                    </th>
                    <th
                      className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Автор
                    </th>
                    <th
                      className='hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Дата создания
                    </th>
                  </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-100'>
                  {posts?.map((post) => (
                    <tr key={post.id}>
                      <td className='px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900'>
                        <div className='flex items-center space-x-3 lg:pl-2'>
                          <div
                            className='bg-blue-500 flex-shrink-0 w-2.5 h-2.5 rounded-full'
                            aria-hidden='true'
                          />
                          <p className='truncate hover:text-gray-600'>
                            <span>
                              {post?.title}
                            </span>
                          </p>
                        </div>
                      </td>
                      <td className='px-6 py-3 text-sm text-gray-500 font-medium'>
                        <div className='flex items-center space-x-2'>
                          <div className='flex flex-shrink-0 -space-x-1'>
                            <img
                              className='max-w-none h-6 w-6 rounded-full ring-2 ring-white'
                              src={post?.image || defaultImage}
                              alt={post?.image || ''}
                            />
                          </div>
                          <span className='flex-shrink-0 text-xs leading-5 font-medium'>
                              {post?.user?.name}
                          </span>
                        </div>
                      </td>
                      <td className='hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right'>
                        {post.created_at}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
          <aside className='col-span-full block xl:col-span-4 mt-4 xl:mt-0'>
            <div className='sticky top-4 space-y-4'>
              <section aria-labelledby='trending-heading'>
                <div className='bg-white rounded-lg shadow'>
                  <div className='p-6'>
                    <h2 id='trending-heading' className='text-base font-medium text-gray-900'>
                      Комментарии
                    </h2>
                    <div className='mt-6 flow-root'>
                      <ul className='-my-4 divide-y divide-gray-200'>
                        {comments?.map((comment) => (
                          <li key={comment.id} className='flex py-4 space-x-3'>
                            <div className='flex justify-between gap-x-3'>
                              <div className='min-w-0 '>
                                <div className='flex items-start gap-2'>
                                  <img
                                    src={comment.user?.avatar || defaultImage}
                                    alt='avatar'
                                    className='rounded-md h-6 w-6'
                                  />
                                  <div className='flex flex-wrap'>
                                    <h4
                                      className='text-sm font-medium text-gray-900 font-bold truncate mr-2'>{comment?.user?.name}</h4>
                                    <time dateTime={comment.created_at}
                                          className='flex-shrink-0 whitespace-nowrap text-sm text-gray-500'>
                                      {comment.created_at}
                                    </time>
                                  </div>
                                </div>
                                <p className='mt-2 height_2_ellips text-sm text-gray-600'>{comment.text}</p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Permission action={prosmotrKommentariev}>
                      <div className='mt-6'>
                        <Link
                          to='/comments'
                          className='w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
                        >
                          Все комментарии
                        </Link>
                      </div>
                    </Permission>
                  </div>
                </div>
              </section>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};