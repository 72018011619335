import { api } from '@api/index';

export const createTag = (data: any) => {
  return api
    .post('/dashboard/post-tags', {
      ...data
    })
    .then(res => res.data.result.tag)
}
export const updateTag = (id: number, data: any) => {
  return api
    .post(`/dashboard/post-tags/${id}`, {
      ...data,
      _method: 'PUT'
    })
    .then(res => res.data.result.tag)
}
export const deleteTag = (id: number) => {
  return api
    .post(`/dashboard/post-tags/${id}`, {
      _method: 'DELETE'
    })
    .then(res => res.data.result)
}
export const refreshTag = (id: number) => {
  return api
    .post(`/dashboard/post-tags/${id}/restore`)
    .then(res => res.data.result)
}
export const getTagsService = (params: any) => {
  return api
    .get(`/dashboard/post-tags`, { params })
    .then(res => {
      return res.data.result.tags;
    })
}
export const getTagService = (id: string) => {
  return api
    .get(`/dashboard/post-tags/${id}`)
    .then(res => res.data.result.tag)
}
export const getAllTagsService = () => {
  return api
    .get(`/dashboard/post-tags/`)
    .then(res => res.data.result.tags)
}
