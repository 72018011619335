import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRole } from '@interfaces/index';

interface IState {
  roles: IRole[];
  allRoles: IRole[];
  role: IRole | null;
  pagination: number;
}

const initialState: IState = {
  roles: [],
  allRoles: [],
  role: null,
  pagination: 0,
};

export const { actions: rolesAction, reducer: rolesReducer } = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<{ roles: IRole[], pagination: number }>) => ({
      ...state,
      roles: action.payload.roles,
      pagination: action.payload.pagination,
    }),
    setRole: (state, action: PayloadAction<{ role: IRole | null }>) => ({
      ...state,
      role: action.payload.role,
    }),
    setAllRoles: (state, action: PayloadAction<{ allRoles: IRole[] }>) => ({
      ...state,
      allRoles: action.payload.allRoles,
    }),
  },
});