import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { deleteCategory, refreshCategory } from '@services/categories.service';
import {
  categorySet,
  getCategories,
} from '@store/categories/categories.thunks';
import { formatDate } from '@helpers/format-date';
import { SlideOvers } from '@components/shared/slideovers';
import { CategoryPage } from '@pages/categories/category';
import { EditCategory } from '@pages/categories/edit';
import { PencilIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/solid';
import { CreateCategory } from '@pages/categories/create';
import { CategoryContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import { Permission } from '@helpers/permissions';
import {
  obnovlenieKategorii,
  udalenieKategorii,
  vosstanovlenieKategorii,
} from '@_data/permissions';

export const CategoriesTBody: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useContext(CategoryContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('show');
  const category = useSelector((state: State) => state.categories.category);
  const categories = useSelector((state: State) => state.categories.categories);
  const deleteHandler = (id: number) => {
    deleteCategory(id)
      .then(() => {
        dispatch(categorySet(null));
        dispatch(getCategories(filter));
        setOpen(false);
      })
      .catch((err) =>
        setMessage(dispatch, err.response?.data?.result?.message || err),
      );
  };
  const refresh = async (id: number) => {
    await refreshCategory(id)
      .then(() => {
        dispatch(getCategories(filter));
      })
      .catch((err) =>
        setMessage(dispatch, err.response?.data?.result?.message || err),
      );
  };
  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {categories.map((category, i) => (
        <tr
          key={category.id + i}
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setShow(true);
            setMode('show');
            dispatch(categorySet(category));
          }}
        >
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full"
                  src={category.image}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {category.name?.ru || category.name?.uz}
                </div>
                <div className="text-sm text-gray-500">{category.slug}</div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {category.position}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {formatDate(category.created_at)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" />
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            {category.deleted_at ? (
              <Permission action={vosstanovlenieKategorii}>
                <button
                  className="text-gray-600 hover:text-gray-900"
                  onClick={(e) => {
                    e.stopPropagation();
                    refresh(category.id);
                  }}
                >
                  <RefreshIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
              </Permission>
            ) : (
              <>
                <Permission action={obnovlenieKategorii}>
                  <button
                    className="text-gray-600 hover:text-gray-900"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(categorySet(category));
                      setMode('edit');
                      setShow(true);
                    }}
                  >
                    <PencilIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                  </button>
                </Permission>
                <Permission action={udalenieKategorii}>
                  <button
                    className="text-red-600 hover:text-red-900"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(categorySet(category));
                      setOpen(true);
                    }}
                  >
                    <XCircleIcon
                      className="w-5 h-5 text-red-400"
                      aria-hidden="true"
                    />
                  </button>
                </Permission>
              </>
            )}
          </td>
        </tr>
      ))}
      {category && (
        <Permission action={udalenieKategorii}>
          <PopupDelete
            open={open}
            setOpen={setOpen}
            setShow={setShow}
            setMode={setMode}
            handleDelete={deleteHandler}
            data={{ name: category.name?.ru, id: category.id }}
          />
        </Permission>
      )}
      <SlideOvers
        Create={CreateCategory}
        Show={CategoryPage}
        Edit={EditCategory}
        show={show}
        mode={mode}
        confirmModal={setOpen}
        setMode={setMode}
        setShow={setShow}
      />
    </tbody>
  );
};
