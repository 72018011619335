import React, { useEffect, useRef, useState } from 'react';
import { Dropzone } from '@components/shared/dropzone';
import EditorJs from 'react-editor-js';
import {EDITOR_JS_TOOLS, logLevel} from '@pages/posts/EDITOR_JS_TOOLS';
import { useForm } from 'react-hook-form';
import { deletePost, refreshPost, updatePost } from '@services/posts.service';
import { formatData } from '@helpers/format-data';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@components/shared/select';
import { State } from '@store/index';
import { getAllCategories } from '@store/categories/categories.thunks';
import { Breadcrumbs } from '@components/posts/UI/breadcrumbs';
import { PostEditButtons } from '@components/posts/UI/buttons';
import { useParams } from 'react-router-dom';
import { getPost, postSet } from '@store/posts/posts.thunks';
import { Permission } from '@helpers/permissions';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { moderaciyaStati, udalenieStati } from '@_data/permissions';
import { setterPreview } from '@helpers/preview';
import { setMessage } from '@services/dispatch.service';
import { SearchSelect } from '@components/shared/searchselect';
import { getTagsService } from '@services/tags.service';
import { forPost, website } from '@_data/helpers';
import { Sidebar } from '@components/posts/UI/sidebar';
import { Spinner } from '@components/shared/spinner';
import { ITag } from '@interfaces/index';
import { getAuthors } from '@store/users/users.thunks';
import { DatePick } from '@components/shared/datePicker';
import { Settings } from '@components/posts/settings';
import { handleReady } from '@helpers/helpers';

const tools: any = EDITOR_JS_TOOLS;
const log: any = logLevel

export const EditPost: React.FC = () => {
  const editorRef = useRef<any>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
      watch,
    getValues,
    control,
  } = useForm();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPost(+params.id));
    dispatch(getAllCategories());
    dispatch(getAuthors());
  }, [dispatch, params.id]);
  const post: any = useSelector((state: State) => state.posts.post);
  const categories = useSelector((state: State) => state.categories.allCategories);
  const authors = useSelector((state: State) => state.users.authors);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<any>(post?.image ? [{ preview: post.image }] : []);
  const [data, setData] = useState<any>(post?.description || null);
  const [tags, setTags] = useState<ITag[]>([]);
  useEffect(() => {
    return () => {
      dispatch(postSet(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (post?.image) setFiles([{ preview: post.image }]);
    if(post?.description) setData(JSON.parse(post?.description))
  }, [post]);

  const previewpage = () => setterPreview({
    title: getValues(`title`),
    meta_description: getValues('meta_description'),
    description: data,
    image: files[0]?.preview || post?.image,
    category: getValues('category'),
  });

  const refresh = async () => {
    await refreshPost(+params.id)
      .then(post => {
        dispatch(postSet(post));
        setMessage(dispatch, 'Статья успешно опубликована');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };

  const deleteHandler = () => {
    deletePost(+params.id)
      .then((res) => {
        setOpen(false);
        dispatch(postSet(res.post));
        setMessage(dispatch, 'Статья успешно удалена');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };

  const handleChange = (newData: any) => {
    setData(newData);
  };

  const submitHandle = async (formdata: any) => {
    await updatePost(+params.id, formatData({
      formdata: {
        ...formdata,
        tags_id: tags,
        category: formdata.category?.id,
        author_id: formdata.author_id?.id,
      },
      image: (typeof files[0] === 'object' && Object.keys(files[0]).length === 1) ? null : files[0],
      data,
      patch: true,
    }))
      .then(post => {
        setMessage(dispatch, 'Изменения успешно сохранены');
        dispatch(postSet(post));
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          Object.keys(err.response.data.errors).forEach(key => {
            setError(key, {
              type: 'required',
              message: err.response.data.errors[key][0],
            });
          });
        }
      });
  };
  const undoRedo = (undo: boolean = false) => () => {
    const keydownCTRL = new KeyboardEvent('keydown', {
      bubbles: true,
      key: undo ? 'z' : 'y',
      ctrlKey: true,
      metaKey: true,
    });
    document.querySelector(`#${editorRef.current?.holder}`)?.dispatchEvent(keydownCTRL);
  };
  return (
    <form
      onSubmit={handleSubmit(submitHandle)}
      className='post_container relative lg:pl-20 px-2 sm:pt-1'
    >
      {
        !!post &&
        <div className='w-full py-2 px-5 bg-white mb-5 flex flex-wrap justify-between'>
          <Breadcrumbs />
          {
            isSubmitting
              ? <Spinner className='text-right' />
              : (
                <PostEditButtons
                  isSubmiting={isSubmitting}
                  active={post?.active}
                  refresh={refresh}
                  onDelete={setOpen}
                  deleted={!!post?.deleted_at}
                  selectbutton={true}
                  previewpage={previewpage}
                />
              )
          }
        </div>
      }
      <div className='lg:grid lg:grid-cols-12 lg:gap-x-5'>
        <Sidebar>
          {
            !!categories && !!post &&
            <Select
              selected={post.category}
              name='category'
              control={control}
              error={errors['category']}
              items={categories}
              title='Категория'
            />
          }
          {
            !!authors && !!post &&
            <Permission action={moderaciyaStati}>
              <Select
                selected={post.user}
                name='author_id'
                control={control}
                error={errors['author_id']}
                items={authors}
                title='Автор'
              />
            </Permission>
          }
          {
            !!post &&
            <Permission action={moderaciyaStati}>
              <label className='block text-sm font-medium text-gray-700'>
                Просмотров
              </label>
              <input
                defaultValue={post?.views_count}
                type='number'
                placeholder='0'
                {...register('views_count')}
                className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              />
              <p className='mt-2 text-red-600 text-sm'>{(errors.author_id && errors.author_id.message)}</p>
            </Permission>
          }
          {
            !!post &&
            <Permission action={moderaciyaStati}>
              <label className='block text-sm font-medium text-gray-700'>
                Дата создания
              </label>
              <DatePick name='created_at' defaultValue={post.created_at} control={control} />
            </Permission>
          }
        </Sidebar>

        <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
          {/*Main*/}
          <div id='post'>
            <div className='shadow sm:rounded-md'>
              <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
                <div>
                  <h3 className='text-lg leading-6 font-medium text-gray-900'>Редактирование статьи</h3>
                </div>
                <div>
                  {
                    !!post &&
                    <Dropzone files={files} setFiles={setFiles} title={forPost} />
                  }
                </div>
                <div
                  className='w-full'
                >
                  {
                    !!post && <>
                      <input
                        defaultValue={post.caption}
                        type='text'
                        placeholder='Caption'
                        {...register('caption')}
                        className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      />
                      <p className='mt-2 text-red-600 text-sm'>{errors.caption && errors.caption.message}</p>
                    </>
                  }
                </div>
                <div>
                  {
                    !!post &&
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Язык
                      </label>
                      <select
                        defaultValue={post.locale}
                        {...register('locale')}
                        className='mt-1 block border w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                      >
                        <option value='ru'>Русский</option>
                        <option value='uz'>Узбекский</option>
                      </select>
                    </div>
                  }
                </div>
                <div>
                  <div
                    className='w-full'
                  >
                    {
                      !!post && <>
                        <textarea
                          placeholder='Заголовок'
                          defaultValue={post.title}
                          {...register('title')}
                          className={`post_title mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        <p className='mt-2 text-red-600 text-sm'>{(errors.title && errors.title.message)}</p>
                      </>
                    }
                  </div>
                  <div
                    className='w-full'
                  >
                    {
                      !!post && <>
                        <textarea
                          placeholder='URL'
                          defaultValue={post.slug}
                          {...register('slug')}
                          className={`post_title mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        <p className='mt-2 text-red-600 text-sm'>{errors.slug && errors.slug.message}</p>
                      </>
                    }
                  </div>
                  {
                    !!post &&
                    <>
                      <a
                        href={`${website}${watch('locale') === 'ru' ? '' : '/uz'}/posts/${post?.id}`}
                        rel='noreferrer'
                        target='_blank'
                        className='mb-4 text-blue-500'
                      >{`${website}${watch('locale') === 'ru' ? '' : '/uz'}/posts/${post?.id}`}</a>
                      <br />
                      <br />
                      <a
                        href={`${website}${watch('locale') === 'ru' ? '' : '/uz'}/posts/${post?.slug}`}
                        rel='noreferrer'
                        target='_blank'
                        className='mb-4 text-blue-500'
                      >{post?.slug}</a>

                    </>
                  }
                  <div
                    className='w-full'
                  >
                    {
                      !!post && <>
                        <textarea
                          placeholder='Короткое содержание'
                          defaultValue={post?.meta_description}
                          {...register('meta_description')}
                          className={`meta_description mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        />
                        <p
                          className='mt-2 text-red-600 text-sm'>{errors.meta_description && errors.meta_description.message}</p>
                      </>
                    }
                  </div>
                  <div
                    className={'mb-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md'}>
                    {
                      !!post && <EditorJs
                        logLevel={log}
                        ref={editorRef}
                        tools={tools}
                        onReady={handleReady}
                        onChange={(api, newData) => handleChange(newData)}
                        placeholder='Статья'
                        data={JSON.parse(post.description || '{}')}
                      />
                    }
                    <div className='rounded-md p-2 flex justify-end gap-2'>
                      <img
                        src='/undo.png'
                        alt=''
                        className='cursor-pointer h-4 w-4'
                        style={{ transform: 'scale3d(1.5, 1.5, 1.5)' }}
                        onClick={undoRedo(true)}
                      />
                      <img
                        src='/undo.png'
                        alt=''
                        className='cursor-pointer h-4 w-4'
                        style={{ transform: 'scale3d(-1.5, 1.5, -1.5)' }}
                        onClick={undoRedo()}
                      />
                    </div>
                    <p className='mt-2 text-red-600 text-sm'>{(errors.description && errors.description?.message)}</p>
                  </div>
                  <div className='mb-2'>
                    {
                      !!post &&
                      <SearchSelect
                        selected={post.tags}
                        isMulti={true}
                        ru={true}
                        setSelected={setTags}
                        isTag={true}
                        title='Тэги'
                        getItems={(name: string) => getTagsService({ name })}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end'>
                <PostEditButtons
                  isSubmiting={isSubmitting}
                  active={post?.active}
                  refresh={refresh}
                  onDelete={setOpen}
                  deleted={!!post?.deleted_at}
                  selectbutton={false}
                  previewpage={previewpage}
                />
              </div>
            </div>
          </div>
          <Permission action={moderaciyaStati}>
            {
              !!post &&
              <Settings register={register} post={post} />
            }
            <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end'>
              {
                isSubmitting
                  ? <Spinner className='text-right' />
                  : <PostEditButtons
                    isSubmiting={isSubmitting}
                    active={post?.active}
                    refresh={refresh}
                    onDelete={setOpen}
                    deleted={!!post?.deleted_at}
                    selectbutton={false}
                    previewpage={previewpage}
                  />
              }
            </div>
          </Permission>
        </div>
      </div>
      <Permission action={udalenieStati}>
        {
          !!post
            ? <PopupDelete
              open={open}
              setOpen={setOpen}
              handleDelete={deleteHandler}
              data={{ name: post.title, id: post.id }}
            />
            : null
        }
      </Permission>
    </form>
  );
};