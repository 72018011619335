import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { defaultImage } from '@_data/helpers';
import { Control, useController } from 'react-hook-form';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface IError {
  message: string;
}

interface Props {
  items: any;
  title: string;
  selected: any;
  name: string;
  error?: IError;
  control: Control;
}

export const Select: React.FC<Props> = ({ selected, items, title, control, name, error }) => {
  const { field } = useController({
    control,
    defaultValue: selected,
    name,
  });
  const selectItems = [
    {
      id: 0,
      name: 'Значение по умолчанию',
      image: defaultImage,
    },
    ...items,
  ];
  return (
    <>
      <Listbox value={field.value} onChange={field.onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className='block text-sm font-medium text-gray-700'>{title}</Listbox.Label>
            <div className='mt-1 relative'>
              <Listbox.Button
                className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
              <span className='flex items-center'>
                <img src={field.value?.image || field.value?.avatar || defaultImage} alt=''
                     className='flex-shrink-0 h-5 w-5 rounded-full' />
                <span
                  className='ml-3 block truncate w-40'>{field.value?.name?.ru || field.value?.name?.uz || field.value?.name || 'Выберите значение'}</span>
              </span>
                <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={React.Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options
                  className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                  {selectItems.map((item: any) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className='flex items-center'>
                            <img src={item?.image || item?.avatar} alt=''
                                 className='flex-shrink-0 h-6 w-6 rounded-full' />
                            <span
                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                            >
                            {item?.name?.ru || item?.name?.uz || item?.name}
                          </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {
        error &&
        <p className='mt-2 text-red-600 text-sm'>{error.message}</p>
      }
    </>
  );
};