import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { sidebarItems } from '@_data/titles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '@store/auth/auth.thunks';
import { State } from '@store/index';
import { Permission, PermissionCheck } from '@helpers/permissions';
import { SlideOvers } from '@components/shared/slideovers';
import { Account } from '@pages/settings/account';
import { prosmotrNastroek } from '@_data/permissions';

export const Sidebar: React.FC = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: State) => state.auth.user);
  const signOut = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <>
      <div className='flex md:flex md:flex-shrink-0 hidden md:visible'>
        <div className='flex flex-col w-64'>
          <div className='flex flex-col h-0 flex-1 bg-gray-800'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-4'>
                <img
                  className='h-8 w-auto'
                  src='https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg'
                  alt='Workflow'
                />
              </div>
              <nav className='mt-5 flex-1 px-2 bg-gray-800 space-y-1'>
                {
                  sidebarItems.map((item, i) => (
                    <PermissionCheck component={SidebarItem} operation={item.slug} item={item} key={item.name + i} />
                  ))
                }
              </nav>
            </div>
            <div className='flex-shrink-0 flex bg-gray-700 p-4'>
              <div className='flex-shrink-0 w-full group block'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <div>
                      {
                        !!user && <img
                          className='inline-block h-9 w-9 rounded-full'
                          src={user.avatar}
                          alt=''
                        />
                      }
                    </div>
                    <div className='ml-3'>
                      <p
                        className='text-sm font-medium text-white overflow-hidden overflow-ellipsis whitespace-nowrap w-20 sm:w-40 cursor-pointer'>{user?.name}</p>
                      <p
                        className='text-xs font-medium text-gray-300 group-hover:text-gray-200 cursor-pointer'>{user?.role?.name}</p>
                    </div>
                  </div>
                  <div className='w-1/12 flex items-center'>
                    <Menu as='div' className='relative inline-block text-left'>
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className='rounded-full flex items-center text-gray-400'>
                              <span className='sr-only'>Open options</span>
                              <DotsVerticalIcon className='h-5 w-5' aria-hidden='true' />
                            </Menu.Button>
                          </div>

                          <Transition
                            show={open}
                            as={React.Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'
                          >
                            <Menu.Items
                              static
                              className='origin-bottom-right absolute right-0 bottom-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                            >
                              <div className='py-1'>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => setShow(true)}
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 w-full text-left text-sm',
                                      )}
                                    >
                                      Профиль
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Permission action={prosmotrNastroek}>
                                      <NavLink
                                        to='/settings'
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        )}
                                      >
                                        Настройки
                                      </NavLink>
                                    </Permission>
                                  )}
                                </Menu.Item>

                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      type='button'
                                      onClick={signOut}
                                      className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full text-left px-4 py-2 text-sm w-full',
                                      )}
                                    >
                                      Выйти
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlideOvers show={show} setShow={setShow} mode={'edit'} Edit={Account} />
    </>
  );
};

const SidebarItem: React.FC<any> = ({ item }) => {
  return (
    <NavLink
      to={item.to}
      activeClassName='bg-gray-900 text-white'
      className='text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
      exact
    >
      <item.icon
        className={classNames(
          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
          'mr-3 flex-shrink-0 h-6 w-6',
        )}
        aria-hidden='true'
      />
      {item.name}
    </NavLink>
  );
};