import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import LinkTool from '@editorjs/link';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import Warning from '@editorjs/warning';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';
import Image from '@helpers/image';
import Lead from '@helpers/lead';
import Meta from '@helpers/meta';
import Quote from '@helpers/quote';
import InstagramTool from '@helpers/instagram';
import TelegramTool from '@helpers/telegram';
import Hyperlink from "@helpers/hyperlink";
import Paragraph from "@helpers/paragraph";
import {api} from "@api/index";
import {formatData} from "@helpers/format-data";

export const logLevel = "ERROR"
export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true
  },
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return api.post(`/dashboard/storage/upload`, formatData({image: file}), {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
              .then(res => {
                return {
                        success: 1,
                        file: {
                          url: res.data.url,
                        },
                      }
              })
        },
        uploadByUrl(url) {
          return new Promise((res) => {
            res({
              success: 1,
              file: { url },
            });
          });
        }
      },
    },
  },
  quote: {
    class: Quote,
    inlineToolbar: true
  },
  telegram: TelegramTool,
  link: {
    class: Hyperlink,
    config: {
      shortcut: 'CMD+L',
      target: null,
      rel: null,
      availableTargets: ['_blank', '_self'],
      availableRels: ['author', 'noreferrer'],
      validate: false,
    }
  },
  linkTool: {
    class: LinkTool,
    config: {
      endpoint: 'https://api.uznews.uz/get/url/data',
    },
  },
  embed: Embed,
  table: Table,
  marker: Marker,
  lead: Lead,
  meta: Meta,
  warning: Warning,
  list: List,
  instagram: InstagramTool,
  raw: Raw,
  header: Header,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
};
