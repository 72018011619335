import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { createUser } from '@services/users.service';
import { State } from '@store/index';
import { getUsers } from '@store/users/users.thunks';
import { XIcon } from '@heroicons/react/solid';
import { formatData } from '@helpers/format-data';
import { imageUpload } from '@helpers/preview';
import { UserContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
interface ICreateUser {
  email: string,
  password: string,
  name: string,
  phone: string,
  avatar: Blob | null,
  roles: string;
}

interface IProps {
  setOpen: (value: boolean) => void;
}

export const UserCreate: React.FC<IProps> = ({ setOpen }) => {
  const dispatch = useDispatch();
  const filter = useContext(UserContext)
  const { register, handleSubmit, formState: { errors }, setError } = useForm();

  const { allRoles } = useSelector((state: State) => state.roles);
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>()

  const fields = [
    {
      field: 'name',
      type: 'text',
      label: 'Имя пользователя',
    },
    {
      field: 'phone',
      type: 'text',
      label: 'Номер',
    },
    {
      field: 'email',
      type: 'text',
      label: 'Email',
    },
    {
      field: 'password',
      type: 'password',
      label: 'Пароль',
    },
    {
      field: 'role',
      type: '',
      label: 'Роль',
    },
    {
      field: 'about_me',
      type: 'text',
      label: 'Обо мне',
    },
  ];


  const handleCreateUser = (formdata: ICreateUser) => {
    createUser(formatData({formdata: {...formdata, phone: null}, avatar: file, phone: formdata.phone}))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUsers(filter));
          setOpen(false);
        }
      })
      .catch((err) => {
        if (err.response.data.errors){
          Object.keys(err.response.data.errors).forEach((key) => {
            setError(key, {
              type: 'server-error',
              message: err.response.data.errors[key][0],
            });
          });
          return
        }
        setMessage(dispatch, err.response?.data?.result?.message || err)
      });
  };
  return (
    <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
      <div className='px-4 py-6 sm:px-6 '>
        <div className='flex items-start justify-between'>
          <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
            Создать пользователя
          </h2>
          <div className='ml-3 h-7 flex items-center'>
            <button
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
              onClick={() => setOpen(false)}
            >
              <span className='sr-only'>Close panel</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
      <form className='h-full' onSubmit={handleSubmit(handleCreateUser)}>
        <div className='shadow overflow-hidden sm:rounded-md dropdown-box flex h-full flex-col justify-between'>
          <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='sm:col-span-6'>
                <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                  Фото
                </label>
                <div className='mt-1 flex items-center'>
                <span className='h-12 w-12 rounded-full overflow-hidden bg-gray-100'>
                  <img src={preview} alt='' className='h-full'/>
                </span>
                  <div>
                    <label htmlFor='upload-image'
                           className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Upload
                    </label>
                    <input
                      id='upload-image'
                      type='file'
                      className='w-0'
                      onChange={imageUpload(setPreview, setFile)}
                    />
                  </div>
                </div>
              </div>
              {fields.map((field, index) => {
                if (field.field === 'phone') {
                  return (
                    <div className='col-span-6 sm:col-span-3' key={field.field}>
                      <div className=''>
                        <label htmlFor='first-name'
                               className='block text-sm font-medium text-gray-700'>
                          {field.label}
                        </label>
                        <div className='mt-1'>
                          <InputMask mask='+\9\98 (99) 999-99-99' {...register(field.field)}
                                     className="'mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                      </div>
                      <p className='text-xs text-red-600 mt-2'>{errors[field.field] && errors[field.field].message}</p>
                    </div>
                  );
                }
                if (field.field === 'role') {
                  return (
                    <div className='col-span-6 sm:col-span-3' key={field.field}>
                      <div className=''>
                        <label htmlFor='first-name'
                               className='block text-sm font-medium text-gray-700'>
                          {field.label}
                        </label>
                        <select
                          className='mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                          {...register('role_id')}
                        >
                          {allRoles && allRoles.map((role: any) => (
                            <option value={role.id} key={role.id}>{role.name}</option>
                          ))}
                        </select>
                      </div>
                      <p className='text-xs text-red-600 mt-2'>{errors[field.field] && errors[field.field].message}</p>
                    </div>
                  );
                }
                return (
                  <div className='col-span-6 sm:col-span-3' key={field.field}>
                    <div className=''>
                      <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                        {field.label}
                      </label>
                      <input
                        defaultValue=''
                        {...register(field.field)}
                        className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      />
                    </div>
                    <p className='text-xs text-red-600 mt-2'>{errors[field.field] && errors[field.field].message}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            <button
              type='button'
              className='transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={() => setOpen(false)}
            >
              Отмена
            </button>
            <button
              type='submit'
              className='transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Создать
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
