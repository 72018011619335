import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '@store/index';

interface Props {
  component: React.JSXElementConstructor<any> ;
  exact?: boolean;
  path?: string;
  operation: string;
  route?: boolean;
  item?: any;
}
interface PermissionProps {
  action: string,
  children: any
}
export const Permission: React.FC<PermissionProps> = ({ action, children}) => {
  return (
    <PermissionCheck
      component={() => children}
      operation={action}
    />
  )
}

export const PermissionCheck: React.FC<Props> = ({ component: Component, route= false, operation, ...rest}) => {
  const user = useSelector((state: State) => state.auth.user)
  return route
    ? (
      user?.role?.permissions?.find(per => per.slug === operation)
        ? <Route {...rest} component={Component}/>
        : null
  )
    : (
      user?.role?.permissions?.find(per => per.slug === operation)
        ? <Component {...rest}/>
        : null
    )
};

