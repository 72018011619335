import { usersAction } from './users.slices';
import { getAuthorsService, getUsersService } from '@services/users.service';
import { setMessage } from '@services/dispatch.service';

export const usersSet = (res: any) => async (dispatch: any) => {
  dispatch(usersAction.fetchSuccess({
    users: res.data,
    pagination: res.total,
  }))
}
export const userSet = (user: any) => async (dispatch: any) => {
  dispatch(usersAction.setUser({ user }))
}
export const authorSet = (auhtors: any) => async (dispatch: any) => {
  dispatch(usersAction.setAuthors({ auhtors }))
}

export const getUsers = (params: any) => (dispatch: any) => {
  return getUsersService({ ...params})
    .then((res) => dispatch(usersSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
export const getAuthors = () => (dispatch: any) => {
  return getAuthorsService()
    .then((res) => dispatch(authorSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
