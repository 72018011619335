import { commentsAction } from '@store/comments/comments.slices';
import { getCommentsService, getLastCommentsService } from '@services/comments.service';
import { IComment } from '@interfaces/index';
import { setMessage } from '@services/dispatch.service';

export const commentsSet = (res: any) => async (dispatch: any) => {
  dispatch(commentsAction.setComments({comments: res.data, pagination: res.total}))
}
export const commentSet = (comment: IComment | null ) => async (dispatch: any) => {
  dispatch(commentsAction.setComment({ comment }))
}

export const getComments = (params: any) => (dispatch: any) => {
  return getCommentsService(params)
    .then(res => dispatch(commentsSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
export const getLastComments = () => (dispatch: any) => {
  return getLastCommentsService()
    .then(res => dispatch(commentsSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
