import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PaginationComponent } from '@components/shared/pagination/pagination';
import { FilterBarComment } from '@components/shared/filter-bar';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { IFilters } from '@interfaces/index';
import { CommentsTable } from '@components/comments/comments-table';
import { getComments } from '@store/comments/comments.thunks';
import { CommentContext } from '@helpers/context';

const limit = 10;

export const CommentsPage: React.FC = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const filter = useRef<any>();

  const commentsGet = useCallback(() => {
    dispatch(getComments({ page: activePage, limit, ...filter.current }));
  }, [dispatch, activePage]);

  const applyFilter = (data: IFilters) => {
    filter.current = { ...data };
    setActivePage(1);
    commentsGet();
  };

  const resetFilter = () => {
    setActivePage(1);
    filter.current = {};
    commentsGet();
  };

  const changePage = (i: number) => setActivePage(i);

  useEffect(() => {
    commentsGet();
  }, [activePage, dispatch, commentsGet]);
  const pagination = useSelector((state: State) => state.comments.pagination);
  return (
    <CommentContext.Provider value={{
      ...filter.current,
      page: activePage
    }}>
      <h3 className='text-2xl font-semibold'>Комментарии</h3>
      <div className='flex justify-between items-center'>
        <div className='w-2/12'>
          <FilterBarComment handleFilter={applyFilter} handleReset={resetFilter} />
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <CommentsTable />
            </div>
          </div>
        </div>
      </div>
      {
        !!pagination && <PaginationComponent
          activePage={activePage}
          changePage={changePage}
          itemsCount={pagination}
        />
      }
    </CommentContext.Provider>
  );
};