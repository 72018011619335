import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { PopupDelete } from '@components/shared/popup/popup-delete';
import { EyeIcon } from '@heroicons/react/solid';
import { deletePost, refreshPost } from '@services/posts.service';
import { getPosts, postSet } from '@store/posts/posts.thunks';
import { useHistory } from 'react-router';
import { Permission } from '@helpers/permissions';
import {
  moderaciyaStati,
  obnovlenieStati,
  udalenieStati,
  vosstanovlenieStati,
} from '@_data/permissions';
import { ChatIcon } from '@heroicons/react/outline';
import { setMessage } from '@services/dispatch.service';

export const PostsTbody: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const post = useSelector((state: State) => state.posts.post);
  const posts = useSelector((state: State) => state.posts.posts);

  const deleteHandler = (id: number) => {
    deletePost(id)
      .then(() => {
        dispatch(getPosts({}));
        setOpen(false);
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const refresh = async (id: number) => {
    await refreshPost(id)
      .then(() => {
        dispatch(getPosts({}));
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };

  return (
    <tbody className='bg-white divide-y divide-gray-200'>
    {!!posts.length && posts.map((post) => (
      <tr
        key={post.id}
        className={`cursor-pointer ${!post.active && post.status !== 'event'? 'bg-yellow-100' : ''} ${post.status === 'event'? 'bg-indigo-200' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/posts/show/${post.id}`);
        }}
      >
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <img className='h-10 w-10 rounded-full object-cover' src={post.image} alt='' />
            </div>
            <div className='ml-4'>
              <div className='text-sm font-medium text-gray-900'><p
                className='overflow-hidden overflow-ellipsis w-full'
                style={{ maxWidth: '600px' }}>{post.title}</p></div>
              <div className='flex flex-wrap gap-4 items-center'>
                <div className='text-sm text-gray-500'><p
                  className='overflow-hidden overflow-ellipsis'>{post.created_at}</p></div>
                {
                  post.category &&
                  <div className='text-sm text-gray-900'><p
                    className='overflow-hidden overflow-ellipsis'>{post.category?.name?.ru}</p></div>
                }
                {
                  post.user &&
                  <div className='text-sm text-gray-900'><p
                    className='overflow-hidden overflow-ellipsis'>{post.user?.name}</p></div>
                }
              </div>
            </div>
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
          <div className='text-sm font-medium text-gray-900 text-center'>{post.locale}</div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
          <div className='mt-2 flex items-center text-xs text-gray-500'>
            <EyeIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
            {post?.views_count}
          </div>
          <div className='mt-2 flex items-center text-xs text-gray-500'>
            <ChatIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' aria-hidden='true' />
            {post?.comments_count}
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-col space-y-1'>
          {
            !!post?.hot_post && !post.deleted_at && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Горячая новость
              </span>
          }
          {
            !!post?.popular && !post.deleted_at && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Популярно
              </span>
          }
          {
            !!post?.special_report && !post.deleted_at && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Спец. репортаж
              </span>
          }
          {
            post?.active && !post.deleted_at && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Активно
              </span>
          }
          {
            !post?.active && !post.deleted_at && post.status !== 'event' && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-300 text-yellow-600'>
                В модерации
              </span>
          }
          {
            !!post.deleted_at && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
                В архиве
              </span>
          }
          {
            post.status === 'event' && <span
              className='flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-300 text-indigo-600'>
                Собитие от пользователя
              </span>
          }
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
          {
            post.deleted_at
              ? (
                <Permission action={vosstanovlenieStati}>
                  <button
                    className='text-gray-600 hover:text-gray-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      refresh(post.id);
                    }}
                  >
                    Восстановить
                  </button>
                </Permission>
              )
              : <>
                <Permission action={obnovlenieStati}>
                  <button
                    className='text-indigo-600 hover:text-indigo-900 mx-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/posts/edit/${post.id}`);
                    }}
                  >
                    Ред.
                  </button>
                </Permission>
                <Permission action={udalenieStati}>
                  <button
                    className='text-red-600 hover:text-red-900'
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(postSet(post));
                      setOpen(true);
                    }}
                  >
                    В архив
                  </button>
                </Permission>
                <>
                  {
                    !post.active && post.status !== 'event' && <Permission action={moderaciyaStati}>
                      <button
                        className='text-yellow-600 hover:text-yellow-900 mx-2'
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`/posts/moderate/${post.id}`);
                        }}
                      >
                        Модер.
                      </button>
                    </Permission>
                  }
                </>
              </>
          }
        </td>
      </tr>
    ))}
    {
      !!post && <Permission action={udalenieStati}>
        <PopupDelete
          open={open}
          setOpen={setOpen}
          handleDelete={deleteHandler}
          data={{ name: post.title, id: post.id }}
        />
      </Permission>
    }
    </tbody>
  );
};

