import { api } from '@api/index';

export const loginService = (data: any) => {
  return api
    .post('/auth/login', data)
    .then(res => res.data.result)
}
export const userService = () => {
  return api
    .get('/auth/me')
    .then(res => res.data.result.user)
}