import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategory } from '@interfaces/index';

interface IState {
  categories: ICategory[];
  allCategories: ICategory[];
  category: ICategory | null;
  pagination: number;
}

const initialState: IState = {
  categories: [],
  allCategories: [],
  category: null,
  pagination: 0,
};

export const { actions: categoriesAction, reducer: categoriesReducer } = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<{ categories: ICategory[], pagination: number }>) => ({
      ...state,
      categories: action.payload.categories,
      pagination: action.payload.pagination,
    }),
    setCategory: (state, action: PayloadAction<{ category: ICategory | null }>) => ({
      ...state,
      category: action.payload.category,
    }),
    setAllCategory: (state, action: PayloadAction<{ categories: ICategory[] }>) => ({
      ...state,
      allCategories: action.payload.categories,
    }),
  },
});