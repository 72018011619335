import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UsersTable } from '@components/users/users-table';
import { SlideOvers } from '@components/shared/slideovers';
import { FilterBarUser } from '@components/shared/filter-bar';
import { PaginationComponent } from '@components/shared/pagination';
import { UserCreate } from '@pages/users/create';
import { getUsers } from '@store/users/users.thunks';
import { State } from '@store/index';
import { IFilters } from '@interfaces/index';
import { fetchAllRoles } from '@store/roles/roles.thunks';
import { UserContext } from '@helpers/context';
import { Permission } from '@helpers/permissions';
import { sozdaniePolzovatelya } from '@_data/permissions';

const limit = 10;

export const UsersPage: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useRef<any>();
  const { pagination } = useSelector((state: State) => state.users);

  const [show, setShow] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const usersGet = useCallback(() => {
    dispatch(getUsers({ page: activePage, limit, ...filter.current }));
  }, [dispatch, activePage]);
  const resetFilters = () => {
    setActivePage(1);
    filter.current = {};
    usersGet();
  };
  const changePage = (i: number) => setActivePage(i);
  const filterUsers = (data: IFilters) => {
    setActivePage(1);
    filter.current = data;
    usersGet();
  };

  useEffect(() => {
    usersGet();
    dispatch(fetchAllRoles());
  }, [usersGet, dispatch, activePage]);

  return (
    <UserContext.Provider value={{
      ...filter.current,
      page: activePage
    }}>
      <h3 className='text-2xl font-semibold'>Пользователи</h3>
      <div className='flex justify-between items-start'>
        <div className='w-2/12'>
          <FilterBarUser handleFilter={filterUsers} handleReset={resetFilters}/>
        </div>
        <Permission action={sozdaniePolzovatelya}>
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
              type='button'
              className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Добавить пользователя
            </button>
          </div>
        </Permission>
      </div>

      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
            <UsersTable />
          </div>
        </div>
      </div>

      {
        !!pagination && <PaginationComponent
          activePage={activePage}
          changePage={changePage}
          itemsCount={pagination}
        />
      }
      <SlideOvers
        Create={UserCreate}
        show={show}
        setShow={setShow}
        mode={'create'}
      />
    </UserContext.Provider>
  );
};