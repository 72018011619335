import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/solid';

export const Breadcrumbs: React.FC = () => {
  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol className='flex items-center space-x-4'>
        <li>
          <div className='flex'>
            <Link to='/' className='text-sm font-medium text-gray-500 hover:text-gray-700'>
              Главная
            </Link>
          </div>
        </li>
        <li>
          <div className='flex items-center'>
            <ChevronRightIcon className='flex-shrink-0 h-5 w-5 text-gray-400' aria-hidden='true' />
            <Link to='/posts' className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'>
              Статьи
            </Link>
          </div>
        </li>
      </ol>
    </nav>
  );
};