import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { XIcon } from '@heroicons/react/solid';
import { TagContext } from '@helpers/context';
import { ruTagsSet, tagSet, uzTagsSet } from '@store/tags/tags.thunks';
import { getTagsService, updateTag } from '@services/tags.service';
import { Permission } from '@helpers/permissions';
import { obnovlenieTega, udalenieTega } from '@_data/permissions';
import { setMessage } from '@services/dispatch.service';

interface Props {
  confirmModal: (open: boolean) => void;
  setOpen: (open: boolean) => void;
  setMode: (mode: string) => void;
}

export const EditTag: React.FC<Props> = ({ confirmModal, setOpen, setMode }) => {
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm();
  const dispatch = useDispatch();
  const { filter, page }: any = useContext(TagContext);
  const tag = useSelector((state: State) => state.tags.tag);
  const close = () => {
    setOpen(false);
    dispatch(tagSet(tag));
  };
  const submitHandle = async (data: any) => {
    await updateTag(tag!.id, { ...data })
      .then(tag => {
        dispatch(tagSet(tag));
        getTagsService({ filter, locale: data.locale, page: page[data.locale] })
          .then(res => {
            if (res.uz) dispatch(uzTagsSet({ tags: res.uz.data, pagination: res.uz.total }));
            if (res.ru) dispatch(ruTagsSet({ tags: res.ru.data, pagination: res.ru.total }));
          });
        reset();
        setMode('show');
      })
      .catch(err => {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).forEach(key => {
            setError(key, {
              type: 'required',
              message: err.response.data.errors[key][0],
            });
          });
          return;
        }
        setMessage(dispatch, err.response.data.message || err);
      });
  };

  return (
    <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
      <div className='px-4 py-6 sm:px-6 '>
        <div className='flex items-start justify-between'>
          <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
            Изменить тэг
          </h2>
          <div className='ml-3 h-7 flex items-center'>
            <button
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
              onClick={close}
            >
              <span className='sr-only'>Close panel</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
      {/* Main */}
      <form
        className='w-12/12 h-full'
        onSubmit={handleSubmit(submitHandle)}
      >
        <div className='shadow overflow-hidden sm:rounded-md dropdown-box flex h-full flex-col justify-between'>
          <div className='grid grid-cols-6 gap-6 p-5'>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Название:
              </label>
              {
                !!tag && <>
                  <input
                    type='text'
                    defaultValue={tag.name}
                    {...register('name')}
                    className={`${errors.name ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className='mt-2 text-red-600 text-sm'>{(errors.name && errors.name.message)}</p>
                </>
              }
            </div>
            <div
              className='col-span-3 sm:col-span-1'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Локализация:
              </label>
              {
                !!tag && <>
                  <select
                    defaultValue='ru'
                    {...register('locale')}
                    className={`${errors.locale ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full pl-3 pr-6 py-2 border text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                  >
                    <option value='ru'>RU</option>
                    <option value='uz'>UZ</option>
                  </select>
                  <p className='mt-2 text-red-600 text-sm'>{(errors.locale && errors.locale.message)}</p>
                </>
              }
            </div>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Meta Title:
              </label>
              {
                !!tag && <>
                  <input
                    type='text'
                    defaultValue={tag.meta_title}
                    {...register('meta_title')}
                    className={`${errors.meta_title ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p className='mt-2 text-red-600 text-sm'>{(errors.meta_title && errors.meta_title.message)}</p>
                </>
              }
            </div>
            <div
              className='col-span-6 sm:col-span-3'
            >
              <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                Meta Description:
              </label>
              {
                !!tag && <>
                  <input
                    type='text'
                    defaultValue={tag.meta_description}
                    {...register('meta_description')}
                    className={`${errors.meta_description ? 'border-red-300' : 'border-gray-300'} mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  <p
                    className='mt-2 text-red-600 text-sm'>{(errors.meta_description && errors.meta_description.message)}</p>
                </>
              }
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            <Permission action={udalenieTega}>
              <button
                type='button'
                className='mr-auto transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => {
                  setOpen(false);
                  confirmModal(true);
                }}
              >
                Удалить
              </button>
            </Permission>
            <button
              type='button'
              className='transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={() => setMode('show')}
            >
              Отменить
            </button>
            <Permission action={obnovlenieTega}>
              <button
                type='submit'
                className='transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Изменить
              </button>
            </Permission>
          </div>
        </div>
      </form>
    </div>
  );
};