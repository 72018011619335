import React from 'react';
import { Thead } from '@components/shared/thead';
import { RolesTableTitles } from '@_data/titles';
import { RolesTBody } from '@components/roles/roles-tbody';

export const RolesTable: React.FC = () => {
  return (
    <table className='min-w-full divide-y divide-gray-200'>
      <Thead tableTitles={RolesTableTitles}/>
      <RolesTBody />
    </table>
  );
};