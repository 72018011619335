import { categoriesAction } from './categories.slices';
import { getAllCategoriesService, getCategoriesService, getCategoryService } from '@services/categories.service';
import { setMessage } from '@services/dispatch.service';

export const categoriesSet = (res: any) =>  async (dispatch: any) => {
  dispatch(categoriesAction.setCategories({
    categories: res.categories.data,
    pagination: res.categories.total,
  }));
}
export const allCategorySet = (res: any) =>  async (dispatch: any) => {
  dispatch(categoriesAction.setAllCategory({
    categories: res.categories,
  }));
}
export const categorySet = (category: any) =>  async (dispatch: any) => {
  dispatch(categoriesAction.setCategory({ category }));
}

export const getCategories = (params: any) => async (dispatch: any) => {
  getCategoriesService(params)
    .then(res => dispatch(categoriesSet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};
export const getAllCategories = () => async (dispatch: any) => {
  getAllCategoriesService()
    .then(res => dispatch(allCategorySet(res)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};

export const getCategory = (id: string) => async (dispatch: any) => {
  getCategoryService(id)
    .then(res => dispatch(categorySet(res.category)))
    .catch((e) => setMessage(dispatch, e.response ? e.response?.data?.message : e.error))
};