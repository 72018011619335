import React, { useContext, useState } from 'react';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { deleteImage, updateUser } from '@services/users.service';
import { XIcon } from '@heroicons/react/solid';
import { getUsers, userSet } from '@store/users/users.thunks';
import { formatData } from '@helpers/format-data';
import { imageUpload } from '@helpers/preview';
import { UserContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import { Permission } from '@helpers/permissions';
import { obnovleniePolzovatelya, udaleniePolzovatelya } from '@_data/permissions';

interface Props {
  confirmModal: (open: boolean) => void;
  setOpen: (open: boolean) => void;
  setMode: (mode: string) => void;
}
export const Edit: React.FC<Props> = ({ confirmModal, setOpen, setMode }) => {
  const dispatch = useDispatch();
  const filter = useContext(UserContext)
  const { register, handleSubmit, reset, formState: { errors }, setError } = useForm();

  const allRoles = useSelector((state: State) => state.roles.allRoles);
  const user = useSelector((state: State) => state.users.user);
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>(user?.avatar);

  const close = () => {
    setOpen(false);
    dispatch(userSet(null));
  };
  const imageRemove = () => {
    deleteImage(user!.id)
      .then(() => {
        setPreview('');
        setMessage(dispatch, 'Изображение удалено');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err))
  }

  const submitForm = async (formdata: any) => {
    updateUser(user ? user.id : 0, formatData({
      formdata: { ...formdata, phone: null },
      image: file,
      patch: true,
      phone: formdata.phone,
    }))
      .then(user => {
        dispatch(userSet(user));
        dispatch(getUsers(filter));
        reset();
        setMode('show');
      })
      .catch((err) => {
        if (err.response.data.errors) {
          Object.keys(err.response.data.errors).forEach((key) => {
            setError(key, {
              type: 'server-error',
              message: err.response.data.errors[key][0],
            });
          });
          return;
        }
        setMessage(dispatch, err.response?.data?.result?.message || err)
      });
  };
  return (
    <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
      <div className='px-4 py-6 sm:px-6 '>
        <div className='flex items-start justify-between'>
          <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
            Изменить пользователя
          </h2>
          <div className='ml-3 h-7 flex items-center'>
            <button
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
              onClick={close}
            >
              <span className='sr-only'>Close panel</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(submitForm)}>
        <div className='shadow sm:rounded-md sm:overflow-hidden'>
          <div className='bg-white py-6 px-4 space-y-6 sm:p-6'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='sm:col-span-6'>
                <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                  Фото
                </label>
                <div className='mt-1 flex items-center'>
                <span className='h-12 w-12 rounded-full overflow-hidden bg-gray-100'>
                  <img src={preview} className='h-full' alt='avatar' />
                </span>
                  <div>
                    <label
                      htmlFor='upload-image'
                      className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Загрузить
                    </label>
                    <input
                      id='upload-image'
                      type='file'
                      className='w-0'
                      onChange={imageUpload(setPreview, setFile)}
                    />
                    <button
                      type='button'
                      className='ml-2 px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
                      onClick={imageRemove}
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                  Имя пользователя:
                </label>
                {user && <>
                  <input
                    type='text'
                    id='first-name'
                    autoComplete='given-name'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    defaultValue={user?.name}
                    {...register('name')}
                  />
                  <p className='text-xs text-red-600 mt-2'>{errors.name && errors.name.message}</p>
                </>
                }

              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='street-address' className='block text-sm font-medium text-gray-700'>
                  Телефон:
                </label>
                {
                  user && <>
                    <div className='mt-1'>
                      <InputMask
                        mask='+\9\98 (99) 999-99-99'
                        defaultValue={user?.phone}
                        {...register('phone')}
                        className="'mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <p className='text-xs text-red-600 mt-2'>{errors.phone && errors.phone.message}</p>
                  </>
                }
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                  Почта:
                </label>
                {user && <>
                  <input
                    type='text'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    defaultValue={user?.email}
                    {...register('email')}
                  />
                  <p className='text-xs text-red-600 mt-2'>{errors.email && errors.email.message}</p>
                </>
                }
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                  Пароль:
                </label>
                {user && <>
                  <input
                    type='text'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    defaultValue={user?.password}
                    {...register('password')}
                  />
                  <p className='text-xs text-red-600 mt-2'>{errors.password && errors.password.message}</p>
                </>
                }
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <div className=''>
                  <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                    Роль:
                  </label>
                  {
                    !!user && <>
                      <select
                        {...register('role_id')}
                        defaultValue={user.role_id ? user.role_id : 1}
                        className='cursor-not-allowed mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                      >
                        {
                          !!allRoles && allRoles.map((role: any) => (
                            <option
                              value={role.id}
                              key={role.id}
                            >
                              {role.name}
                            </option>
                          ))
                        }
                      </select>
                      <p className='text-xs text-red-600 mt-2'>{errors.role_id && errors.role_id.message}</p>
                    </>
                  }
                </div>
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                  Обо мне:
                </label>
                {user && <>
                  <input
                    type='text'
                    className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    defaultValue={user?.about_me}
                    {...register('about_me')}
                  />
                  <p className='text-xs text-red-600 mt-2'>{errors.about_me && errors.about_me.message}</p>
                </>
                }
              </div>

            </div>
          </div>
          <div className='flex px-4 py-3 bg-gray-50 text-right sm:px-6'>
            <Permission action={udaleniePolzovatelya}>
              <button
                type='button'
                className='mr-auto transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => {
                  setOpen(false);
                  confirmModal(true);
                }}
              >
                Удалить
              </button>
            </Permission>
            <button
              type='button'
              className='transition duration-300 ease-in-out rounded-3xl mr-3 py-2 px-4 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={() => setMode('show')}
            >
              Отменить
            </button>
            <Permission action={obnovleniePolzovatelya}>
              <button
                type='submit'
                className='transition duration-300 ease-in-out rounded-3xl inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Изменить
              </button>
            </Permission>
          </div>
        </div>
      </form>
    </div>
  );
};