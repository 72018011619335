import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PencilIcon, CheckIcon, EyeIcon } from '@heroicons/react/solid';
import { Selectbutton } from '@components/posts/UI/selectbutton';
import { LinkIcon } from '@heroicons/react/outline';
import { Permission } from '@helpers/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '@services/dispatch.service';
import { moderaciyaStati, obnovlenieStati, sozdanieStati, udalenieStati } from '@_data/permissions';
import { State } from '@store/index';
import { Spinner } from '@components/shared/spinner';
import { website } from '@_data/helpers';

interface PostModerateButtonsProps {
  onSubmit: () => void;
  loading: boolean;
  slug?: string;
}

export const PostModerateButtons: React.FC<PostModerateButtonsProps> = ({ onSubmit, loading, slug = '' }) => {
  const params = useParams<any>();
  return (
    <div className='flex'>
      <Permission action={obnovlenieStati}>
        <span>
          <Link
            aria-disabled={loading}
            to={`/posts/edit/${params.id}`}
            className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <PencilIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
            Редактировать
          </Link>
        </span>
      </Permission>
      <span>
        <a
          href={`${website}/posts/${slug}`}
          target='_blank'
          rel='noreferrer'
          className='mb-0.5 mx-1 sm:mx-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md whitespace-nowrap shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <LinkIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
          На сайте
        </a>
      </span>
      {
        loading
          ? <Spinner className='text-center' />
          : (
            <Permission action={moderaciyaStati}>
              <span className='sm:ml-3'>
                <button
                  disabled={loading}
                  onClick={onSubmit}
                  type='button'
                  className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  Опубликовать
                </button>
              </span>
            </Permission>
          )
      }
    </div>
  );
};

interface PostPageButtonsProps {
  refresh: () => void;
  onDelete: (open: boolean) => void;
  loading: boolean;
  deleted: boolean;
  active: boolean;
}

export const PostPageButtons: React.FC<PostPageButtonsProps> = ({
                                                                  refresh,
                                                                  onDelete,
                                                                  loading,
                                                                  deleted,
                                                                  active = true,
                                                                }) => {
  const { post } = useSelector((state: State) => state.posts);
  const params = useParams<any>();
  const dispatch = useDispatch();
  const publishingOptions = [
    {
      title: 'Опубликовано',
      onClick: () => deleted ? refresh() : setMessage(dispatch, 'Статья уже опубликована'),
      description: '',
      current: !deleted,
    },
    {
      title: 'В архив',
      onClick: () => deleted ? setMessage(dispatch, 'Статья уже в архиве') : onDelete(true),
      description: 'Статья пропадает с сайта, но будет доступна в панели управления и сохранит все данные',
      current: deleted,
    },
  ];
  const [selected, setSelected] = useState(deleted ? publishingOptions[1] : publishingOptions[0]);
  return (
    <div className='flex flex-wrap justify-end sm:justify-start lg:flex-nowrap'>
      <Permission action={obnovlenieStati}>
        <span>
          <Link
            aria-disabled={loading}
            to={`/posts/edit/${[params.id]}`}
            className='mb-0.5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <PencilIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
            Редактировать
          </Link>
        </span>
      </Permission>
      {
        active
          ? null
          : (
            <Permission action={sozdanieStati}>
              <span>
                <Link
                  to={`/posts/moderate/${post?.id}`}
                  className='mb-0.5 ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md whitespace-nowrap shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  Опубликовать
                </Link>
              </span>
            </Permission>
          )
      }
      <span>
        <a
          href={`${website}${post?.locale === 'ru' ? '' : '/uz'}/posts/${post?.slug}`}
          target='_blank'
          rel='noreferrer'
          className='mb-0.5 mx-1 sm:mx-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md whitespace-nowrap shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <LinkIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
          На сайте
        </a>
      </span>
      <Permission action={udalenieStati}>
        <span className=''>
          <Selectbutton publishingOptions={publishingOptions} selected={selected} setSelected={setSelected} />
        </span>
      </Permission>
    </div>
  );
};

interface PostEditButtonsProps {
  refresh: () => void;
  onDelete: (open: boolean) => void;
  deleted: boolean;
  selectbutton: boolean;
  active?: boolean;
  previewpage: () => void;
  isSubmiting: boolean;
}

export const PostEditButtons: React.FC<PostEditButtonsProps> = ({
                                                                  refresh,
                                                                  onDelete,
                                                                  deleted,
                                                                  selectbutton,
                                                                  isSubmiting,
                                                                  active = true,
                                                                }) => {
  const dispatch = useDispatch();
  const { post } = useSelector((state: State) => state.posts);
  const publishingOptions = [
    {
      title: active ? 'Опубликовано' : 'Сохранено',
      onClick: () => deleted ? refresh() : setMessage(dispatch, 'Статья уже опубликована'),
      description: active ? '' : 'Сохраненные статьи не выводятся в списках статей, но доступны по прямой ссылке.',
      current: !deleted,
    },
    {
      title: 'В архив',
      onClick: () => deleted ? setMessage(dispatch, 'Статья уже в архиве') : onDelete(true),
      description: 'Статья пропадает с сайта, но будет доступна в панели управления и сохранит все данные',
      current: deleted,
    },
  ];
  const [selected, setSelected] = useState(deleted ? publishingOptions[1] : publishingOptions[0]);
  return (
    <div className='flex flex-wrap items-center justify-end sm:justify-start lg:flex-nowrap'>
      {
        isSubmiting
          ? <Spinner className='text-center' />
          : (
            <Permission action={sozdanieStati}>
              <span>
                <button
                  type='submit'
                  disabled={isSubmiting}
                  className='mb-0.5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  <PencilIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
                  Сохранить
                </button>
              </span>
            </Permission>
          )
      }
      <span>
        <a
          href={`${website}${post?.locale === 'ru' ? '' : '/uz'}/posts/${post?.slug}`}
          target='_blank'
          rel='noreferrer'
          className='mb-0.5 mx-1 sm:mx-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md whitespace-nowrap shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'

        >
          <LinkIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
          Предпросмотр
        </a>
      </span>
      {
        !active &&
        <Permission action={sozdanieStati}>
          <span>
            <Link
              to={`/posts/moderate/${post?.id}`}
              className='mb-0.5 mr-3 inline-flex bg-green-400 items-center text-white px-4 py-2 border border-gray-300 rounded-md whitespace-nowrap shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <EyeIcon className='-ml-1 mr-2 h-5 w-5 text-white' aria-hidden='true' />
              Опубликовать
            </Link>
          </span>
        </Permission>
      }
      {
        selectbutton && (
          <Permission
            action={udalenieStati}
          >
            <span>
              <Selectbutton publishingOptions={publishingOptions} selected={selected} setSelected={setSelected} />
            </span>
          </Permission>
        )
      }

    </div>
  );
};

interface PostCreateButtonsProps {
  setPublicate: (publicate: boolean) => void;
  previewpage: () => void;
  isSubmiting: boolean;
}

export const PostCreateButtons: React.FC<PostCreateButtonsProps> = ({ setPublicate, previewpage, isSubmiting }) => {
  return (
    <div className='flex flex-wrap justify-end sm:justify-start lg:flex-nowrap'>
      <Permission action={sozdanieStati}>
        <span>
          <button
            type='submit'
            disabled={isSubmiting}
            onClick={() => setPublicate(false)}
            className='mb-0.5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <PencilIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
            Сохранить
          </button>
        </span>
      </Permission>
      <span>
        <Link
          onClick={previewpage}
          to={`/posts/preview`}
          target='_blank'
          className='mb-0.5 mx-1 sm:mx-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md whitespace-nowrap shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          <LinkIcon className='-ml-1 mr-2 h-5 w-5 text-gray-500' aria-hidden='true' />
          Предпросмотр
        </Link>
      </span>
      <Permission action={moderaciyaStati}>
         <span className=''>
          <button
            type='submit'
            disabled={isSubmiting}
            onClick={() => setPublicate(true)}
            className='bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          >
            <CheckIcon className='-ml-1 mr-2 h-5 w-5 text-white' aria-hidden='true' />
            Опубликовать
          </button>
        </span>
      </Permission>
    </div>
  );
};