import React from 'react';
import { Thead } from '@components/shared/thead';
import { CommentsTableTitles } from '@_data/titles';
import { CommentsTbody } from '@components/comments/comments-tbody';

export const CommentsTable: React.FC = () => {
  return (
    <table className='min-w-full divide-y divide-gray-200'>
      <Thead tableTitles={CommentsTableTitles}/>
      <CommentsTbody />
    </table>
  );
};