import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PaginationComponent } from '@components/shared/pagination/pagination';
import { FilterBarPost } from '@components/shared/filter-bar';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { getPosts } from '@store/posts/posts.thunks';
import { PostsTable } from '@components/posts/posts-table';
import { sozdanieStati } from '@_data/permissions';
import { Permission } from '@helpers/permissions';
import { getAllCategories } from '@store/categories/categories.thunks';
import { getAuthors } from '@store/users/users.thunks';

const limit = 10;

export const PostsPage: React.FC = () => {
  const dispatch = useDispatch();
  const filter = useRef<any>();
  const [activePage, setActivePage] = useState(1);

  const postsGet = useCallback(() => {
    dispatch(getPosts({ page: activePage, limit, ...filter.current }));
  }, [dispatch, activePage]);
  const applyFilter = (data: any) => {
    filter.current = { ...data };
    setActivePage(1);
    postsGet();
  };
  const resetFilter = () => {
    filter.current = {};
    setActivePage(1);
    postsGet();
  };
  const changePage = (i: number) => setActivePage(i);
  useEffect(() => {
    postsGet();
  }, [activePage, dispatch, postsGet]);
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAuthors());
  }, [dispatch]);
  const pagination = useSelector((state: State) => state.posts.pagination);

  return (
    <>
      <h3 className='text-2xl font-semibold'>Статьи</h3>
      <div className='flex justify-between items-start'>
        <div className='w-2/12'>
          <FilterBarPost handleFilter={applyFilter} handleReset={resetFilter} />
        </div>
        <Permission action={sozdanieStati}>
          <div>
            <Link
              to='/posts/create'
              className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Создать статью
            </Link>
          </div>
        </Permission>
      </div>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <PostsTable />
            </div>
          </div>
        </div>
      </div>
      {
        !!pagination && <PaginationComponent
          activePage={activePage}
          changePage={changePage}
          itemsCount={pagination}
        />
      }
    </>
  );
};