import React from 'react';
import Pagination from 'react-js-pagination';

interface Props {
  activePage: number;
  changePage: (i: number) => void;
  itemsCount: number
}

const showItems = 10
const showPages = 10

export const PaginationComponent: React.FC<Props> = ({ changePage, activePage, itemsCount}) => {
  const pageChange = (i: number) => {
    changePage(i)
  };
  return (
    <div className='flex'>
      <Pagination
        innerClass='relative z-0 inline-flex rounded-md shadow-sm -space-x-px mt-6 ml-auto'
        itemClassPrev='relative inline-flex cursor-pointer items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
        itemClassNext='relative inline-flex cursor-pointer items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
        itemClassFirst='relative inline-flex cursor-pointer items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
        itemClassLast='relative inline-flex cursor-pointer items-center px-2 py-2  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
        activeClass='z-10 bg-indigo-50 cursor-pointer border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
        itemClass='bg-white border-gray-300 cursor-pointer text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
        totalItemsCount={itemsCount}
        onChange={pageChange}
        itemsCountPerPage={showItems}
        pageRangeDisplayed={showPages}
        activePage={activePage}
      />
    </div>
  );
};