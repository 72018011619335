import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@store/index';
import { PencilIcon, RefreshIcon, XIcon } from '@heroicons/react/solid';
import { formatDate } from '@helpers/format-date';
import { refreshUser } from '@services/users.service';
import { getUsers, userSet } from '@store/users/users.thunks';
import { UserContext } from '@helpers/context';
import { setMessage } from '@services/dispatch.service';
import { Permission } from '@helpers/permissions';
import { obnovleniePolzovatelya, vosstanovleniePolzovatelya } from '@_data/permissions';

interface Props {
  setOpen: (open: boolean) => void;
  setMode: (mode: string) => void;
}

export const UserPage: React.FC<Props> = ({ setOpen, setMode }) => {
  const dispatch = useDispatch();
  const filter = useContext(UserContext)
  const close = () => {
    setOpen(false);
    dispatch(userSet(null));
  };
  const refresh = async (id: number) => {
    await refreshUser(id)
      .then(user => {
        dispatch(userSet(user));
        dispatch(getUsers(filter));
        setMode('show');
      })
      .catch(err => setMessage(dispatch, err.response?.data?.result?.message || err));
  };
  const { user } = useSelector((state: State) => state.users);
  return (
    <>
      <div className='h-full flex flex-col bg-white shadow-xl overflow-y-scroll'>
        <div className='px-4 py-6 sm:px-6'>
          <div className='flex items-start justify-between'>
            <h2 id='slide-over-heading' className='text-lg font-medium text-gray-900'>
              Пользователь
            </h2>
            <div className='ml-3 h-7 flex items-center'>
              <button
                className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500'
                onClick={close}
              >
                <span className='sr-only'>Close panel</span>
                <XIcon className='h-6 w-6' aria-hidden='true'/>
              </button>
            </div>
          </div>
        </div>
        <div className='divide-y divide-gray-200'>
          <div className='pb-6'>
            <div className='bg-indigo-700 h-24 sm:h-20 lg:h-28'/>
            <div className='-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-15'>
              <div>
                <div className='-m-1 flex'>
                  <div className='inline-flex rounded-lg overflow-hidden border-4 border-white'>
                    <img
                      className='flex-shrink-0 h-24 w-24 sm:h-40 sm:w-40 lg:w-48 lg:h-48'
                      src={user?.avatar}
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <div className='mt-6 sm:ml-6 sm:flex-1'>
                <div>
                  <div className='flex items-center'>
                    <h3 className='font-bold text-xl text-gray-900 sm:text-2xl'>{user?.name}</h3>
                  </div>
                  <p className='text-sm text-gray-500'><span className='text-black'>Email:</span> {user?.email}</p>
                </div>
                <div className='mt-5 w-6/12 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3'>
                  {
                    !!user && user.deleted_at
                      ? (
                        <Permission action={vosstanovleniePolzovatelya}>
                          <button
                            className='text-gray-600 hover:text-gray-900'
                            onClick={(e) => {
                              e.stopPropagation();
                              user && refresh(user.id);
                            }}
                          >
                            <RefreshIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true'/>
                          </button>
                        </Permission>
                      )
                      : (
                        <Permission action={obnovleniePolzovatelya}>
                          <button
                            type='button'
                            className='inline-flex items-center px-3 py-2 text-gray-400 border border-gray-400 shadow-sm text-sm leading-4 font-medium rounded-3xl hover:text-white bg-transparent hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() => setMode('edit')}
                          >
                            <PencilIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true'/>
                            Изменить
                          </button>
                        </Permission>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='px-4 py-5 sm:px-0 sm:py-0'>
            <dl className='space-y-8 sm:divide-y sm:divide-gray-200 sm:space-y-0'>
              <div className='sm:flex sm:px-6 sm:py-5'>
                <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>Email</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2'>
                  {user?.email}
                </dd>
              </div>
              <div className='sm:flex sm:px-6 sm:py-5'>
                <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>Обо мне</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2'>
                  {user?.about_me}
                </dd>
              </div>
              <div className='sm:flex sm:px-6 sm:py-5'>
                <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>
                  Номер
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2'>
                  {user?.phone ? `+${+user.phone}` : ''}
                </dd>
              </div>
              <div className='sm:flex sm:px-6 sm:py-5'>
                <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>
                  Роль
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2'>
                  {user?.role?.name}
                </dd>
              </div>
              <div className='sm:flex sm:px-6 sm:py-5'>
                <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>
                  Создано
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2'>
                  <time dateTime='1982-06-23'>{user?.created_at && formatDate(user.created_at)}</time>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};